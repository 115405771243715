import {useState} from 'react'
import {useIncrementCounterTaskOfStudent} from '../../../../admin/hooks/useTaskAndStudents'
import {truncateText} from '../../../../helpers/Helpers'
import {RightTaskProps} from './_model'

const MidTask: React.FC<RightTaskProps> = ({task}) => {
  const [taskStudentId, setTaskStudentId] = useState<number>()
  const {mutate} = useIncrementCounterTaskOfStudent(taskStudentId || 0)
  if (task === undefined) {
    console.log('Undefined Mid task')
    return <></>
  }

  const handleUserAction = (taskId: number) => {
    setTaskStudentId(taskId)
    const data = {
      id: taskId,
      isCompleted: false,
      counter: 0,
    } //we can't send it empty
    mutate(data) // parameters  required
  }

  return (
    <svg
      width='100%'
      height='100%'
      viewBox='0 0 1324 1192'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='Frame 11' clip-path='url(#clip0_473_2)'>
        <g id='Frame 12' clip-path='url(#clip1_473_2)'>
          <g id='rod-top'>
            <path
              id='Rectangle_7-3'
              d='M3266.61 -966.295C3276.61 -956.895 3277.01 -941.295 3267.71 -931.295L664.711 599.105C655.311 609.105 639.711 609.505 629.711 600.205C619.711 590.805 619.311 575.205 628.611 565.205L3231.61 -965.195C3241.01 -975.095 3256.61 -975.595 3266.61 -966.295Z'
              fill='#F6F9FD'
            />
            <g id='Group'>
              <g id='Group_2'>
                <g id='Group_3'>
                  <path
                    id='Rectangle_7-4_00000073682545896355933430000007296589064854039739_'
                    d='M3266.63 -966.434C3276.63 -957.134 3277.13 -941.534 3267.83 -931.534L670.231 599.866C660.931 609.866 645.331 610.366 635.331 601.066C625.331 591.766 624.831 576.166 634.131 566.166L3231.73 -965.134C3241.03 -975.134 3256.63 -975.634 3266.63 -966.434Z'
                    fill='white'
                  />
                </g>
              </g>
              <g id='Group_4'>
                <g id='Group_5'>
                  <path
                    id='Rectangle_7-4_00000104690783767583358180000004783354482472393151_'
                    d='M3266.63 -966.434C3276.63 -957.134 3277.13 -941.534 3267.83 -931.534L670.231 599.866C660.931 609.866 645.331 610.366 635.331 601.066C625.331 591.766 624.831 576.166 634.131 566.166L3231.73 -965.134C3241.03 -975.134 3256.63 -975.634 3266.63 -966.434Z'
                    fill='#0686EF'
                  />
                </g>
              </g>
            </g>
            <g id='Group_6'>
              <path
                id='Rectangle_7-4_00000003794566903202574860000014514811246399556538_'
                d='M3266.63 -966.434C3276.63 -957.134 3277.13 -941.534 3267.83 -931.534L670.231 599.866C660.931 609.866 645.331 610.366 635.331 601.066C625.331 591.766 624.831 576.166 634.131 566.166L3231.73 -965.134C3241.03 -975.134 3256.63 -975.634 3266.63 -966.434Z'
                stroke='#39A2EA'
                stroke-width='18'
                stroke-miterlimit='10'
              />
            </g>
          </g>
          <g id='rod-bottom'>
            <path
              id='Rectangle_7-3_00000024716307940472339350000005306884937533764228_'
              d='M3251.71 2110.01C3243.11 2120.61 3227.51 2122.11 3216.91 2113.51L651.81 644.605C641.21 636.005 639.71 620.405 648.31 609.805C656.91 599.205 672.51 597.705 683.11 606.305L3248.21 2075.21C3258.81 2083.81 3260.31 2099.41 3251.71 2110.01Z'
              fill='#F6F9FD'
            />
            <g id='Group_7'>
              <g id='Group_8'>
                <g id='Group_9'>
                  <path
                    id='Rectangle_7-4_00000038390722079273140770000015509889850588286345_'
                    d='M3251.83 2110.07C3243.23 2120.67 3227.73 2122.27 3217.13 2113.67L651.431 650.065C640.831 641.465 639.23 625.965 647.83 615.365C656.43 604.765 671.93 603.165 682.53 611.765L3248.23 2075.36C3258.83 2083.86 3260.43 2099.47 3251.83 2110.07Z'
                    fill='white'
                  />
                </g>
              </g>
              <g id='Group_10'>
                <g id='Group_11'>
                  <path
                    id='Rectangle_7-4_00000005226083266473742350000015127326218213953920_'
                    d='M3251.83 2110.07C3243.23 2120.67 3227.73 2122.27 3217.13 2113.67L651.431 650.065C640.831 641.465 639.23 625.965 647.83 615.365C656.43 604.765 671.93 603.165 682.53 611.765L3248.23 2075.36C3258.83 2083.86 3260.43 2099.47 3251.83 2110.07Z'
                    fill='#0686EF'
                  />
                </g>
              </g>
            </g>
            <g id='Group_12'>
              <path
                id='Rectangle_7-4_00000005240330307936412830000014701045605647859338_'
                d='M3251.83 2110.07C3243.23 2120.67 3227.73 2122.27 3217.13 2113.67L651.431 650.065C640.831 641.465 639.23 625.965 647.83 615.365C656.43 604.765 671.93 603.165 682.53 611.765L3248.23 2075.36C3258.83 2083.86 3260.43 2099.47 3251.83 2110.07Z'
                stroke='#39A2EA'
                stroke-width='18'
                stroke-miterlimit='10'
              />
            </g>
          </g>
          <g id='corona'>
            {task[0] !== undefined && task[0] !== null && (
              <g id='task-1'>
                <g id='rod-1'>
                  <g id='Rectangle_6-5_00000065780732382114178500000001051937397845895564_'>
                    <g id='Group_13'>
                      <path
                        id='Vector'
                        d='M714.91 596.505C714.71 593.305 717.11 590.405 720.41 590.205L1096.41 565.505C1099.61 565.305 1102.51 567.705 1102.71 571.005C1102.91 574.205 1100.51 577.105 1097.21 577.305L721.21 602.005C718.01 602.205 715.21 599.705 714.91 596.505Z'
                        fill='#F6F9FD'
                      />
                    </g>
                    <g id='Group_14'>
                      <path
                        id='Vector_2'
                        d='M714.91 596.505C714.71 593.305 717.11 590.405 720.41 590.205L1096.41 565.505C1099.61 565.305 1102.51 567.705 1102.71 571.005C1102.91 574.205 1100.51 577.105 1097.21 577.305L721.21 602.005C718.01 602.205 715.21 599.705 714.91 596.505Z'
                        fill='#0686EF'
                      />
                    </g>
                  </g>
                  <path
                    id='Rectangle_6-6_00000172434067923636351530000005043719638535967374_'
                    d='M715.01 596.505C714.81 593.305 717.21 590.405 720.51 590.205L1096.51 565.505C1099.71 565.305 1102.61 567.705 1102.81 571.005C1103.01 574.205 1100.61 577.105 1097.31 577.305L721.31 602.005C718.01 602.205 715.21 599.705 715.01 596.505Z'
                    stroke='#00AAFF'
                    stroke-width='4'
                    stroke-miterlimit='10'
                  />
                </g>
                <g id='subtasks-1'>
                  <g id='subtask-1'>
                    <g id='Group_15'>
                      <path
                        id='Ellipse_16-13_00000115504423440306377710000009297834789339779225_'
                        d='M1114.51 587.704C1124.67 587.704 1132.91 579.466 1132.91 569.304C1132.91 559.142 1124.67 550.904 1114.51 550.904C1104.35 550.904 1096.11 559.142 1096.11 569.304C1096.11 579.466 1104.35 587.704 1114.51 587.704Z'
                        fill='#F6F9FD'
                      />
                      <g id='Group_16'>
                        <path
                          id='Ellipse_16-14_00000101819910526560481920000002376282503580941214_'
                          d='M1114.5 587.664C1124.66 587.664 1132.9 579.426 1132.9 569.264C1132.9 559.102 1124.66 550.864 1114.5 550.864C1104.34 550.864 1096.1 559.102 1096.1 569.264C1096.1 579.426 1104.34 587.664 1114.5 587.664Z'
                          fill='white'
                        />
                      </g>
                    </g>
                    <path
                      id='Rectangle_15_00000025442167243438165300000003330163850447553158_'
                      d='M1099.01 563.205L1100.31 578.605C1100.51 581.005 1098.71 583.105 1096.31 583.305C1093.91 583.505 1091.81 581.705 1091.61 579.305L1090.31 563.905C1090.11 561.505 1091.91 559.405 1094.31 559.205C1096.71 559.005 1098.81 560.805 1099.01 563.205Z'
                      fill='#E1E1E1'
                    />
                    <text
                      id='Task'
                      transform='translate(1098.58 563.805) rotate(85.2466)'
                      fill='#272727'
                      xmlSpace='preserve'
                      style={{whiteSpace: 'pre'}}
                      font-family='Poppins'
                      font-size='6.2224'
                      letter-spacing='0em'
                    >
                      <tspan x='0' y='6.67784'>
                        Task
                      </tspan>
                    </text>
                    <g id='Group_15' opacity={task[0].TasksAndStudents?.isCompleted ? '1' : '0.4'}>
                      <path
                        id='Ellipse_17-13_00000114773637333391544030000017425518104633876380_'
                        d='M1114.51 582.005C1121.53 582.005 1127.21 576.319 1127.21 569.305C1127.21 562.29 1121.53 556.604 1114.51 556.604C1107.5 556.604 1101.81 562.29 1101.81 569.305C1101.81 576.319 1107.5 582.005 1114.51 582.005Z'
                        fill='#00AAFF'
                      />
                    </g>
                  </g>
                  <g id='subtask-2'>
                    <g id='Group_17'>
                      <path
                        id='Ellipse_21-13_00000045598067093052534210000005955796667542784959_'
                        d='M1181.01 509.105C1191.17 509.105 1199.41 500.867 1199.41 490.705C1199.41 480.543 1191.17 472.305 1181.01 472.305C1170.85 472.305 1162.61 480.543 1162.61 490.705C1162.61 500.867 1170.85 509.105 1181.01 509.105Z'
                        fill='#F6F9FD'
                      />
                      <g id='Group_18'>
                        <path
                          id='Ellipse_21-14_00000181776085453617478640000015446355557863270279_'
                          d='M1181 509.164C1191.16 509.164 1199.4 500.926 1199.4 490.764C1199.4 480.602 1191.16 472.364 1181 472.364C1170.84 472.364 1162.6 480.602 1162.6 490.764C1162.6 500.926 1170.84 509.164 1181 509.164Z'
                          fill='white'
                        />
                      </g>
                    </g>
                    <path
                      id='Rectangle_13_00000021080983124348507730000015352217528848762771_'
                      d='M1167.51 484.304L1168.81 499.704C1169.01 502.104 1167.21 504.204 1164.81 504.404C1162.41 504.604 1160.31 502.804 1160.11 500.404L1158.81 485.004C1158.61 482.604 1160.41 480.504 1162.81 480.304C1165.21 480.104 1167.31 481.904 1167.51 484.304Z'
                      fill='#E1E1E1'
                    />
                    <text
                      id='Task_2'
                      transform='translate(1167.93 483.581) rotate(85.2466)'
                      fill='#272727'
                      xmlSpace='preserve'
                      style={{whiteSpace: 'pre'}}
                      font-family='Poppins'
                      font-size='7.3488'
                      letter-spacing='0em'
                    >
                      <tspan x='0' y='8.07208'>
                        Task
                      </tspan>
                    </text>
                    <g id='Group_137' opacity={task[0].TasksAndStudents?.isCompleted ? '1' : '0.4'}>
                      <path
                        id='Ellipse_20-13_00000095335738210538892580000010077107482105430181_'
                        d='M1181.01 503.405C1188.03 503.405 1193.71 497.719 1193.71 490.705C1193.71 483.691 1188.03 478.005 1181.01 478.005C1174 478.005 1168.31 483.691 1168.31 490.705C1168.31 497.719 1174 503.405 1181.01 503.405Z'
                        fill='#00AAFF'
                      />
                    </g>
                  </g>
                  <g id='subtask-3'>
                    <g id='Group_19'>
                      <path
                        id='Ellipse_18-13_00000079468616715523144160000016933892730996160953_'
                        d='M1193.81 654.805C1203.97 654.805 1212.21 646.567 1212.21 636.405C1212.21 626.243 1203.97 618.005 1193.81 618.005C1183.65 618.005 1175.41 626.243 1175.41 636.405C1175.41 646.567 1183.65 654.805 1193.81 654.805Z'
                        fill='#F6F9FD'
                      />
                      <g id='Group_20'>
                        <path
                          id='Ellipse_18-14_00000116939949189246572020000002421000659259866530_'
                          d='M1193.8 654.765C1203.96 654.765 1212.2 646.527 1212.2 636.365C1212.2 626.203 1203.96 617.965 1193.8 617.965C1183.64 617.965 1175.4 626.203 1175.4 636.365C1175.4 646.527 1183.64 654.765 1193.8 654.765Z'
                          fill='white'
                        />
                      </g>
                    </g>
                    <path
                      id='Rectangle_14_00000103265658538507934280000001723390804165526161_'
                      d='M1179.51 629.405L1180.81 644.805C1181.01 647.205 1179.21 649.305 1176.81 649.505C1174.41 649.705 1172.31 647.905 1172.11 645.505L1170.81 630.105C1170.61 627.705 1172.41 625.605 1174.81 625.405C1177.21 625.205 1179.31 627.005 1179.51 629.405Z'
                      fill='#E1E1E1'
                    />
                    <text
                      id='Task_3'
                      transform='translate(1179.99 628.662) rotate(85.2466)'
                      fill='#272727'
                      xmlSpace='preserve'
                      style={{whiteSpace: 'pre'}}
                      font-family='Poppins'
                      font-size='7.3488'
                      letter-spacing='0em'
                    >
                      <tspan x='0' y='8.07208'>
                        Task
                      </tspan>
                    </text>
                    <g id='Group_21' opacity={task[0].TasksAndStudents?.isCompleted ? '1' : '0.4'}>
                      <path
                        id='Ellipse_19-13_00000071556202759943128390000001706461766057955218_'
                        d='M1193.8 649.065C1200.82 649.065 1206.5 643.379 1206.5 636.365C1206.5 629.351 1200.82 623.665 1193.8 623.665C1186.79 623.665 1181.1 629.351 1181.1 636.365C1181.1 643.379 1186.79 649.065 1193.8 649.065Z'
                        fill='#00AAFF'
                      />
                    </g>
                  </g>
                  <g id='rods'>
                    <g id='Group 4'>
                      <g id='Group_22'>
                        <path
                          id='Rectangle_8-13_00000168799644958720293820000009991138216390198706_'
                          d='M1122.51 558.705C1120.11 556.605 1119.81 552.805 1122.01 550.405L1166.31 499.905C1168.41 497.505 1172.21 497.205 1174.61 499.405C1177.01 501.505 1177.31 505.305 1175.11 507.705L1130.81 558.205C1128.71 560.605 1125.01 560.905 1122.51 558.705Z'
                          fill='#F6F9FD'
                        />
                        <g id='Group_23'>
                          <path
                            id='Rectangle_8-14_00000089559431846271538450000014857911304360776858_'
                            d='M1122.5 558.765C1120.1 556.665 1119.8 552.865 1122 550.465L1166.3 499.965C1168.4 497.565 1172.2 497.265 1174.6 499.465C1177 501.565 1177.3 505.365 1175.1 507.765L1130.8 558.265C1128.7 560.665 1125 560.865 1122.5 558.765Z'
                            fill='white'
                          />
                        </g>
                      </g>
                      <g id='Group_24'>
                        <path
                          id='Rectangle_9-13_00000041294308047644181780000016626865061150279599_'
                          d='M1124.21 577.505C1126.31 575.005 1130.01 574.705 1132.51 576.805L1183.71 620.205C1186.21 622.305 1186.51 626.005 1184.41 628.505C1182.31 631.005 1178.61 631.305 1176.11 629.205L1124.91 585.805C1122.41 583.705 1122.11 580.005 1124.21 577.505Z'
                          fill='#F6F9FD'
                        />
                        <g id='Group_25'>
                          <path
                            id='Rectangle_9-14_00000083058738374791646760000013130188700704341388_'
                            d='M1124.2 577.464C1126.3 574.964 1130 574.665 1132.5 576.765L1183.7 620.164C1186.2 622.264 1186.5 625.964 1184.4 628.464C1182.3 630.964 1178.6 631.264 1176.1 629.164L1124.9 585.765C1122.4 583.665 1122.1 579.964 1124.2 577.464Z'
                            fill='white'
                          />
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
                <a href='#' onClick={() => handleUserAction(task[0].TasksAndStudents?.id || 0)}>
                  <g id='box-1'>
                    <g id='Group_26'>
                      <path
                        id='Vector_3'
                        opacity='0.0196078'
                        d='M916.91 607.104C917.41 613.204 922.81 617.704 928.81 617.204L965.41 614.204C971.51 613.704 976.01 608.304 975.51 602.304L972.51 565.704C972.01 559.604 966.61 555.104 960.61 555.604L924.01 558.604C917.91 559.104 913.41 564.504 913.91 570.504L916.91 607.104Z'
                        fill='#FBFCFD'
                      />
                      <path
                        id='Vector_4'
                        opacity='0.0392157'
                        d='M917.011 607.005C917.511 613.105 922.91 617.605 928.91 617.105L965.511 614.105C971.611 613.605 976.11 608.205 975.61 602.205L972.611 565.605C972.111 559.505 966.711 555.005 960.711 555.505L924.111 558.505C918.011 559.005 913.511 564.405 914.011 570.405L917.011 607.005Z'
                        fill='#F8F9FB'
                      />
                      <path
                        id='Vector_5'
                        opacity='0.0588235'
                        d='M917.11 606.904C917.61 613.004 923.01 617.504 929.01 617.004L965.61 614.004C971.71 613.504 976.21 608.104 975.71 602.104L972.71 565.504C972.21 559.404 966.81 554.904 960.81 555.404L924.21 558.404C918.11 558.904 913.61 564.304 914.11 570.304L917.11 606.904Z'
                        fill='#F4F6F9'
                      />
                      <path
                        id='Vector_6'
                        opacity='0.0784314'
                        d='M917.11 606.805C917.61 612.905 923.01 617.405 929.01 616.905L965.61 613.905C971.71 613.405 976.21 608.005 975.71 602.005L972.71 565.405C972.21 559.305 966.81 554.805 960.81 555.305L924.21 558.305C918.11 558.805 913.61 564.205 914.11 570.205L917.11 606.805Z'
                        fill='#F1F3F7'
                      />
                      <path
                        id='Vector_7'
                        opacity='0.0980392'
                        d='M917.211 606.704C917.711 612.804 923.111 617.304 929.111 616.804L965.711 613.804C971.811 613.304 976.311 607.904 975.811 601.904L972.811 565.304C972.311 559.204 966.911 554.704 960.911 555.204L924.311 558.204C918.211 558.704 913.711 564.104 914.211 570.104L917.211 606.704Z'
                        fill='#EDF0F4'
                      />
                      <path
                        id='Vector_8'
                        opacity='0.1176'
                        d='M917.31 606.604C917.81 612.704 923.21 617.204 929.21 616.704L965.81 613.704C971.91 613.204 976.41 607.804 975.91 601.804L972.91 565.204C972.41 559.104 967.01 554.604 961.01 555.104L924.41 558.104C918.31 558.604 913.81 564.004 914.31 570.004L917.31 606.604Z'
                        fill='#EAEDF2'
                      />
                      <path
                        id='Vector_9'
                        opacity='0.1373'
                        d='M917.41 606.505C917.91 612.605 923.31 617.105 929.31 616.605L965.91 613.605C972.01 613.105 976.51 607.705 976.01 601.705L973.01 565.105C972.51 559.005 967.11 554.505 961.11 555.005L924.51 558.005C918.41 558.505 913.91 563.905 914.41 569.905L917.41 606.505Z'
                        fill='#E6EAF0'
                      />
                      <path
                        id='Vector_10'
                        opacity='0.1569'
                        d='M917.511 606.404C918.011 612.504 923.41 617.004 929.41 616.504L966.011 613.504C972.111 613.004 976.61 607.604 976.11 601.604L973.111 565.004C972.611 558.904 967.211 554.404 961.211 554.904L924.611 557.904C918.511 558.404 914.011 563.804 914.511 569.804L917.511 606.404Z'
                        fill='#E3E7EE'
                      />
                      <path
                        id='Vector_11'
                        opacity='0.1765'
                        d='M917.61 606.305C918.11 612.405 923.51 616.905 929.51 616.405L966.11 613.405C972.21 612.905 976.71 607.505 976.21 601.505L973.21 564.905C972.71 558.805 967.31 554.305 961.31 554.805L924.71 557.805C918.61 558.305 914.11 563.705 914.61 569.705L917.61 606.305Z'
                        fill='#DFE3EC'
                      />
                      <path
                        id='Vector_12'
                        opacity='0.1961'
                        d='M917.711 606.204C918.211 612.304 923.611 616.804 929.611 616.304L966.211 613.304C972.311 612.804 976.811 607.404 976.311 601.404L973.311 564.804C972.811 558.704 967.411 554.204 961.411 554.704L924.811 557.704C918.711 558.204 914.211 563.604 914.711 569.604L917.711 606.204Z'
                        fill='#DCE0EA'
                      />
                      <path
                        id='Vector_13'
                        opacity='0.2157'
                        d='M917.711 606.104C918.211 612.204 923.611 616.704 929.611 616.204L966.211 613.204C972.311 612.704 976.811 607.304 976.311 601.304L973.311 564.704C972.811 558.604 967.411 554.104 961.411 554.604L924.811 557.604C918.711 558.104 914.211 563.504 914.711 569.504L917.711 606.104Z'
                        fill='#D8DDE8'
                      />
                      <path
                        id='Vector_14'
                        opacity='0.2353'
                        d='M917.81 606.005C918.31 612.105 923.71 616.605 929.71 616.105L966.31 613.105C972.41 612.605 976.91 607.205 976.41 601.205L973.41 564.605C972.91 558.505 967.51 554.005 961.51 554.505L924.91 557.505C918.81 558.005 914.31 563.405 914.81 569.405L917.81 606.005Z'
                        fill='#D4DAE6'
                      />
                      <path
                        id='Vector_15'
                        opacity='0.2549'
                        d='M917.91 605.904C918.41 612.004 923.81 616.504 929.81 616.004L966.41 613.004C972.51 612.504 977.01 607.104 976.51 601.104L973.51 564.504C973.01 558.404 967.61 553.904 961.61 554.404L925.01 557.404C918.91 557.904 914.41 563.304 914.91 569.304L917.91 605.904Z'
                        fill='#D1D7E3'
                      />
                      <path
                        id='Vector_16'
                        opacity='0.2745'
                        d='M918.011 605.805C918.511 611.905 923.91 616.405 929.91 615.905L966.511 612.905C972.611 612.405 977.11 607.005 976.61 601.005L973.611 564.405C973.111 558.305 967.711 553.805 961.711 554.305L925.111 557.305C919.011 557.805 914.511 563.205 915.011 569.205L918.011 605.805Z'
                        fill='#CDD4E1'
                      />
                      <path
                        id='Vector_17'
                        opacity='0.2941'
                        d='M918.11 605.704C918.61 611.804 924.01 616.304 930.01 615.804L966.61 612.804C972.71 612.304 977.21 606.904 976.71 600.904L973.71 564.304C973.21 558.204 967.81 553.704 961.81 554.204L925.21 557.204C919.11 557.704 914.61 563.104 915.11 569.104L918.11 605.704Z'
                        fill='#CAD1DF'
                      />
                      <path
                        id='Vector_18'
                        opacity='0.3137'
                        d='M918.211 605.604C918.711 611.704 924.111 616.204 930.111 615.704L966.711 612.704C972.811 612.204 977.311 606.804 976.811 600.804L973.811 564.204C973.311 558.104 967.911 553.604 961.911 554.104L925.311 557.104C919.211 557.604 914.711 563.004 915.211 569.004L918.211 605.604Z'
                        fill='#C6CEDD'
                      />
                      <path
                        id='Vector_19'
                        opacity='0.3333'
                        d='M918.211 605.505C918.711 611.605 924.111 616.105 930.111 615.605L966.711 612.605C972.811 612.105 977.311 606.705 976.811 600.705L973.811 564.105C973.311 558.005 967.911 553.505 961.911 554.005L925.311 557.005C919.211 557.505 914.711 562.905 915.211 568.905L918.211 605.505Z'
                        fill='#C3CBDB'
                      />
                      <path
                        id='Vector_20'
                        opacity='0.3529'
                        d='M918.31 605.404C918.81 611.504 924.21 616.004 930.21 615.504L966.81 612.504C972.91 612.004 977.41 606.604 976.91 600.604L973.91 564.004C973.41 557.904 968.01 553.404 962.01 553.904L925.41 556.904C919.31 557.404 914.81 562.804 915.31 568.804L918.31 605.404Z'
                        fill='#BFC8D9'
                      />
                      <path
                        id='Vector_21'
                        opacity='0.3725'
                        d='M918.41 605.305C918.91 611.405 924.31 615.905 930.31 615.405L966.91 612.405C973.01 611.905 977.51 606.505 977.01 600.505L974.01 563.905C973.51 557.805 968.11 553.305 962.11 553.805L925.51 556.805C919.41 557.305 914.91 562.705 915.41 568.705L918.41 605.305Z'
                        fill='#BCC5D7'
                      />
                      <path
                        id='Vector_22'
                        opacity='0.3922'
                        d='M918.511 605.204C919.011 611.304 924.41 615.804 930.41 615.304L967.011 612.304C973.111 611.804 977.61 606.404 977.11 600.404L974.111 563.804C973.611 557.704 968.211 553.204 962.211 553.704L925.611 556.704C919.511 557.204 915.011 562.604 915.511 568.604L918.511 605.204Z'
                        fill='#B8C2D5'
                      />
                      <path
                        id='Vector_23'
                        opacity='0.4118'
                        d='M918.61 605.104C919.11 611.204 924.51 615.704 930.51 615.204L967.11 612.204C973.21 611.704 977.71 606.304 977.21 600.304L974.21 563.704C973.71 557.604 968.31 553.104 962.31 553.604L925.71 556.604C919.61 557.104 915.11 562.504 915.61 568.504L918.61 605.104Z'
                        fill='#B4BFD3'
                      />
                      <path
                        id='Vector_24'
                        opacity='0.4314'
                        d='M918.711 605.005C919.211 611.105 924.611 615.605 930.611 615.105L967.211 612.105C973.311 611.605 977.811 606.205 977.311 600.205L974.311 563.605C973.811 557.505 968.411 553.005 962.411 553.505L925.811 556.505C919.711 557.005 915.211 562.405 915.711 568.405L918.711 605.005Z'
                        fill='#B1BCD0'
                      />
                      <path
                        id='Vector_25'
                        opacity='0.451'
                        d='M918.81 604.904C919.31 611.004 924.71 615.504 930.71 615.004L967.31 612.004C973.41 611.504 977.91 606.104 977.41 600.104L974.41 563.504C973.91 557.404 968.51 552.904 962.51 553.404L925.91 556.404C919.81 556.904 915.31 562.304 915.81 568.304L918.81 604.904Z'
                        fill='#ADB9CE'
                      />
                      <path
                        id='Vector_26'
                        opacity='0.4706'
                        d='M918.81 604.805C919.31 610.905 924.71 615.405 930.71 614.905L967.31 611.905C973.41 611.405 977.91 606.005 977.41 600.005L974.41 563.405C973.91 557.305 968.51 552.805 962.51 553.305L925.91 556.305C919.81 556.805 915.31 562.205 915.81 568.205L918.81 604.805Z'
                        fill='#AAB6CC'
                      />
                      <path
                        id='Vector_27'
                        opacity='0.4902'
                        d='M918.91 604.704C919.41 610.804 924.81 615.304 930.81 614.804L967.41 611.804C973.51 611.304 978.01 605.904 977.51 599.904L974.51 563.304C974.01 557.204 968.61 552.704 962.61 553.204L926.01 556.204C919.91 556.704 915.41 562.104 915.91 568.104L918.91 604.704Z'
                        fill='#A6B3CA'
                      />
                      <path
                        id='Vector_28'
                        opacity='0.5098'
                        d='M919.011 604.604C919.511 610.704 924.91 615.204 930.91 614.704L967.511 611.704C973.611 611.204 978.11 605.804 977.61 599.804L974.611 563.204C974.111 557.104 968.711 552.604 962.711 553.104L926.111 556.104C920.011 556.604 915.511 562.004 916.011 568.004L919.011 604.604Z'
                        fill='#A3AFC8'
                      />
                      <path
                        id='Vector_29'
                        opacity='0.5294'
                        d='M919.11 604.505C919.61 610.605 925.01 615.105 931.01 614.605L967.61 611.605C973.71 611.105 978.21 605.705 977.71 599.705L974.71 563.105C974.21 557.005 968.81 552.505 962.81 553.005L926.21 556.005C920.11 556.505 915.61 561.905 916.11 567.905L919.11 604.505Z'
                        fill='#9FACC6'
                      />
                      <path
                        id='Vector_30'
                        opacity='0.549'
                        d='M919.211 604.404C919.711 610.504 925.111 615.004 931.111 614.504L967.711 611.504C973.811 611.004 978.311 605.604 977.811 599.604L974.811 563.004C974.311 556.904 968.911 552.404 962.911 552.904L926.311 555.904C920.211 556.404 915.711 561.804 916.211 567.804L919.211 604.404Z'
                        fill='#9CA9C4'
                      />
                      <path
                        id='Vector_31'
                        opacity='0.5686'
                        d='M919.31 604.305C919.81 610.405 925.21 614.905 931.21 614.405L967.81 611.405C973.91 610.905 978.41 605.505 977.91 599.505L974.91 562.905C974.41 556.805 969.01 552.305 963.01 552.805L926.41 555.805C920.31 556.305 915.81 561.705 916.31 567.705L919.31 604.305Z'
                        fill='#98A6C2'
                      />
                      <path
                        id='Vector_32'
                        opacity='0.5882'
                        d='M919.31 604.204C919.81 610.304 925.21 614.804 931.21 614.304L967.81 611.304C973.91 610.804 978.41 605.404 977.91 599.404L974.91 562.804C974.41 556.704 969.01 552.204 963.01 552.704L926.41 555.704C920.31 556.204 915.81 561.604 916.31 567.604L919.31 604.204Z'
                        fill='#95A3BF'
                      />
                      <path
                        id='Vector_33'
                        opacity='0.6078'
                        d='M919.41 604.104C919.91 610.204 925.31 614.704 931.31 614.204L967.91 611.204C974.01 610.704 978.51 605.304 978.01 599.304L975.01 562.704C974.51 556.604 969.11 552.104 963.11 552.604L926.51 555.604C920.41 556.104 915.91 561.504 916.41 567.504L919.41 604.104Z'
                        fill='#91A0BD'
                      />
                      <path
                        id='Vector_34'
                        opacity='0.6275'
                        d='M919.511 604.005C920.011 610.105 925.41 614.605 931.41 614.105L968.011 611.105C974.111 610.605 978.61 605.205 978.11 599.205L975.11 562.605C974.61 556.505 969.211 552.005 963.211 552.505L926.611 555.505C920.511 556.005 916.011 561.405 916.511 567.405L919.511 604.005Z'
                        fill='#8D9DBB'
                      />
                      <path
                        id='Vector_35'
                        opacity='0.6471'
                        d='M919.61 603.904C920.11 610.004 925.51 614.504 931.51 614.004L968.11 611.004C974.21 610.504 978.71 605.104 978.21 599.104L975.21 562.504C974.71 556.404 969.31 551.904 963.31 552.404L926.71 555.404C920.61 555.904 916.11 561.304 916.61 567.304L919.61 603.904Z'
                        fill='#8A9AB9'
                      />
                      <path
                        id='Vector_36'
                        opacity='0.6667'
                        d='M919.711 603.805C920.211 609.905 925.611 614.405 931.611 613.905L968.211 610.905C974.311 610.405 978.811 605.005 978.311 599.005L975.311 562.405C974.811 556.305 969.411 551.805 963.411 552.305L926.811 555.305C920.711 555.805 916.211 561.205 916.711 567.205L919.711 603.805Z'
                        fill='#8697B7'
                      />
                      <path
                        id='Vector_37'
                        opacity='0.6863'
                        d='M919.81 603.704C920.31 609.804 925.71 614.304 931.71 613.804L968.31 610.804C974.41 610.304 978.91 604.904 978.41 598.904L975.41 562.304C974.91 556.204 969.51 551.704 963.51 552.204L926.91 555.204C920.81 555.704 916.31 561.104 916.81 567.104L919.81 603.704Z'
                        fill='#8394B5'
                      />
                      <path
                        id='Vector_38'
                        opacity='0.7059'
                        d='M919.91 603.604C920.41 609.704 925.81 614.204 931.81 613.704L968.41 610.704C974.51 610.204 979.01 604.804 978.51 598.804L975.51 562.204C975.01 556.104 969.61 551.604 963.61 552.104L927.01 555.104C920.91 555.604 916.41 561.004 916.91 567.004L919.91 603.604Z'
                        fill='#7F91B3'
                      />
                      <path
                        id='Vector_39'
                        opacity='0.7255'
                        d='M919.91 603.505C920.41 609.605 925.81 614.105 931.81 613.605L968.41 610.605C974.51 610.105 979.01 604.705 978.51 598.705L975.51 562.105C975.01 556.005 969.61 551.505 963.61 552.005L927.01 555.005C920.91 555.505 916.41 560.905 916.91 566.905L919.91 603.505Z'
                        fill='#7C8EB1'
                      />
                      <path
                        id='Vector_40'
                        opacity='0.7451'
                        d='M920.011 603.404C920.511 609.504 925.91 614.004 931.91 613.504L968.511 610.504C974.611 610.004 979.11 604.604 978.61 598.604L975.61 562.004C975.11 555.904 969.711 551.404 963.711 551.904L927.111 554.904C921.011 555.404 916.511 560.804 917.011 566.804L920.011 603.404Z'
                        fill='#788BAF'
                      />
                      <path
                        id='Vector_41'
                        opacity='0.7647'
                        d='M920.11 603.305C920.61 609.405 926.01 613.905 932.01 613.405L968.61 610.405C974.71 609.905 979.21 604.505 978.71 598.505L975.71 561.905C975.21 555.805 969.81 551.305 963.81 551.805L927.21 554.805C921.11 555.305 916.61 560.705 917.11 566.705L920.11 603.305Z'
                        fill='#7588AC'
                      />
                      <path
                        id='Vector_42'
                        opacity='0.7843'
                        d='M920.211 603.204C920.711 609.304 926.111 613.804 932.111 613.304L968.711 610.304C974.811 609.804 979.311 604.404 978.811 598.404L975.811 561.804C975.311 555.704 969.911 551.204 963.911 551.704L927.311 554.704C921.211 555.204 916.711 560.604 917.211 566.604L920.211 603.204Z'
                        fill='#7185AA'
                      />
                      <path
                        id='Vector_43'
                        opacity='0.8039'
                        d='M920.31 603.104C920.81 609.204 926.21 613.704 932.21 613.204L968.81 610.204C974.91 609.704 979.41 604.304 978.91 598.304L975.91 561.704C975.41 555.604 970.01 551.104 964.01 551.604L927.41 554.604C921.31 555.104 916.81 560.504 917.31 566.504L920.31 603.104Z'
                        fill='#6D82A8'
                      />
                      <path
                        id='Vector_44'
                        opacity='0.8235'
                        d='M920.41 603.005C920.91 609.105 926.31 613.605 932.31 613.105L968.91 610.105C975.01 609.605 979.51 604.205 979.01 598.205L976.01 561.605C975.51 555.505 970.11 551.005 964.11 551.505L927.51 554.505C921.41 555.005 916.91 560.405 917.41 566.405L920.41 603.005Z'
                        fill='#6A7FA6'
                      />
                      <path
                        id='Vector_45'
                        opacity='0.8431'
                        d='M920.41 602.904C920.91 609.004 926.31 613.504 932.31 613.004L968.91 610.004C975.01 609.504 979.51 604.104 979.01 598.104L976.01 561.504C975.51 555.404 970.11 550.904 964.11 551.404L927.51 554.404C921.41 554.904 916.91 560.304 917.41 566.304L920.41 602.904Z'
                        fill='#667BA4'
                      />
                      <path
                        id='Vector_46'
                        opacity='0.8627'
                        d='M920.511 602.805C921.011 608.905 926.41 613.405 932.41 612.905L969.011 609.905C975.111 609.405 979.61 604.005 979.11 598.005L976.11 561.405C975.61 555.305 970.211 550.805 964.211 551.305L927.611 554.305C921.511 554.805 917.011 560.205 917.511 566.205L920.511 602.805Z'
                        fill='#6378A2'
                      />
                      <path
                        id='Vector_47'
                        opacity='0.8824'
                        d='M920.61 602.704C921.11 608.804 926.51 613.304 932.51 612.804L969.11 609.804C975.21 609.304 979.71 603.904 979.21 597.904L976.21 561.304C975.71 555.204 970.31 550.704 964.31 551.204L927.71 554.204C921.61 554.704 917.11 560.104 917.61 566.104L920.61 602.704Z'
                        fill='#5F75A0'
                      />
                      <path
                        id='Vector_48'
                        opacity='0.902'
                        d='M920.711 602.604C921.211 608.704 926.611 613.204 932.611 612.704L969.211 609.704C975.311 609.204 979.811 603.804 979.311 597.804L976.311 561.204C975.811 555.104 970.411 550.604 964.411 551.104L927.811 554.104C921.711 554.604 917.211 560.004 917.711 566.004L920.711 602.604Z'
                        fill='#5C729E'
                      />
                      <path
                        id='Vector_49'
                        opacity='0.9216'
                        d='M920.81 602.505C921.31 608.605 926.71 613.105 932.71 612.605L969.31 609.605C975.41 609.105 979.91 603.705 979.41 597.705L976.41 561.105C975.91 555.005 970.51 550.505 964.51 551.005L927.91 554.005C921.81 554.505 917.31 559.905 917.81 565.905L920.81 602.505Z'
                        fill='#586F9B'
                      />
                      <path
                        id='Vector_50'
                        opacity='0.9412'
                        d='M920.91 602.404C921.41 608.504 926.81 613.004 932.81 612.504L969.41 609.504C975.51 609.004 980.01 603.604 979.51 597.604L976.51 561.004C976.01 554.904 970.61 550.404 964.61 550.904L928.01 553.904C921.91 554.404 917.41 559.804 917.91 565.804L920.91 602.404Z'
                        fill='#556C99'
                      />
                      <path
                        id='Vector_51'
                        opacity='0.9608'
                        d='M920.91 602.305C921.41 608.405 926.81 612.905 932.81 612.405L969.41 609.405C975.51 608.905 980.01 603.505 979.51 597.505L976.51 560.905C976.01 554.805 970.61 550.305 964.61 550.805L928.01 553.805C921.91 554.305 917.41 559.705 917.91 565.705L920.91 602.305Z'
                        fill='#516997'
                      />
                      <path
                        id='Vector_52'
                        opacity='0.9804'
                        d='M921.011 602.204C921.511 608.304 926.91 612.804 932.91 612.304L969.511 609.304C975.611 608.804 980.11 603.404 979.61 597.404L976.61 560.804C976.11 554.704 970.711 550.204 964.711 550.704L928.111 553.704C922.011 554.204 917.511 559.604 918.011 565.604L921.011 602.204Z'
                        fill='#4E6695'
                      />
                      <path
                        id='Vector_53'
                        d='M921.11 602.104C921.61 608.204 927.01 612.704 933.01 612.204L969.61 609.204C975.71 608.704 980.21 603.304 979.71 597.304L976.71 560.704C976.21 554.604 970.81 550.104 964.81 550.604L928.21 553.604C922.11 554.104 917.61 559.504 918.11 565.504L921.11 602.104Z'
                        fill='#4A6393'
                      />
                    </g>
                    <path
                      id='Vector_54'
                      d='M922.211 601.105C922.711 607.105 928.011 611.505 933.911 611.005L969.811 608.005C975.811 607.505 980.211 602.205 979.711 596.305L976.711 560.404C976.211 554.404 970.911 550.005 965.011 550.505L929.111 553.505C923.111 554.005 918.711 559.305 919.211 565.205L922.211 601.105Z'
                      fill='url(#paint0_linear_473_2)'
                    />
                    <path
                      id='Vector_55'
                      d='M922.611 601.005C923.111 606.705 928.111 611.005 933.811 610.505L969.711 607.505C975.411 607.005 979.711 602.005 979.211 596.305L976.211 560.405C975.711 554.705 970.711 550.405 965.011 550.905L929.111 553.905C923.411 554.405 919.111 559.405 919.611 565.105L922.611 601.005Z'
                      fill={task[0].TasksAndStudents?.isCompleted ? '#1D87CE' : '#fff'}
                    />
                    <text
                      id='Task 1'
                      transform='translate(966.999 557.441) rotate(85.2466)'
                      fill={task[0].TasksAndStudents?.isCompleted ? '#fff' : '#000'}
                      xmlSpace='preserve'
                      style={{whiteSpace: 'pre'}}
                      font-family='Poppins'
                      font-size='13.3614'
                      font-weight='bold'
                      letter-spacing='0em'
                    >
                      <tspan x='0' y='14.6765'>
                        {task[0].name && task[0].name.length > 3
                          ? truncateText(task[0].name, 4)
                          : task[0].name}
                      </tspan>
                    </text>
                    <path
                      id='Vector_56'
                      d='M931.91 595.705L929.81 570.105C929.51 566.505 932.21 563.305 935.81 563.005L937.91 562.805C941.51 562.505 944.71 565.205 945.01 568.805L947.11 594.405C947.41 598.005 944.71 601.205 941.11 601.505L939.01 601.705C935.31 601.905 932.21 599.305 931.91 595.705Z'
                      fill={task[0].TasksAndStudents?.isCompleted ? '#fff' : '#000'}
                    />
                    <text
                      id='3/7'
                      transform='translate(944.671 572.225) rotate(85.2466)'
                      fill={task[0]?.TasksAndStudents?.isCompleted ? '#1D87CE' : '#fff'}
                      xmlSpace='preserve'
                      style={{whiteSpace: 'pre'}}
                      font-family='Poppins'
                      font-size='11.3287'
                      font-weight='bold'
                      letter-spacing='0em'
                    >
                      <tspan x='0' y='12.465'>
                        {/* {task[0].counter + '/3'} */}
                        {(task[0]?.TasksAndStudents?.counter || 0) +
                          '/' +
                          (task[0]?.total_counter || 0)}
                      </tspan>
                    </text>
                  </g>
                </a>
              </g>
            )}
            {task[1] !== undefined && task[1] !== null && (
              <g id='task-2'>
                <g id='rod-2'>
                  <g id='Rectangle_6-5_00000134930964438695469790000017520895769610767499_'>
                    <g id='Group_27'>
                      <path
                        id='Vector_57'
                        d='M650.51 687.804C653.71 687.004 656.91 688.904 657.71 692.004L752.41 1056.7C753.21 1059.9 751.31 1063.1 748.21 1063.9C745.01 1064.7 741.81 1062.8 741.01 1059.7L646.31 695.004C645.41 691.804 647.31 688.604 650.51 687.804Z'
                        fill='#F6F9FD'
                      />
                    </g>
                    <g id='Group_28'>
                      <path
                        id='Vector_58'
                        d='M650.51 687.804C653.71 687.004 656.91 688.904 657.71 692.004L752.41 1056.7C753.21 1059.9 751.31 1063.1 748.21 1063.9C745.01 1064.7 741.81 1062.8 741.01 1059.7L646.31 695.004C645.41 691.804 647.31 688.604 650.51 687.804Z'
                        fill='#0686EF'
                      />
                    </g>
                  </g>
                  <g id='Group_29'>
                    <path
                      id='Rectangle_6-6_00000063592293941254346420000009786494453269948806_'
                      d='M650.5 687.864C653.7 687.064 656.9 688.964 657.7 692.064L752.399 1056.76C753.199 1059.96 751.3 1063.16 748.2 1063.96C745 1064.76 741.8 1062.86 741 1059.76L646.3 695.064C645.4 691.864 647.3 688.664 650.5 687.864Z'
                      stroke='#00AAFF'
                      stroke-width='4'
                      stroke-miterlimit='10'
                    />
                  </g>
                </g>
                <g id='subtasks-2'>
                  <g id='rods_2'>
                    <g id='Group_30'>
                      <path
                        id='Rectangle_8-13_00000055671326655644338380000016031729223398516115_'
                        d='M764.008 1081.1C765.608 1078.3 769.308 1077.4 772.108 1079L830.008 1113C832.808 1114.6 833.708 1118.3 832.108 1121.1C830.508 1123.9 826.808 1124.8 824.008 1123.2L766.108 1089.2C763.308 1087.5 762.408 1083.9 764.008 1081.1Z'
                        fill='#F6F9FD'
                      />
                      <g id='Group_31'>
                        <path
                          id='Rectangle_8-14_00000156569017028708060190000015742731169108828545_'
                          d='M763.998 1081.06C765.598 1078.26 769.298 1077.36 772.098 1078.96L829.998 1112.96C832.798 1114.56 833.698 1118.26 832.098 1121.06C830.498 1123.86 826.798 1124.76 823.998 1123.16L766.098 1089.16C763.298 1087.46 762.398 1083.86 763.998 1081.06Z'
                          fill='white'
                        />
                      </g>
                    </g>
                    <g id='Group_32'>
                      <path
                        id='Rectangle_9-13_00000035507185815971776190000017285223013894625180_'
                        d='M745.908 1086.3C748.708 1087.9 749.708 1091.5 748.108 1094.3L715.108 1152.8C713.508 1155.6 709.908 1156.6 707.108 1155C704.308 1153.4 703.308 1149.8 704.908 1147L737.908 1088.5C739.508 1085.7 743.108 1084.7 745.908 1086.3Z'
                        fill='#F6F9FD'
                      />
                      <g id='Group_33'>
                        <path
                          id='Rectangle_9-14_00000157302408910914022350000012427529952786939566_'
                          d='M745.898 1086.26C748.698 1087.86 749.699 1091.46 748.099 1094.26L715.099 1152.76C713.499 1155.56 709.899 1156.56 707.099 1154.96C704.299 1153.36 703.298 1149.76 704.898 1146.96L737.898 1088.46C739.498 1085.66 743.098 1084.66 745.898 1086.26Z'
                          fill='white'
                        />
                      </g>
                    </g>
                  </g>
                  <g id='subtask-1_2'>
                    <g id='Group_34'>
                      <path
                        id='Ellipse_16-13_00000128473815751253058190000005069058712988633534_'
                        d='M752.209 1093.6C762.371 1093.6 770.609 1085.37 770.609 1075.2C770.609 1065.04 762.371 1056.8 752.209 1056.8C742.047 1056.8 733.809 1065.04 733.809 1075.2C733.809 1085.37 742.047 1093.6 752.209 1093.6Z'
                        fill='#F6F9FD'
                      />
                      <g id='Group_35'>
                        <path
                          id='Ellipse_16-14_00000180353458279354826750000004296602654176013446_'
                          d='M752.199 1093.56C762.361 1093.56 770.599 1085.33 770.599 1075.16C770.599 1065 762.361 1056.76 752.199 1056.76C742.037 1056.76 733.799 1065 733.799 1075.16C733.799 1085.33 742.037 1093.56 752.199 1093.56Z'
                          fill='white'
                        />
                      </g>
                    </g>
                    <path
                      id='Rectangle_15_00000181060785050382832020000016866383050020379285_'
                      d='M755.209 1058.8L740.309 1062.9C738.009 1063.5 735.609 1062.2 734.909 1059.9C734.309 1057.6 735.609 1055.2 737.909 1054.5L752.809 1050.4C755.109 1049.8 757.509 1051.1 758.209 1053.4C758.909 1055.7 757.609 1058.1 755.209 1058.8Z'
                      fill='#E1E1E1'
                    />
                    <text
                      id='Task_4'
                      transform='translate(754.53 1058.47) rotate(164.436)'
                      fill='#272727'
                      xmlSpace='preserve'
                      style={{whiteSpace: 'pre'}}
                      font-family='Poppins'
                      font-size='6.2224'
                      letter-spacing='0em'
                    >
                      <tspan x='0' y='6.67784'>
                        Task
                      </tspan>
                    </text>
                    <g id='Group_36' opacity={task[1].TasksAndStudents?.isCompleted ? '1' : '0.4'}>
                      <path
                        id='Ellipse_17-13_00000112619372681208433630000009138161295220244612_'
                        d='M752.199 1087.86C759.213 1087.86 764.899 1082.18 764.899 1075.16C764.899 1068.15 759.213 1062.46 752.199 1062.46C745.185 1062.46 739.499 1068.15 739.499 1075.16C739.499 1082.18 745.185 1087.86 752.199 1087.86Z'
                        fill='#00AAFF'
                      />
                    </g>
                  </g>
                  <g id='subtask-2_2'>
                    <g id='Group_37'>
                      <path
                        id='Ellipse_18-13_00000073692074799519235440000017745741084729284018_'
                        d='M701.109 1184C711.271 1184 719.509 1175.77 719.509 1165.6C719.509 1155.44 711.271 1147.2 701.109 1147.2C690.947 1147.2 682.709 1155.44 682.709 1165.6C682.709 1175.77 690.947 1184 701.109 1184Z'
                        fill='#F6F9FD'
                      />
                      <g id='Group_38'>
                        <path
                          id='Ellipse_18-14_00000178165338556367986310000017289439356244734593_'
                          d='M701.099 1184.06C711.261 1184.06 719.499 1175.83 719.499 1165.66C719.499 1155.5 711.261 1147.26 701.099 1147.26C690.937 1147.26 682.699 1155.5 682.699 1165.66C682.699 1175.83 690.937 1184.06 701.099 1184.06Z'
                          fill='white'
                        />
                      </g>
                    </g>
                    <path
                      id='Rectangle_14_00000096747317044943179330000017120760301193341065_'
                      d='M705.309 1150.3L690.409 1154.4C688.109 1155 685.709 1153.7 685.009 1151.4C684.409 1149.1 685.709 1146.7 688.009 1146L702.909 1141.9C705.209 1141.3 707.609 1142.6 708.309 1144.9C709.009 1147.3 707.609 1149.7 705.309 1150.3Z'
                      fill='#E1E1E1'
                    />
                    <text
                      id='Task_5'
                      transform='translate(706.093 1150.6) rotate(164.436)'
                      fill='#272727'
                      xmlSpace='preserve'
                      style={{whiteSpace: 'pre'}}
                      font-family='Poppins'
                      font-size='7.3487'
                      letter-spacing='0em'
                    >
                      <tspan x='0' y='8.07205'>
                        Task
                      </tspan>
                    </text>
                    <g id='Group_39' opacity={task[1].TasksAndStudents?.isCompleted ? '1' : '0.4'}>
                      <path
                        id='Ellipse_19-13_00000059285869384774260590000002667241720815475852_'
                        d='M701.199 1178.36C708.213 1178.36 713.899 1172.68 713.899 1165.66C713.899 1158.65 708.213 1152.96 701.199 1152.96C694.185 1152.96 688.499 1158.65 688.499 1165.66C688.499 1172.68 694.185 1178.36 701.199 1178.36Z'
                        fill='#00AAFF'
                      />
                    </g>
                  </g>
                  <g id='subtask-3_2'>
                    <g id='Group_40'>
                      <path
                        id='Ellipse_21-13_00000009567231597258254050000002878709911118184578_'
                        d='M841.808 1144.2C851.97 1144.2 860.208 1135.97 860.208 1125.8C860.208 1115.64 851.97 1107.4 841.808 1107.4C831.646 1107.4 823.408 1115.64 823.408 1125.8C823.408 1135.97 831.646 1144.2 841.808 1144.2Z'
                        fill='#F6F9FD'
                      />
                      <g id='Group_41'>
                        <path
                          id='Ellipse_21-14_00000158735394769892087510000016474872758860512399_'
                          d='M841.799 1144.16C851.961 1144.16 860.199 1135.93 860.199 1125.76C860.199 1115.6 851.961 1107.36 841.799 1107.36C831.637 1107.36 823.398 1115.6 823.398 1125.76C823.398 1135.93 831.637 1144.16 841.799 1144.16Z'
                          fill='white'
                        />
                      </g>
                    </g>
                    <path
                      id='Rectangle_13_00000077321494968234615430000015254489107780711836_'
                      d='M845.609 1111.3L830.709 1115.4C828.409 1116 826.009 1114.7 825.309 1112.4C824.709 1110.1 826.009 1107.7 828.309 1107L843.209 1102.9C845.509 1102.3 847.909 1103.6 848.609 1105.9C849.309 1108.2 847.909 1110.6 845.609 1111.3Z'
                      fill='#E1E1E1'
                    />
                    <text
                      id='Task_6'
                      transform='translate(846.335 1111.54) rotate(164.436)'
                      fill='#272727'
                      xmlSpace='preserve'
                      style={{whiteSpace: 'pre'}}
                      font-family='Poppins'
                      font-size='7.3487'
                      letter-spacing='0em'
                    >
                      <tspan x='0' y='8.07205'>
                        Task
                      </tspan>
                    </text>
                    <g id='Group_42' opacity={task[1].TasksAndStudents?.isCompleted ? '1' : '0.4'}>
                      <path
                        id='Ellipse_20-13_00000026134107029875395480000000413829725654885802_'
                        d='M841.8 1138.46C848.814 1138.46 854.5 1132.78 854.5 1125.76C854.5 1118.75 848.814 1113.06 841.8 1113.06C834.786 1113.06 829.1 1118.75 829.1 1125.76C829.1 1132.78 834.786 1138.46 841.8 1138.46Z'
                        fill='#00AAFF'
                      />
                    </g>
                  </g>
                </g>
                <a href='#' onClick={() => handleUserAction(task[1].TasksAndStudents?.id || 0)}>
                  <g id='box-2'>
                    <g id='Group_43'>
                      <path
                        id='Vector_59'
                        opacity='0.0196078'
                        d='M678.009 888.204C672.109 889.804 668.709 896.004 670.309 901.804L680.209 937.204C681.809 943.104 688.009 946.504 693.809 944.904L729.209 935.004C735.109 933.404 738.509 927.204 736.909 921.404L727.009 886.004C725.409 880.104 719.209 876.704 713.409 878.304L678.009 888.204Z'
                        fill='#FBFCFD'
                      />
                      <path
                        id='Vector_60'
                        opacity='0.0392157'
                        d='M678.11 888.204C672.21 889.804 668.81 896.004 670.41 901.804L680.31 937.204C681.91 943.104 688.11 946.504 693.91 944.904L729.31 935.004C735.21 933.404 738.61 927.204 737.01 921.404L727.11 886.004C725.51 880.104 719.31 876.704 713.51 878.304L678.11 888.204Z'
                        fill='#F8F9FB'
                      />
                      <path
                        id='Vector_61'
                        opacity='0.0588235'
                        d='M678.209 888.305C672.309 889.905 668.909 896.105 670.509 901.905L680.409 937.305C682.009 943.205 688.209 946.605 694.009 945.005L729.409 935.105C735.309 933.505 738.709 927.305 737.109 921.505L727.209 886.105C725.609 880.205 719.409 876.805 713.609 878.405L678.209 888.305Z'
                        fill='#F4F6F9'
                      />
                      <path
                        id='Vector_62'
                        opacity='0.0784314'
                        d='M678.31 888.305C672.41 889.905 669.01 896.105 670.61 901.905L680.51 937.305C682.11 943.205 688.31 946.605 694.11 945.005L729.51 935.105C735.41 933.505 738.81 927.305 737.21 921.505L727.31 886.105C725.71 880.205 719.51 876.805 713.71 878.405L678.31 888.305Z'
                        fill='#F1F3F7'
                      />
                      <path
                        id='Vector_63'
                        opacity='0.0980392'
                        d='M678.409 888.404C672.509 890.004 669.109 896.204 670.709 902.004L680.609 937.404C682.209 943.304 688.409 946.704 694.209 945.104L729.609 935.204C735.509 933.604 738.909 927.404 737.309 921.604L727.409 886.204C725.809 880.304 719.609 876.904 713.809 878.504L678.409 888.404Z'
                        fill='#EDF0F4'
                      />
                      <path
                        id='Vector_64'
                        opacity='0.1176'
                        d='M678.509 888.505C672.609 890.105 669.209 896.305 670.809 902.105L680.709 937.505C682.309 943.405 688.509 946.805 694.309 945.205L729.709 935.305C735.609 933.705 739.009 927.505 737.409 921.705L727.509 886.305C725.909 880.405 719.709 877.005 713.909 878.605L678.509 888.505Z'
                        fill='#EAEDF2'
                      />
                      <path
                        id='Vector_65'
                        opacity='0.1373'
                        d='M678.709 888.505C672.809 890.105 669.409 896.305 671.009 902.105L680.909 937.505C682.509 943.405 688.709 946.805 694.509 945.205L729.909 935.305C735.809 933.705 739.209 927.505 737.609 921.705L727.709 886.305C726.109 880.405 719.909 877.005 714.109 878.605L678.709 888.505Z'
                        fill='#E6EAF0'
                      />
                      <path
                        id='Vector_66'
                        opacity='0.1569'
                        d='M678.81 888.605C672.91 890.205 669.51 896.404 671.11 902.204L681.01 937.605C682.61 943.505 688.81 946.904 694.61 945.304L730.01 935.404C735.91 933.804 739.31 927.604 737.71 921.804L727.81 886.404C726.21 880.504 720.01 877.104 714.21 878.704L678.81 888.605Z'
                        fill='#E3E7EE'
                      />
                      <path
                        id='Vector_67'
                        opacity='0.1765'
                        d='M678.909 888.704C673.009 890.304 669.609 896.504 671.209 902.304L681.109 937.704C682.709 943.604 688.909 947.004 694.709 945.404L730.109 935.504C736.009 933.904 739.409 927.704 737.809 921.904L727.909 886.504C726.309 880.604 720.109 877.204 714.309 878.804L678.909 888.704Z'
                        fill='#DFE3EC'
                      />
                      <path
                        id='Vector_68'
                        opacity='0.1961'
                        d='M679.009 888.704C673.109 890.304 669.709 896.504 671.309 902.304L681.209 937.704C682.809 943.604 689.009 947.004 694.809 945.404L730.209 935.504C736.109 933.904 739.509 927.704 737.909 921.904L728.009 886.504C726.409 880.604 720.209 877.204 714.409 878.804L679.009 888.704Z'
                        fill='#DCE0EA'
                      />
                      <path
                        id='Vector_69'
                        opacity='0.2157'
                        d='M679.11 888.805C673.21 890.405 669.81 896.605 671.41 902.405L681.31 937.805C682.91 943.705 689.11 947.105 694.91 945.505L730.31 935.605C736.21 934.005 739.61 927.805 738.01 922.005L728.11 886.605C726.51 880.705 720.31 877.305 714.51 878.905L679.11 888.805Z'
                        fill='#D8DDE8'
                      />
                      <path
                        id='Vector_70'
                        opacity='0.2353'
                        d='M679.209 888.904C673.309 890.504 669.909 896.704 671.509 902.504L681.409 937.904C683.009 943.804 689.209 947.204 695.009 945.604L730.409 935.704C736.309 934.104 739.709 927.904 738.109 922.104L728.209 886.704C726.609 880.804 720.409 877.404 714.609 879.004L679.209 888.904Z'
                        fill='#D4DAE6'
                      />
                      <path
                        id='Vector_71'
                        opacity='0.2549'
                        d='M679.31 888.904C673.41 890.504 670.01 896.704 671.61 902.504L681.51 937.904C683.11 943.804 689.31 947.204 695.11 945.604L730.51 935.704C736.41 934.104 739.81 927.904 738.21 922.104L728.31 886.704C726.71 880.804 720.51 877.404 714.71 879.004L679.31 888.904Z'
                        fill='#D1D7E3'
                      />
                      <path
                        id='Vector_72'
                        opacity='0.2745'
                        d='M679.509 889.005C673.609 890.605 670.209 896.805 671.809 902.605L681.709 938.005C683.309 943.905 689.509 947.305 695.309 945.705L730.709 935.805C736.609 934.205 740.009 928.005 738.409 922.205L728.509 886.805C726.909 880.905 720.709 877.505 714.909 879.105L679.509 889.005Z'
                        fill='#CDD4E1'
                      />
                      <path
                        id='Vector_73'
                        opacity='0.2941'
                        d='M679.61 889.105C673.71 890.705 670.31 896.904 671.91 902.704L681.81 938.105C683.41 944.005 689.61 947.404 695.41 945.804L730.81 935.904C736.71 934.304 740.11 928.104 738.51 922.304L728.61 886.904C727.01 881.004 720.81 877.604 715.01 879.204L679.61 889.105Z'
                        fill='#CAD1DF'
                      />
                      <path
                        id='Vector_74'
                        opacity='0.3137'
                        d='M679.709 889.105C673.809 890.705 670.409 896.904 672.009 902.704L681.909 938.105C683.509 944.005 689.709 947.404 695.509 945.804L730.909 935.904C736.809 934.304 740.209 928.104 738.609 922.304L728.709 886.904C727.109 881.004 720.909 877.604 715.109 879.204L679.709 889.105Z'
                        fill='#C6CEDD'
                      />
                      <path
                        id='Vector_75'
                        opacity='0.3333'
                        d='M679.81 889.204C673.91 890.804 670.51 897.004 672.11 902.804L682.01 938.204C683.61 944.104 689.81 947.504 695.61 945.904L731.01 936.004C736.91 934.404 740.31 928.204 738.71 922.404L728.81 887.004C727.21 881.104 721.01 877.704 715.21 879.304L679.81 889.204Z'
                        fill='#C3CBDB'
                      />
                      <path
                        id='Vector_76'
                        opacity='0.3529'
                        d='M679.909 889.204C674.009 890.804 670.609 897.004 672.209 902.804L682.109 938.204C683.709 944.104 689.909 947.504 695.709 945.904L731.109 936.004C737.009 934.404 740.409 928.204 738.809 922.404L728.909 887.004C727.309 881.104 721.109 877.704 715.309 879.304L679.909 889.204Z'
                        fill='#BFC8D9'
                      />
                      <path
                        id='Vector_77'
                        opacity='0.3725'
                        d='M680.009 889.305C674.109 890.905 670.709 897.105 672.309 902.905L682.209 938.305C683.809 944.205 690.009 947.605 695.809 946.005L731.209 936.105C737.109 934.505 740.509 928.305 738.909 922.505L729.009 887.105C727.409 881.205 721.209 877.805 715.409 879.405L680.009 889.305Z'
                        fill='#BCC5D7'
                      />
                      <path
                        id='Vector_78'
                        opacity='0.3922'
                        d='M680.11 889.404C674.21 891.004 670.81 897.204 672.41 903.004L682.31 938.404C683.91 944.304 690.11 947.704 695.91 946.104L731.31 936.204C737.21 934.604 740.61 928.404 739.01 922.604L729.11 887.204C727.51 881.304 721.31 877.904 715.51 879.504L680.11 889.404Z'
                        fill='#B8C2D5'
                      />
                      <path
                        id='Vector_79'
                        opacity='0.4118'
                        d='M680.31 889.404C674.41 891.004 671.01 897.204 672.61 903.004L682.51 938.404C684.11 944.304 690.31 947.704 696.11 946.104L731.51 936.204C737.41 934.604 740.81 928.404 739.21 922.604L729.31 887.204C727.71 881.304 721.51 877.904 715.71 879.504L680.31 889.404Z'
                        fill='#B4BFD3'
                      />
                      <path
                        id='Vector_80'
                        opacity='0.4314'
                        d='M680.409 889.505C674.509 891.105 671.109 897.305 672.709 903.105L682.609 938.505C684.209 944.405 690.409 947.805 696.209 946.205L731.609 936.305C737.509 934.705 740.909 928.505 739.309 922.705L729.409 887.305C727.809 881.405 721.609 878.005 715.809 879.605L680.409 889.505Z'
                        fill='#B1BCD0'
                      />
                      <path
                        id='Vector_81'
                        opacity='0.451'
                        d='M680.509 889.605C674.609 891.205 671.209 897.404 672.809 903.204L682.709 938.605C684.309 944.505 690.509 947.904 696.309 946.304L731.709 936.404C737.609 934.804 741.009 928.604 739.409 922.804L729.509 887.404C727.909 881.504 721.709 878.104 715.909 879.704L680.509 889.605Z'
                        fill='#ADB9CE'
                      />
                      <path
                        id='Vector_82'
                        opacity='0.4706'
                        d='M680.61 889.605C674.71 891.205 671.31 897.404 672.91 903.204L682.81 938.605C684.41 944.505 690.61 947.904 696.41 946.304L731.81 936.404C737.71 934.804 741.11 928.604 739.51 922.804L729.61 887.404C728.01 881.504 721.81 878.104 716.01 879.704L680.61 889.605Z'
                        fill='#AAB6CC'
                      />
                      <path
                        id='Vector_83'
                        opacity='0.4902'
                        d='M680.709 889.704C674.809 891.304 671.409 897.504 673.009 903.304L682.909 938.704C684.509 944.604 690.709 948.004 696.509 946.404L731.909 936.504C737.809 934.904 741.209 928.704 739.609 922.904L729.709 887.504C728.109 881.604 721.909 878.204 716.109 879.804L680.709 889.704Z'
                        fill='#A6B3CA'
                      />
                      <path
                        id='Vector_84'
                        opacity='0.5098'
                        d='M680.81 889.805C674.91 891.405 671.51 897.605 673.11 903.405L683.01 938.805C684.61 944.705 690.81 948.105 696.61 946.505L732.01 936.605C737.91 935.005 741.31 928.805 739.71 923.005L729.81 887.605C728.21 881.705 722.01 878.305 716.21 879.905L680.81 889.805Z'
                        fill='#A3AFC8'
                      />
                      <path
                        id='Vector_85'
                        opacity='0.5294'
                        d='M680.909 889.805C675.009 891.405 671.609 897.605 673.209 903.405L683.109 938.805C684.709 944.705 690.909 948.105 696.709 946.505L732.109 936.605C738.009 935.005 741.409 928.805 739.809 923.005L729.909 887.605C728.309 881.705 722.109 878.305 716.309 879.905L680.909 889.805Z'
                        fill='#9FACC6'
                      />
                      <path
                        id='Vector_86'
                        opacity='0.549'
                        d='M681.11 889.904C675.21 891.504 671.81 897.704 673.41 903.504L683.31 938.904C684.91 944.804 691.11 948.204 696.91 946.604L732.31 936.704C738.21 935.104 741.61 928.904 740.01 923.104L730.11 887.704C728.51 881.804 722.31 878.404 716.51 880.004L681.11 889.904Z'
                        fill='#9CA9C4'
                      />
                      <path
                        id='Vector_87'
                        opacity='0.5686'
                        d='M681.209 890.005C675.309 891.605 671.909 897.805 673.509 903.605L683.409 939.005C685.009 944.905 691.209 948.305 697.009 946.705L732.409 936.805C738.309 935.205 741.709 929.005 740.109 923.205L730.209 887.805C728.609 881.905 722.409 878.505 716.609 880.105L681.209 890.005Z'
                        fill='#98A6C2'
                      />
                      <path
                        id='Vector_88'
                        opacity='0.5882'
                        d='M681.31 890.005C675.41 891.605 672.01 897.805 673.61 903.605L683.51 939.005C685.11 944.905 691.31 948.305 697.11 946.705L732.51 936.805C738.41 935.205 741.81 929.005 740.21 923.205L730.31 887.805C728.71 881.905 722.51 878.505 716.71 880.105L681.31 890.005Z'
                        fill='#95A3BF'
                      />
                      <path
                        id='Vector_89'
                        opacity='0.6078'
                        d='M681.409 890.105C675.509 891.705 672.109 897.904 673.709 903.704L683.609 939.105C685.209 945.005 691.409 948.404 697.209 946.804L732.609 936.904C738.509 935.304 741.909 929.104 740.309 923.304L730.409 887.904C728.809 882.004 722.609 878.604 716.809 880.204L681.409 890.105Z'
                        fill='#91A0BD'
                      />
                      <path
                        id='Vector_90'
                        opacity='0.6275'
                        d='M681.509 890.105C675.609 891.705 672.209 897.904 673.809 903.704L683.709 939.105C685.309 945.005 691.509 948.404 697.309 946.804L732.709 936.904C738.609 935.304 742.009 929.104 740.409 923.304L730.509 887.904C728.909 882.004 722.709 878.604 716.909 880.204L681.509 890.105Z'
                        fill='#8D9DBB'
                      />
                      <path
                        id='Vector_91'
                        opacity='0.6471'
                        d='M681.61 890.204C675.71 891.804 672.31 898.004 673.91 903.804L683.81 939.204C685.41 945.104 691.61 948.504 697.41 946.904L732.81 937.004C738.71 935.404 742.11 929.204 740.51 923.404L730.61 888.004C729.01 882.104 722.81 878.704 717.01 880.304L681.61 890.204Z'
                        fill='#8A9AB9'
                      />
                      <path
                        id='Vector_92'
                        opacity='0.6667'
                        d='M681.709 890.305C675.809 891.905 672.409 898.105 674.009 903.905L683.909 939.305C685.509 945.205 691.709 948.605 697.509 947.005L732.909 937.105C738.809 935.505 742.209 929.305 740.609 923.505L730.709 888.105C729.109 882.205 722.909 878.805 717.109 880.405L681.709 890.305Z'
                        fill='#8697B7'
                      />
                      <path
                        id='Vector_93'
                        opacity='0.6863'
                        d='M681.81 890.305C675.91 891.905 672.51 898.105 674.11 903.905L684.01 939.305C685.61 945.205 691.81 948.605 697.61 947.005L733.01 937.105C738.91 935.505 742.31 929.305 740.71 923.505L730.81 888.105C729.21 882.205 723.01 878.805 717.21 880.405L681.81 890.305Z'
                        fill='#8394B5'
                      />
                      <path
                        id='Vector_94'
                        opacity='0.7059'
                        d='M682.009 890.404C676.109 892.004 672.709 898.204 674.309 904.004L684.209 939.404C685.809 945.304 692.009 948.704 697.809 947.104L733.209 937.204C739.109 935.604 742.509 929.404 740.909 923.604L731.009 888.204C729.409 882.304 723.209 878.904 717.409 880.504L682.009 890.404Z'
                        fill='#7F91B3'
                      />
                      <path
                        id='Vector_95'
                        opacity='0.7255'
                        d='M682.11 890.505C676.21 892.105 672.81 898.305 674.41 904.105L684.31 939.505C685.91 945.405 692.11 948.805 697.91 947.205L733.31 937.305C739.21 935.705 742.61 929.505 741.01 923.705L731.11 888.305C729.51 882.405 723.31 879.005 717.51 880.605L682.11 890.505Z'
                        fill='#7C8EB1'
                      />
                      <path
                        id='Vector_96'
                        opacity='0.7451'
                        d='M682.209 890.505C676.309 892.105 672.909 898.305 674.509 904.105L684.409 939.505C686.009 945.405 692.209 948.805 698.009 947.205L733.409 937.305C739.309 935.705 742.709 929.505 741.109 923.705L731.209 888.305C729.609 882.405 723.409 879.005 717.609 880.605L682.209 890.505Z'
                        fill='#788BAF'
                      />
                      <path
                        id='Vector_97'
                        opacity='0.7647'
                        d='M682.31 890.605C676.41 892.205 673.01 898.404 674.61 904.204L684.51 939.605C686.11 945.505 692.31 948.904 698.11 947.304L733.51 937.404C739.41 935.804 742.81 929.604 741.21 923.804L731.31 888.404C729.71 882.504 723.51 879.104 717.71 880.704L682.31 890.605Z'
                        fill='#7588AC'
                      />
                      <path
                        id='Vector_98'
                        opacity='0.7843'
                        d='M682.409 890.704C676.509 892.304 673.109 898.504 674.709 904.304L684.609 939.704C686.209 945.604 692.409 949.004 698.209 947.404L733.609 937.504C739.509 935.904 742.909 929.704 741.309 923.904L731.409 888.504C729.809 882.604 723.609 879.204 717.809 880.804L682.409 890.704Z'
                        fill='#7185AA'
                      />
                      <path
                        id='Vector_99'
                        opacity='0.8039'
                        d='M682.509 890.704C676.609 892.304 673.209 898.504 674.809 904.304L684.709 939.704C686.309 945.604 692.509 949.004 698.309 947.404L733.709 937.504C739.609 935.904 743.009 929.704 741.409 923.904L731.509 888.504C729.909 882.604 723.709 879.204 717.909 880.804L682.509 890.704Z'
                        fill='#6D82A8'
                      />
                      <path
                        id='Vector_100'
                        opacity='0.8235'
                        d='M682.61 890.805C676.71 892.405 673.31 898.605 674.91 904.405L684.81 939.805C686.41 945.705 692.61 949.105 698.41 947.505L733.81 937.605C739.71 936.005 743.11 929.805 741.51 924.005L731.61 888.605C730.01 882.705 723.81 879.305 718.01 880.905L682.61 890.805Z'
                        fill='#6A7FA6'
                      />
                      <path
                        id='Vector_101'
                        opacity='0.8431'
                        d='M682.81 890.904C676.91 892.504 673.51 898.704 675.11 904.504L685.01 939.904C686.61 945.804 692.81 949.204 698.61 947.604L734.01 937.704C739.91 936.104 743.31 929.904 741.71 924.104L731.81 888.704C730.21 882.804 724.01 879.404 718.21 881.004L682.81 890.904Z'
                        fill='#667BA4'
                      />
                      <path
                        id='Vector_102'
                        opacity='0.8627'
                        d='M682.909 890.904C677.009 892.504 673.609 898.704 675.209 904.504L685.109 939.904C686.709 945.804 692.909 949.204 698.709 947.604L734.109 937.704C740.009 936.104 743.409 929.904 741.809 924.104L731.909 888.704C730.309 882.804 724.109 879.404 718.309 881.004L682.909 890.904Z'
                        fill='#6378A2'
                      />
                      <path
                        id='Vector_103'
                        opacity='0.8824'
                        d='M683.009 891.005C677.109 892.605 673.709 898.805 675.309 904.605L685.209 940.005C686.809 945.905 693.009 949.305 698.809 947.705L734.209 937.805C740.109 936.205 743.509 930.005 741.909 924.205L732.009 888.805C730.409 882.905 724.209 879.505 718.409 881.105L683.009 891.005Z'
                        fill='#5F75A0'
                      />
                      <path
                        id='Vector_104'
                        opacity='0.902'
                        d='M683.11 891.005C677.21 892.605 673.81 898.805 675.41 904.605L685.31 940.005C686.91 945.905 693.11 949.305 698.91 947.705L734.31 937.805C740.21 936.205 743.61 930.005 742.01 924.205L732.11 888.805C730.51 882.905 724.31 879.505 718.51 881.105L683.11 891.005Z'
                        fill='#5C729E'
                      />
                      <path
                        id='Vector_105'
                        opacity='0.9216'
                        d='M683.209 891.105C677.309 892.705 673.909 898.904 675.509 904.704L685.409 940.105C687.009 946.005 693.209 949.404 699.009 947.804L734.409 937.904C740.309 936.304 743.709 930.104 742.109 924.304L732.209 888.904C730.609 883.004 724.409 879.604 718.609 881.204L683.209 891.105Z'
                        fill='#586F9B'
                      />
                      <path
                        id='Vector_106'
                        opacity='0.9412'
                        d='M683.31 891.204C677.41 892.804 674.01 899.004 675.61 904.804L685.51 940.204C687.11 946.104 693.31 949.504 699.11 947.904L734.51 938.004C740.41 936.404 743.81 930.204 742.21 924.404L732.31 889.004C730.71 883.104 724.51 879.704 718.71 881.304L683.31 891.204Z'
                        fill='#556C99'
                      />
                      <path
                        id='Vector_107'
                        opacity='0.9608'
                        d='M683.409 891.204C677.509 892.804 674.109 899.004 675.709 904.804L685.609 940.204C687.209 946.104 693.409 949.504 699.209 947.904L734.609 938.004C740.509 936.404 743.909 930.204 742.309 924.404L732.409 889.004C730.809 883.104 724.609 879.704 718.809 881.304L683.409 891.204Z'
                        fill='#516997'
                      />
                      <path
                        id='Vector_108'
                        opacity='0.9804'
                        d='M683.61 891.305C677.71 892.905 674.31 899.105 675.91 904.905L685.81 940.305C687.41 946.205 693.61 949.605 699.41 948.005L734.81 938.105C740.71 936.505 744.11 930.305 742.51 924.505L732.61 889.105C731.01 883.205 724.81 879.805 719.01 881.405L683.61 891.305Z'
                        fill='#4E6695'
                      />
                      <path
                        id='Vector_109'
                        d='M683.709 891.404C677.809 893.004 674.409 899.204 676.009 905.004L685.909 940.404C687.509 946.304 693.709 949.704 699.509 948.104L734.909 938.204C740.809 936.604 744.209 930.404 742.609 924.604L732.709 889.204C731.109 883.304 724.909 879.904 719.109 881.504L683.709 891.404Z'
                        fill='#4A6393'
                      />
                    </g>
                    <path
                      id='Vector_110'
                      d='M684.81 892.205C679.01 893.805 675.61 899.805 677.21 905.605L686.91 940.305C688.51 946.105 694.51 949.505 700.31 947.905L735.01 938.205C740.81 936.605 744.21 930.605 742.61 924.805L732.91 890.105C731.31 884.305 725.31 880.905 719.51 882.505L684.81 892.205Z'
                      fill='url(#paint1_linear_473_2)'
                    />
                    <path
                      id='Vector_111'
                      d='M685.01 892.605C679.51 894.105 676.21 899.904 677.81 905.404L687.51 940.105C689.01 945.605 694.81 948.904 700.31 947.304L735.01 937.605C740.51 936.105 743.81 930.304 742.21 924.804L732.51 890.105C731.01 884.605 725.21 881.304 719.71 882.904L685.01 892.605Z'
                      fill={task[1].TasksAndStudents?.isCompleted ? '#1D87CE' : '#fff'}
                    />
                    <text
                      id='Task 2'
                      transform='translate(736.352 927.932) rotate(164.436)'
                      fill={task[1].TasksAndStudents?.isCompleted ? '#fff' : '#000'}
                      xmlSpace='preserve'
                      style={{whiteSpace: 'pre'}}
                      font-family='Poppins'
                      font-size='13.3613'
                      font-weight='bold'
                      letter-spacing='0em'
                    >
                      <tspan x='0' y='14.6765'>
                        {task[1].name && task[1].name.length > 3
                          ? truncateText(task[1].name, 4)
                          : task[1].name}
                      </tspan>
                    </text>
                    <path
                      id='Vector_112'
                      d='M692.009 900.704L716.809 893.804C720.309 892.804 723.909 894.904 724.909 898.304L725.509 900.304C726.509 903.804 724.409 907.404 721.009 908.404L696.209 915.304C692.709 916.304 689.109 914.204 688.109 910.804L687.509 908.804C686.509 905.304 688.509 901.704 692.009 900.704Z'
                      fill={task[1].TasksAndStudents?.isCompleted ? '#fff' : '#000'}
                    />
                    <text
                      id='3/7_2'
                      transform='translate(717.393 908.869) rotate(164.436)'
                      fill={task[1]?.TasksAndStudents?.isCompleted ? '#1D87CE' : '#fff'}
                      xmlSpace='preserve'
                      style={{whiteSpace: 'pre'}}
                      font-family='Poppins'
                      font-size='11.3287'
                      font-weight='bold'
                      letter-spacing='0em'
                    >
                      <tspan x='0' y='12.465'>
                        {/* {task[1].counter + '/3'} */}
                        {(task[1]?.TasksAndStudents?.counter || 0) +
                          '/' +
                          (task[1]?.total_counter || 0)}
                      </tspan>
                    </text>
                  </g>
                </a>
              </g>
            )}
            {task[2] !== undefined && task[2] !== null && (
              <g id='task-3'>
                <g id='rod-3'>
                  <g id='Rectangle_6-5_00000173149321324348012240000013151256921046057364_'>
                    <g id='Group_44'>
                      <path
                        id='Vector_113'
                        d='M589.609 687.104C592.709 688.204 594.209 691.604 593.109 694.704L464.409 1048.8C463.309 1051.9 459.909 1053.4 456.809 1052.3C453.709 1051.2 452.209 1047.8 453.309 1044.7L582.009 690.604C583.209 687.604 586.509 686.004 589.609 687.104Z'
                        fill='#F6F9FD'
                      />
                    </g>
                    <g id='Group_45'>
                      <path
                        id='Vector_114'
                        d='M589.609 687.104C592.709 688.204 594.209 691.604 593.109 694.704L464.409 1048.8C463.309 1051.9 459.909 1053.4 456.809 1052.3C453.709 1051.2 452.209 1047.8 453.309 1044.7L582.009 690.604C583.209 687.604 586.509 686.004 589.609 687.104Z'
                        fill='#0686EF'
                      />
                    </g>
                  </g>
                  <g id='Group_46'>
                    <path
                      id='Rectangle_6-6_00000127741273475955119530000015156619083931225534_'
                      d='M589.599 687.164C592.699 688.264 594.199 691.664 593.099 694.764L464.399 1048.86C463.299 1051.96 459.9 1053.46 456.8 1052.36C453.7 1051.26 452.2 1047.86 453.3 1044.76L581.999 690.664C583.199 687.564 586.499 686.064 589.599 687.164Z'
                      stroke='#00AAFF'
                      stroke-width='4'
                      stroke-miterlimit='10'
                    />
                  </g>
                </g>
                <g id='subtasks-3'>
                  <g id='rods_3'>
                    <g id='Group_47'>
                      <path
                        id='Rectangle_8-13_00000005958470558041194060000003250937031689786815_'
                        d='M460.209 1075.4C463.209 1074 466.709 1075.3 468.009 1078.2L496.409 1139C497.809 1142 496.509 1145.5 493.609 1146.8C490.609 1148.2 487.109 1146.9 485.809 1144L457.409 1083.2C456.009 1080.3 457.209 1076.8 460.209 1075.4Z'
                        fill='#F6F9FD'
                      />
                      <g id='Group_48'>
                        <path
                          id='Rectangle_8-14_00000176029430864055871060000000644398179603300752_'
                          d='M460.2 1075.46C463.2 1074.06 466.699 1075.36 467.999 1078.26L496.399 1139.06C497.799 1142.06 496.5 1145.56 493.6 1146.86C490.6 1148.26 487.1 1146.96 485.8 1144.06L457.399 1083.26C455.999 1080.36 457.2 1076.86 460.2 1075.46Z'
                          fill='white'
                        />
                      </g>
                    </g>
                    <g id='Group_49'>
                      <path
                        id='Rectangle_9-13_00000072960702634081178100000009525996504837160608_'
                        d='M442.31 1069.5C443.71 1072.4 442.51 1076 439.61 1077.4L379.31 1106.9C376.41 1108.3 372.809 1107.1 371.409 1104.2C370.009 1101.3 371.21 1097.7 374.11 1096.3L434.409 1066.8C437.409 1065.3 440.91 1066.5 442.31 1069.5Z'
                        fill='#F6F9FD'
                      />
                      <g id='Group_50'>
                        <path
                          id='Rectangle_9-14_00000128476939661041037570000005025197264109191870_'
                          d='M442.399 1069.46C443.799 1072.36 442.6 1075.96 439.7 1077.36L379.399 1106.86C376.499 1108.26 372.899 1107.06 371.499 1104.16C370.099 1101.26 371.3 1097.66 374.2 1096.26L434.499 1066.76C437.399 1065.36 440.899 1066.56 442.399 1069.46Z'
                          fill='white'
                        />
                      </g>
                    </g>
                  </g>
                  <g id='subtask-1_3'>
                    <g id='Group_51'>
                      <path
                        id='Ellipse_16-13_00000057134670444511907190000017425270995638792635_'
                        d='M453.809 1082.3C463.971 1082.3 472.209 1074.07 472.209 1063.9C472.209 1053.74 463.971 1045.5 453.809 1045.5C443.647 1045.5 435.409 1053.74 435.409 1063.9C435.409 1074.07 443.647 1082.3 453.809 1082.3Z'
                        fill='#F6F9FD'
                      />
                      <g id='Group_52'>
                        <path
                          id='Ellipse_16-14_00000022539802706716435070000014251517414712633270_'
                          d='M453.8 1082.26C463.962 1082.26 472.2 1074.03 472.2 1063.86C472.2 1053.7 463.962 1045.46 453.8 1045.46C443.638 1045.46 435.399 1053.7 435.399 1063.86C435.399 1074.03 443.638 1082.26 453.8 1082.26Z'
                          fill='white'
                        />
                      </g>
                    </g>
                    <path
                      id='Rectangle_15_00000058580100547248602030000009620857458719650183_'
                      d='M465.61 1052.1L451.01 1047.1C448.71 1046.3 447.51 1043.8 448.31 1041.5C449.11 1039.2 451.61 1038 453.91 1038.8L468.51 1043.8C470.81 1044.6 472.01 1047.1 471.21 1049.4C470.41 1051.7 467.91 1052.9 465.61 1052.1Z'
                      fill='#E1E1E1'
                    />
                    <text
                      id='Task_7'
                      transform='translate(465.155 1051.49) rotate(-161.029)'
                      fill='#272727'
                      xmlSpace='preserve'
                      style={{whiteSpace: 'pre'}}
                      font-family='Poppins'
                      font-size='6.2225'
                      letter-spacing='0em'
                    >
                      <tspan x='0' y='6.67788'>
                        Task
                      </tspan>
                    </text>
                    <g id='Group_53' opacity={task[2].TasksAndStudents?.isCompleted ? '1' : '0.4'}>
                      <path
                        id='Ellipse_17-13_00000070079045351780857160000006678333240941115815_'
                        d='M453.8 1076.56C460.814 1076.56 466.5 1070.88 466.5 1063.86C466.5 1056.85 460.814 1051.16 453.8 1051.16C446.786 1051.16 441.1 1056.85 441.1 1063.86C441.1 1070.88 446.786 1076.56 453.8 1076.56Z'
                        fill='#00AAFF'
                      />
                    </g>
                  </g>
                  <g id='subtask-2_3'>
                    <g id='Group_54'>
                      <path
                        id='Ellipse_21-13_00000018220793248362029280000012212834293017072774_'
                        d='M498.91 1174.7C509.072 1174.7 517.31 1166.47 517.31 1156.3C517.31 1146.14 509.072 1137.9 498.91 1137.9C488.748 1137.9 480.51 1146.14 480.51 1156.3C480.51 1166.47 488.748 1174.7 498.91 1174.7Z'
                        fill='#F6F9FD'
                      />
                      <g id='Group_55'>
                        <path
                          id='Ellipse_21-14_00000158016244469291700310000003482237113169902485_'
                          d='M498.9 1174.76C509.062 1174.76 517.3 1166.53 517.3 1156.36C517.3 1146.2 509.062 1137.96 498.9 1137.96C488.738 1137.96 480.5 1146.2 480.5 1156.36C480.5 1166.53 488.738 1174.76 498.9 1174.76Z'
                          fill='white'
                        />
                      </g>
                    </g>
                    <path
                      id='Rectangle_13_00000134220544570791065300000015861647232563121056_'
                      d='M510.309 1146.6L495.709 1141.6C493.409 1140.8 492.209 1138.3 493.009 1136C493.809 1133.7 496.309 1132.5 498.609 1133.3L513.209 1138.3C515.509 1139.1 516.709 1141.6 515.909 1143.9C515.009 1146.1 512.509 1147.3 510.309 1146.6Z'
                      fill='#E1E1E1'
                    />
                    <text
                      id='Task_8'
                      transform='translate(510.702 1147.25) rotate(-161.029)'
                      fill='#272727'
                      xmlSpace='preserve'
                      style={{whiteSpace: 'pre'}}
                      font-family='Poppins'
                      font-size='7.3488'
                      letter-spacing='0em'
                    >
                      <tspan x='0' y='8.07208'>
                        Task
                      </tspan>
                    </text>
                    <g id='Group_56' opacity={task[2].TasksAndStudents?.isCompleted ? '1' : '0.4'}>
                      <path
                        id='Ellipse_20-13_00000118392746919321788670000016349352723653175472_'
                        d='M498.9 1169.06C505.914 1169.06 511.6 1163.38 511.6 1156.36C511.6 1149.35 505.914 1143.66 498.9 1143.66C491.886 1143.66 486.2 1149.35 486.2 1156.36C486.2 1163.38 491.886 1169.06 498.9 1169.06Z'
                        fill='#00AAFF'
                      />
                    </g>
                  </g>
                  <g id='subtask-3_3'>
                    <g id='Group_57'>
                      <path
                        id='Ellipse_18-13_00000000931926553212070020000011169701262328987269_'
                        d='M360.41 1127.9C370.572 1127.9 378.81 1119.67 378.81 1109.5C378.81 1099.34 370.572 1091.1 360.41 1091.1C350.248 1091.1 342.01 1099.34 342.01 1109.5C342.01 1119.67 350.248 1127.9 360.41 1127.9Z'
                        fill='#F6F9FD'
                      />
                      <g id='Group_58'>
                        <path
                          id='Ellipse_18-14_00000112599240053306562620000015105702880011673017_'
                          d='M360.5 1127.86C370.662 1127.86 378.899 1119.63 378.899 1109.46C378.899 1099.3 370.662 1091.06 360.5 1091.06C350.337 1091.06 342.1 1099.3 342.1 1109.46C342.1 1119.63 350.337 1127.86 360.5 1127.86Z'
                          fill='white'
                        />
                      </g>
                    </g>
                    <path
                      id='Rectangle_14_00000176740029483820369010000002631311690649584291_'
                      d='M372.61 1099.2L358.01 1094.2C355.71 1093.4 354.51 1090.9 355.31 1088.6C356.11 1086.3 358.61 1085.1 360.91 1085.9L375.51 1090.9C377.81 1091.7 379.01 1094.2 378.21 1096.5C377.41 1098.8 374.91 1100 372.61 1099.2Z'
                      fill='#E1E1E1'
                    />
                    <text
                      id='Task_9'
                      transform='translate(373.025 1099.93) rotate(-161.029)'
                      fill='#272727'
                      xmlSpace='preserve'
                      style={{whiteSpace: 'pre'}}
                      font-family='Poppins'
                      font-size='7.3488'
                      letter-spacing='0em'
                    >
                      <tspan x='0' y='8.07208'>
                        Task
                      </tspan>
                    </text>
                    <g id='Group_59' opacity={task[2].TasksAndStudents?.isCompleted ? '1' : '0.4'}>
                      <path
                        id='Ellipse_19-13_00000127727666705617801870000005252143579943681467_'
                        d='M360.5 1122.16C367.514 1122.16 373.2 1116.48 373.2 1109.46C373.2 1102.45 367.514 1096.76 360.5 1096.76C353.485 1096.76 347.8 1102.45 347.8 1109.46C347.8 1116.48 353.485 1122.16 360.5 1122.16Z'
                        fill='#00AAFF'
                      />
                    </g>
                  </g>
                </g>
                <a href='#' onClick={() => handleUserAction(task[2].TasksAndStudents?.id || 0)}>
                  <g id='box-3'>
                    <g id='Group_60'>
                      <path
                        id='Vector_115'
                        opacity='0.0196078'
                        d='M498.709 867.804C492.909 865.804 486.609 868.904 484.609 874.704L472.709 909.404C470.709 915.204 473.809 921.504 479.609 923.504L514.309 935.404C520.109 937.404 526.409 934.304 528.409 928.504L540.309 893.804C542.309 888.004 539.209 881.704 533.409 879.704L498.709 867.804Z'
                        fill='#FBFCFD'
                      />
                      <path
                        id='Vector_116'
                        opacity='0.0392157'
                        d='M498.709 867.904C492.909 865.904 486.609 869.004 484.609 874.804L472.709 909.504C470.709 915.304 473.809 921.604 479.609 923.604L514.309 935.504C520.109 937.504 526.409 934.404 528.409 928.604L540.309 893.904C542.309 888.104 539.209 881.804 533.409 879.804L498.709 867.904Z'
                        fill='#F8F9FB'
                      />
                      <path
                        id='Vector_117'
                        opacity='0.0588235'
                        d='M498.81 868.004C493.01 866.004 486.71 869.104 484.71 874.904L472.81 909.604C470.81 915.404 473.91 921.704 479.71 923.704L514.41 935.604C520.21 937.604 526.51 934.504 528.51 928.704L540.41 894.004C542.41 888.204 539.31 881.904 533.51 879.904L498.81 868.004Z'
                        fill='#F4F6F9'
                      />
                      <path
                        id='Vector_118'
                        opacity='0.0784314'
                        d='M498.81 868.104C493.01 866.104 486.71 869.204 484.71 875.004L472.81 909.704C470.81 915.504 473.91 921.804 479.71 923.804L514.41 935.704C520.21 937.704 526.51 934.604 528.51 928.804L540.41 894.104C542.41 888.304 539.31 882.004 533.51 880.004L498.81 868.104Z'
                        fill='#F1F3F7'
                      />
                      <path
                        id='Vector_119'
                        opacity='0.0980392'
                        d='M498.909 868.203C493.109 866.203 486.809 869.303 484.809 875.103L472.909 909.803C470.909 915.603 474.009 921.903 479.809 923.903L514.51 935.803C520.31 937.803 526.61 934.703 528.61 928.903L540.51 894.203C542.51 888.403 539.41 882.103 533.61 880.103L498.909 868.203Z'
                        fill='#EDF0F4'
                      />
                      <path
                        id='Vector_120'
                        opacity='0.1176'
                        d='M499.01 868.304C493.21 866.304 486.91 869.404 484.91 875.204L473.01 909.904C471.01 915.704 474.11 922.004 479.91 924.004L514.61 935.904C520.41 937.904 526.71 934.804 528.71 929.004L540.61 894.304C542.61 888.504 539.51 882.204 533.71 880.204L499.01 868.304Z'
                        fill='#EAEDF2'
                      />
                      <path
                        id='Vector_121'
                        opacity='0.1373'
                        d='M499.01 868.504C493.21 866.504 486.91 869.604 484.91 875.404L473.01 910.104C471.01 915.904 474.11 922.204 479.91 924.204L514.61 936.104C520.41 938.104 526.71 935.004 528.71 929.204L540.61 894.504C542.61 888.704 539.51 882.404 533.71 880.404L499.01 868.504Z'
                        fill='#E6EAF0'
                      />
                      <path
                        id='Vector_122'
                        opacity='0.1569'
                        d='M499.11 868.604C493.31 866.604 487.01 869.704 485.01 875.504L473.11 910.204C471.11 916.004 474.21 922.304 480.01 924.304L514.71 936.204C520.51 938.204 526.81 935.104 528.81 929.304L540.71 894.604C542.71 888.804 539.61 882.504 533.81 880.504L499.11 868.604Z'
                        fill='#E3E7EE'
                      />
                      <path
                        id='Vector_123'
                        opacity='0.1765'
                        d='M499.11 868.703C493.31 866.703 487.01 869.803 485.01 875.603L473.11 910.303C471.11 916.103 474.21 922.403 480.01 924.403L514.71 936.303C520.51 938.303 526.81 935.203 528.81 929.403L540.71 894.703C542.71 888.903 539.61 882.603 533.81 880.603L499.11 868.703Z'
                        fill='#DFE3EC'
                      />
                      <path
                        id='Vector_124'
                        opacity='0.1961'
                        d='M499.209 868.804C493.409 866.804 487.109 869.904 485.109 875.704L473.209 910.404C471.209 916.204 474.309 922.504 480.109 924.504L514.809 936.404C520.609 938.404 526.909 935.304 528.909 929.504L540.809 894.804C542.809 889.004 539.709 882.704 533.909 880.704L499.209 868.804Z'
                        fill='#DCE0EA'
                      />
                      <path
                        id='Vector_125'
                        opacity='0.2157'
                        d='M499.209 868.904C493.409 866.904 487.109 870.004 485.109 875.804L473.209 910.504C471.209 916.304 474.309 922.604 480.109 924.604L514.809 936.504C520.609 938.504 526.909 935.404 528.909 929.604L540.809 894.904C542.809 889.104 539.709 882.804 533.909 880.804L499.209 868.904Z'
                        fill='#D8DDE8'
                      />
                      <path
                        id='Vector_126'
                        opacity='0.2353'
                        d='M499.31 869.004C493.51 867.004 487.21 870.104 485.21 875.904L473.31 910.604C471.31 916.404 474.41 922.704 480.21 924.704L514.91 936.604C520.71 938.604 527.01 935.504 529.01 929.704L540.91 895.004C542.91 889.204 539.81 882.904 534.01 880.904L499.31 869.004Z'
                        fill='#D4DAE6'
                      />
                      <path
                        id='Vector_127'
                        opacity='0.2549'
                        d='M499.409 869.203C493.609 867.203 487.309 870.303 485.309 876.103L473.409 910.803C471.409 916.603 474.509 922.903 480.309 924.903L515.01 936.803C520.81 938.803 527.11 935.703 529.11 929.903L541.01 895.203C543.01 889.403 539.91 883.103 534.11 881.103L499.409 869.203Z'
                        fill='#D1D7E3'
                      />
                      <path
                        id='Vector_128'
                        opacity='0.2745'
                        d='M499.409 869.304C493.609 867.304 487.309 870.404 485.309 876.204L473.409 910.904C471.409 916.704 474.509 923.004 480.309 925.004L515.01 936.904C520.81 938.904 527.11 935.804 529.11 930.004L541.01 895.304C543.01 889.504 539.91 883.204 534.11 881.204L499.409 869.304Z'
                        fill='#CDD4E1'
                      />
                      <path
                        id='Vector_129'
                        opacity='0.2941'
                        d='M499.51 869.404C493.71 867.404 487.41 870.504 485.41 876.304L473.51 911.004C471.51 916.804 474.61 923.104 480.41 925.104L515.11 937.004C520.91 939.004 527.21 935.904 529.21 930.104L541.11 895.404C543.11 889.604 540.01 883.304 534.21 881.304L499.51 869.404Z'
                        fill='#CAD1DF'
                      />
                      <path
                        id='Vector_130'
                        opacity='0.3137'
                        d='M499.51 869.504C493.71 867.504 487.41 870.604 485.41 876.404L473.51 911.104C471.51 916.904 474.61 923.204 480.41 925.204L515.11 937.104C520.91 939.104 527.21 936.004 529.21 930.204L541.11 895.504C543.11 889.704 540.01 883.404 534.21 881.404L499.51 869.504Z'
                        fill='#C6CEDD'
                      />
                      <path
                        id='Vector_131'
                        opacity='0.3333'
                        d='M499.61 869.604C493.81 867.604 487.51 870.704 485.51 876.504L473.61 911.204C471.61 917.004 474.71 923.304 480.51 925.304L515.21 937.204C521.01 939.204 527.31 936.104 529.31 930.304L541.21 895.604C543.21 889.804 540.11 883.504 534.31 881.504L499.61 869.604Z'
                        fill='#C3CBDB'
                      />
                      <path
                        id='Vector_132'
                        opacity='0.3529'
                        d='M499.61 869.804C493.81 867.804 487.51 870.904 485.51 876.704L473.61 911.404C471.61 917.204 474.71 923.504 480.51 925.504L515.21 937.404C521.01 939.404 527.31 936.304 529.31 930.504L541.21 895.804C543.21 890.004 540.11 883.704 534.31 881.704L499.61 869.804Z'
                        fill='#BFC8D9'
                      />
                      <path
                        id='Vector_133'
                        opacity='0.3725'
                        d='M499.709 869.904C493.909 867.904 487.609 871.004 485.609 876.804L473.709 911.504C471.709 917.304 474.809 923.604 480.609 925.604L515.309 937.504C521.109 939.504 527.409 936.404 529.409 930.604L541.309 895.904C543.309 890.104 540.209 883.804 534.409 881.804L499.709 869.904Z'
                        fill='#BCC5D7'
                      />
                      <path
                        id='Vector_134'
                        opacity='0.3922'
                        d='M499.81 870.004C494.01 868.004 487.71 871.104 485.71 876.904L473.81 911.604C471.81 917.404 474.91 923.704 480.71 925.704L515.41 937.604C521.21 939.604 527.51 936.504 529.51 930.704L541.41 896.004C543.41 890.204 540.31 883.904 534.51 881.904L499.81 870.004Z'
                        fill='#B8C2D5'
                      />
                      <path
                        id='Vector_135'
                        opacity='0.4118'
                        d='M499.81 870.104C494.01 868.104 487.71 871.204 485.71 877.004L473.81 911.704C471.81 917.504 474.91 923.804 480.71 925.804L515.41 937.704C521.21 939.704 527.51 936.604 529.51 930.804L541.41 896.104C543.41 890.304 540.31 884.004 534.51 882.004L499.81 870.104Z'
                        fill='#B4BFD3'
                      />
                      <path
                        id='Vector_136'
                        opacity='0.4314'
                        d='M499.909 870.203C494.109 868.203 487.809 871.303 485.809 877.103L473.909 911.803C471.909 917.603 475.009 923.903 480.809 925.903L515.51 937.803C521.31 939.803 527.61 936.703 529.61 930.903L541.51 896.203C543.51 890.403 540.41 884.103 534.61 882.103L499.909 870.203Z'
                        fill='#B1BCD0'
                      />
                      <path
                        id='Vector_137'
                        opacity='0.451'
                        d='M499.909 870.304C494.109 868.304 487.809 871.404 485.809 877.204L473.909 911.904C471.909 917.704 475.009 924.004 480.809 926.004L515.51 937.904C521.31 939.904 527.61 936.804 529.61 931.004L541.51 896.304C543.51 890.504 540.41 884.204 534.61 882.204L499.909 870.304Z'
                        fill='#ADB9CE'
                      />
                      <path
                        id='Vector_138'
                        opacity='0.4706'
                        d='M500.01 870.504C494.21 868.504 487.91 871.604 485.91 877.404L474.01 912.104C472.01 917.904 475.11 924.204 480.91 926.204L515.61 938.104C521.41 940.104 527.71 937.004 529.71 931.204L541.61 896.504C543.61 890.704 540.51 884.404 534.71 882.404L500.01 870.504Z'
                        fill='#AAB6CC'
                      />
                      <path
                        id='Vector_139'
                        opacity='0.4902'
                        d='M500.01 870.604C494.21 868.604 487.91 871.704 485.91 877.504L474.01 912.204C472.01 918.004 475.11 924.304 480.91 926.304L515.61 938.204C521.41 940.204 527.71 937.104 529.71 931.304L541.61 896.604C543.61 890.804 540.51 884.504 534.71 882.504L500.01 870.604Z'
                        fill='#A6B3CA'
                      />
                      <path
                        id='Vector_140'
                        opacity='0.5098'
                        d='M500.11 870.703C494.31 868.703 488.01 871.803 486.01 877.603L474.11 912.303C472.11 918.103 475.21 924.403 481.01 926.403L515.71 938.303C521.51 940.303 527.81 937.203 529.81 931.403L541.71 896.703C543.71 890.903 540.61 884.603 534.81 882.603L500.11 870.703Z'
                        fill='#A3AFC8'
                      />
                      <path
                        id='Vector_141'
                        opacity='0.5294'
                        d='M500.209 870.804C494.409 868.804 488.109 871.904 486.109 877.704L474.209 912.404C472.209 918.204 475.309 924.504 481.109 926.504L515.809 938.404C521.609 940.404 527.909 937.304 529.909 931.504L541.809 896.804C543.809 891.004 540.709 884.704 534.909 882.704L500.209 870.804Z'
                        fill='#9FACC6'
                      />
                      <path
                        id='Vector_142'
                        opacity='0.549'
                        d='M500.209 870.904C494.409 868.904 488.109 872.004 486.109 877.804L474.209 912.504C472.209 918.304 475.309 924.604 481.109 926.604L515.809 938.504C521.609 940.504 527.909 937.404 529.909 931.604L541.809 896.904C543.809 891.104 540.709 884.804 534.909 882.804L500.209 870.904Z'
                        fill='#9CA9C4'
                      />
                      <path
                        id='Vector_143'
                        opacity='0.5686'
                        d='M500.31 871.004C494.51 869.004 488.21 872.104 486.21 877.904L474.31 912.604C472.31 918.404 475.41 924.704 481.21 926.704L515.91 938.604C521.71 940.604 528.01 937.504 530.01 931.704L541.91 897.004C543.91 891.204 540.81 884.904 535.01 882.904L500.31 871.004Z'
                        fill='#98A6C2'
                      />
                      <path
                        id='Vector_144'
                        opacity='0.5882'
                        d='M500.31 871.203C494.51 869.203 488.21 872.303 486.21 878.103L474.31 912.803C472.31 918.603 475.41 924.903 481.21 926.903L515.91 938.803C521.71 940.803 528.01 937.703 530.01 931.903L541.91 897.203C543.91 891.403 540.81 885.103 535.01 883.103L500.31 871.203Z'
                        fill='#95A3BF'
                      />
                      <path
                        id='Vector_145'
                        opacity='0.6078'
                        d='M500.409 871.304C494.609 869.304 488.309 872.404 486.309 878.204L474.409 912.904C472.409 918.704 475.509 925.004 481.309 927.004L516.01 938.904C521.81 940.904 528.11 937.804 530.11 932.004L542.01 897.304C544.01 891.504 540.91 885.204 535.11 883.204L500.409 871.304Z'
                        fill='#91A0BD'
                      />
                      <path
                        id='Vector_146'
                        opacity='0.6275'
                        d='M500.51 871.404C494.71 869.404 488.41 872.504 486.41 878.304L474.51 913.004C472.51 918.804 475.61 925.104 481.41 927.104L516.11 939.004C521.91 941.004 528.21 937.904 530.21 932.104L542.11 897.404C544.11 891.604 541.01 885.304 535.21 883.304L500.51 871.404Z'
                        fill='#8D9DBB'
                      />
                      <path
                        id='Vector_147'
                        opacity='0.6471'
                        d='M500.51 871.504C494.71 869.504 488.41 872.604 486.41 878.404L474.51 913.104C472.51 918.904 475.61 925.204 481.41 927.204L516.11 939.104C521.91 941.104 528.21 938.004 530.21 932.204L542.11 897.504C544.11 891.704 541.01 885.404 535.21 883.404L500.51 871.504Z'
                        fill='#8A9AB9'
                      />
                      <path
                        id='Vector_148'
                        opacity='0.6667'
                        d='M500.61 871.604C494.81 869.604 488.51 872.704 486.51 878.504L474.61 913.204C472.61 919.004 475.71 925.304 481.51 927.304L516.21 939.204C522.01 941.204 528.31 938.104 530.31 932.304L542.21 897.604C544.21 891.804 541.11 885.504 535.31 883.504L500.61 871.604Z'
                        fill='#8697B7'
                      />
                      <path
                        id='Vector_149'
                        opacity='0.6863'
                        d='M500.61 871.703C494.81 869.703 488.51 872.803 486.51 878.603L474.61 913.303C472.61 919.103 475.71 925.403 481.51 927.403L516.21 939.303C522.01 941.303 528.31 938.203 530.31 932.403L542.21 897.703C544.21 891.903 541.11 885.603 535.31 883.603L500.61 871.703Z'
                        fill='#8394B5'
                      />
                      <path
                        id='Vector_150'
                        opacity='0.7059'
                        d='M500.709 871.904C494.909 869.904 488.609 873.004 486.609 878.804L474.709 913.504C472.709 919.304 475.809 925.604 481.609 927.604L516.309 939.504C522.109 941.504 528.409 938.404 530.409 932.604L542.309 897.904C544.309 892.104 541.209 885.804 535.409 883.804L500.709 871.904Z'
                        fill='#7F91B3'
                      />
                      <path
                        id='Vector_151'
                        opacity='0.7255'
                        d='M500.709 872.004C494.909 870.004 488.609 873.104 486.609 878.904L474.709 913.604C472.709 919.404 475.809 925.704 481.609 927.704L516.309 939.604C522.109 941.604 528.409 938.504 530.409 932.704L542.309 898.004C544.309 892.204 541.209 885.904 535.409 883.904L500.709 872.004Z'
                        fill='#7C8EB1'
                      />
                      <path
                        id='Vector_152'
                        opacity='0.7451'
                        d='M500.81 872.104C495.01 870.104 488.71 873.204 486.71 879.004L474.81 913.704C472.81 919.504 475.91 925.804 481.71 927.804L516.41 939.704C522.21 941.704 528.51 938.604 530.51 932.804L542.41 898.104C544.41 892.304 541.31 886.004 535.51 884.004L500.81 872.104Z'
                        fill='#788BAF'
                      />
                      <path
                        id='Vector_153'
                        opacity='0.7647'
                        d='M500.909 872.203C495.109 870.203 488.809 873.303 486.809 879.103L474.909 913.803C472.909 919.603 476.009 925.903 481.809 927.903L516.51 939.803C522.31 941.803 528.61 938.703 530.61 932.903L542.51 898.203C544.51 892.403 541.41 886.103 535.61 884.103L500.909 872.203Z'
                        fill='#7588AC'
                      />
                      <path
                        id='Vector_154'
                        opacity='0.7843'
                        d='M500.909 872.304C495.109 870.304 488.809 873.404 486.809 879.204L474.909 913.904C472.909 919.704 476.009 926.004 481.809 928.004L516.51 939.904C522.31 941.904 528.61 938.804 530.61 933.004L542.51 898.304C544.51 892.504 541.41 886.204 535.61 884.204L500.909 872.304Z'
                        fill='#7185AA'
                      />
                      <path
                        id='Vector_155'
                        opacity='0.8039'
                        d='M501.01 872.504C495.21 870.504 488.91 873.604 486.91 879.404L475.01 914.104C473.01 919.904 476.11 926.204 481.91 928.204L516.61 940.104C522.41 942.104 528.71 939.004 530.71 933.204L542.61 898.504C544.61 892.704 541.51 886.404 535.71 884.404L501.01 872.504Z'
                        fill='#6D82A8'
                      />
                      <path
                        id='Vector_156'
                        opacity='0.8235'
                        d='M501.01 872.604C495.21 870.604 488.91 873.704 486.91 879.504L475.01 914.204C473.01 920.004 476.11 926.304 481.91 928.304L516.61 940.204C522.41 942.204 528.71 939.104 530.71 933.304L542.61 898.604C544.61 892.804 541.51 886.504 535.71 884.504L501.01 872.604Z'
                        fill='#6A7FA6'
                      />
                      <path
                        id='Vector_157'
                        opacity='0.8431'
                        d='M501.11 872.703C495.31 870.703 489.01 873.803 487.01 879.603L475.11 914.303C473.11 920.103 476.21 926.403 482.01 928.403L516.71 940.303C522.51 942.303 528.81 939.203 530.81 933.403L542.71 898.703C544.71 892.903 541.61 886.603 535.81 884.603L501.11 872.703Z'
                        fill='#667BA4'
                      />
                      <path
                        id='Vector_158'
                        opacity='0.8627'
                        d='M501.11 872.804C495.31 870.804 489.01 873.904 487.01 879.704L475.11 914.404C473.11 920.204 476.21 926.504 482.01 928.504L516.71 940.404C522.51 942.404 528.81 939.304 530.81 933.504L542.71 898.804C544.71 893.004 541.61 886.704 535.81 884.704L501.11 872.804Z'
                        fill='#6378A2'
                      />
                      <path
                        id='Vector_159'
                        opacity='0.8824'
                        d='M501.209 872.904C495.409 870.904 489.109 874.004 487.109 879.804L475.209 914.504C473.209 920.304 476.309 926.604 482.109 928.604L516.809 940.504C522.609 942.504 528.909 939.404 530.909 933.604L542.809 898.904C544.809 893.104 541.709 886.804 535.909 884.804L501.209 872.904Z'
                        fill='#5F75A0'
                      />
                      <path
                        id='Vector_160'
                        opacity='0.902'
                        d='M501.31 873.004C495.51 871.004 489.21 874.104 487.21 879.904L475.31 914.604C473.31 920.404 476.41 926.704 482.21 928.704L516.91 940.604C522.71 942.604 529.01 939.504 531.01 933.704L542.91 899.004C544.91 893.204 541.81 886.904 536.01 884.904L501.31 873.004Z'
                        fill='#5C729E'
                      />
                      <path
                        id='Vector_161'
                        opacity='0.9216'
                        d='M501.31 873.203C495.51 871.203 489.21 874.303 487.21 880.103L475.31 914.803C473.31 920.603 476.41 926.903 482.21 928.903L516.91 940.803C522.71 942.803 529.01 939.703 531.01 933.903L542.91 899.203C544.91 893.403 541.81 887.103 536.01 885.103L501.31 873.203Z'
                        fill='#586F9B'
                      />
                      <path
                        id='Vector_162'
                        opacity='0.9412'
                        d='M501.409 873.304C495.609 871.304 489.309 874.404 487.309 880.204L475.409 914.904C473.409 920.704 476.509 927.004 482.309 929.004L517.01 940.904C522.81 942.904 529.11 939.804 531.11 934.004L543.01 899.304C545.01 893.504 541.91 887.204 536.11 885.204L501.409 873.304Z'
                        fill='#556C99'
                      />
                      <path
                        id='Vector_163'
                        opacity='0.9608'
                        d='M501.409 873.404C495.609 871.404 489.309 874.504 487.309 880.304L475.409 915.004C473.409 920.804 476.509 927.104 482.309 929.104L517.01 941.004C522.81 943.004 529.11 939.904 531.11 934.104L543.01 899.404C545.01 893.604 541.91 887.304 536.11 885.304L501.409 873.404Z'
                        fill='#516997'
                      />
                      <path
                        id='Vector_164'
                        opacity='0.9804'
                        d='M501.51 873.504C495.71 871.504 489.41 874.604 487.41 880.404L475.51 915.104C473.51 920.904 476.61 927.204 482.41 929.204L517.11 941.104C522.91 943.104 529.21 940.004 531.21 934.204L543.11 899.504C545.11 893.704 542.01 887.404 536.21 885.404L501.51 873.504Z'
                        fill='#4E6695'
                      />
                      <path
                        id='Vector_165'
                        d='M501.51 873.604C495.71 871.604 489.41 874.704 487.41 880.504L475.51 915.204C473.51 921.004 476.61 927.304 482.41 929.304L517.11 941.204C522.91 943.204 529.21 940.104 531.21 934.304L543.11 899.604C545.11 893.804 542.01 887.504 536.21 885.504L501.51 873.604Z'
                        fill='#4A6393'
                      />
                    </g>
                    <path
                      id='Vector_166'
                      d='M502.01 875.004C496.31 873.104 490.11 876.104 488.21 881.704L476.51 915.804C474.61 921.504 477.61 927.704 483.21 929.604L517.31 941.304C523.01 943.204 529.21 940.204 531.11 934.604L542.81 900.504C544.71 894.804 541.71 888.604 536.11 886.704L502.01 875.004Z'
                      fill='url(#paint2_linear_473_2)'
                    />
                    <path
                      id='Vector_167'
                      d='M501.91 875.404C496.51 873.504 490.51 876.404 488.71 881.904L477.01 916.004C475.11 921.404 478.01 927.404 483.51 929.204L517.61 940.904C523.01 942.804 529.01 939.904 530.81 934.404L542.51 900.304C544.41 894.904 541.51 888.904 536.01 887.104L501.91 875.404Z'
                      fill={task[2].TasksAndStudents?.isCompleted ? '#1D87CE' : '#fff'}
                    />
                    <text
                      id='Task 3'
                      transform='translate(522.888 933.114) rotate(-161.029)'
                      fill={task[2].TasksAndStudents?.isCompleted ? '#fff' : '#000'}
                      xmlSpace='preserve'
                      style={{whiteSpace: 'pre'}}
                      font-family='Poppins'
                      font-size='13.3615'
                      font-weight='bold'
                      letter-spacing='0em'
                    >
                      <tspan x='0' y='14.6765'>
                        {task[2].name && task[2].name.length > 3
                          ? truncateText(task[2].name, 4)
                          : task[2].name}
                      </tspan>
                    </text>
                    <path
                      id='Vector_168'
                      d='M503.11 886.104L527.409 894.503C530.809 895.703 532.61 899.404 531.51 902.804L530.809 904.804C529.609 908.204 525.91 910.003 522.51 908.903L498.21 900.503C494.81 899.303 493.01 895.604 494.11 892.204L494.809 890.204C495.909 886.704 499.71 884.904 503.11 886.104Z'
                      fill={task[2].TasksAndStudents?.isCompleted ? '#fff' : '#000'}
                    />
                    <text
                      id='3/7_3'
                      transform='translate(519.37 907.195) rotate(-161.029)'
                      fill={task[2].TasksAndStudents?.isCompleted ? '#1D87CE' : '#fff'}
                      xmlSpace='preserve'
                      style={{whiteSpace: 'pre'}}
                      font-family='Poppins'
                      font-size='11.3288'
                      font-weight='bold'
                      letter-spacing='0em'
                    >
                      <tspan x='0' y='12.4651'>
                        {/* {task[2].counter + '/3'} */}
                        {(task[2]?.TasksAndStudents?.counter || 0) +
                          '/' +
                          (task[2]?.total_counter || 0)}
                      </tspan>
                    </text>
                  </g>
                </a>
              </g>
            )}
            {task[3] !== undefined && task[3] !== null && (
              <g id='task-4'>
                <g id='rod-4'>
                  <g id='Rectangle_6-5_00000111872503159269465410000004213774702854376099_'>
                    <g id='Group_61'>
                      <path
                        id='Vector_169'
                        d='M545.909 641.704C547.509 644.504 546.509 648.104 543.709 649.704L215.409 834.704C212.609 836.304 209.009 835.304 207.409 832.504C205.809 829.704 206.809 826.104 209.609 824.504L537.909 639.504C540.709 637.804 544.309 638.804 545.909 641.704Z'
                        fill='#F6F9FD'
                      />
                    </g>
                    <g id='Group_62'>
                      <path
                        id='Vector_170'
                        d='M545.909 641.704C547.509 644.504 546.509 648.104 543.709 649.704L215.409 834.704C212.609 836.304 209.009 835.304 207.409 832.504C205.809 829.704 206.809 826.104 209.609 824.504L537.909 639.504C540.709 637.804 544.309 638.804 545.909 641.704Z'
                        fill='#0686EF'
                      />
                    </g>
                  </g>
                  <g id='Group_63'>
                    <path
                      id='Rectangle_6-6_00000183943622137279818420000010960814529125555866_'
                      d='M545.899 641.665C547.499 644.465 546.5 648.065 543.7 649.665L215.399 834.665C212.599 836.265 208.999 835.265 207.399 832.465C205.799 829.665 206.799 826.065 209.599 824.465L537.899 639.465C540.699 637.865 544.299 638.865 545.899 641.665Z'
                      stroke='#00AAFF'
                      stroke-width='4'
                      stroke-miterlimit='10'
                    />
                  </g>
                </g>
                <g id='subtasks-4'>
                  <g id='rods_4'>
                    <g id='Group_64'>
                      <path
                        id='Rectangle_8-13_00000065779384914979308450000000892113514233907624_'
                        d='M194.81 852.105C197.91 853.005 199.81 856.205 198.91 859.405L180.81 924.105C179.91 927.205 176.71 929.105 173.51 928.205C170.41 927.305 168.51 924.105 169.41 920.905L187.51 856.205C188.41 853.105 191.71 851.205 194.81 852.105Z'
                        fill='#F6F9FD'
                      />
                      <g id='Group_65'>
                        <path
                          id='Rectangle_8-14_00000116943700668414753560000013487111306153505215_'
                          d='M194.8 852.065C197.9 852.965 199.8 856.165 198.9 859.365L180.8 924.065C179.9 927.165 176.7 929.065 173.5 928.165C170.4 927.265 168.5 924.065 169.4 920.865L187.5 856.165C188.4 853.065 191.7 851.265 194.8 852.065Z'
                          fill='white'
                        />
                      </g>
                    </g>
                    <g id='Group_66'>
                      <path
                        id='Rectangle_9-13_00000145036160657122044020000008893932666291719332_'
                        d='M185.209 836.005C184.409 839.205 181.209 841.005 178.009 840.205L113.009 823.305C109.809 822.505 108.009 819.305 108.809 816.105C109.609 812.905 112.809 811.105 116.009 811.905L181.009 828.805C184.109 829.605 186.009 832.805 185.209 836.005Z'
                        fill='#F6F9FD'
                      />
                      <g id='Group_67'>
                        <path
                          id='Rectangle_9-14_00000170257530208602398710000001897885547118422949_'
                          d='M185.2 835.965C184.4 839.165 181.199 840.965 177.999 840.165L112.999 823.265C109.799 822.465 108 819.265 108.8 816.065C109.6 812.865 112.799 811.065 115.999 811.865L180.999 828.765C184.099 829.565 186 832.765 185.2 835.965Z'
                          fill='white'
                        />
                      </g>
                    </g>
                  </g>
                  <g id='subtask-1_4'>
                    <g id='Group_68'>
                      <path
                        id='Ellipse_16-13_00000003817308433411772120000012175707320069010583_'
                        d='M197.509 857.605C207.671 857.605 215.909 849.367 215.909 839.205C215.909 829.043 207.671 820.805 197.509 820.805C187.347 820.805 179.109 829.043 179.109 839.205C179.109 849.367 187.347 857.605 197.509 857.605Z'
                        fill='#F6F9FD'
                      />
                      <g id='Group_69'>
                        <path
                          id='Ellipse_16-14_00000098907659818313710020000001099790668816056762_'
                          d='M197.5 857.565C207.662 857.565 215.899 849.327 215.899 839.165C215.899 829.003 207.662 820.765 197.5 820.765C187.337 820.765 179.1 829.003 179.1 839.165C179.1 849.327 187.337 857.565 197.5 857.565Z'
                          fill='white'
                        />
                      </g>
                    </g>
                    <path
                      id='Rectangle_15_00000143586537888931348530000002304321243361577093_'
                      d='M214.11 837.905L206.31 824.605C205.11 822.505 205.81 819.805 207.91 818.605C210.01 817.405 212.71 818.105 213.91 820.205L221.71 833.505C222.91 835.605 222.21 838.305 220.11 839.505C218.01 840.705 215.31 840.005 214.11 837.905Z'
                      fill='#E1E1E1'
                    />
                    <text
                      id='Task_10'
                      transform='translate(214.197 837.149) rotate(-120.399)'
                      fill='#272727'
                      xmlSpace='preserve'
                      style={{whiteSpace: 'pre'}}
                      font-family='Poppins'
                      font-size='6.2224'
                      letter-spacing='0em'
                    >
                      <tspan x='0' y='6.67784'>
                        Task
                      </tspan>
                    </text>
                    <g id='Group_70' opacity={task[3].TasksAndStudents?.isCompleted ? '1' : '0.4'}>
                      <path
                        id='Ellipse_17-13_00000097471304487177755210000005547975596093834174_'
                        d='M197.5 851.865C204.514 851.865 210.2 846.179 210.2 839.165C210.2 832.151 204.514 826.465 197.5 826.465C190.485 826.465 184.8 832.151 184.8 839.165C184.8 846.179 190.485 851.865 197.5 851.865Z'
                        fill='#00AAFF'
                      />
                    </g>
                  </g>
                  <g id='subtask-2_4'>
                    <g id='Group_71'>
                      <path
                        id='Ellipse_21-13_00000114052318525341534300000009669431933669582482_'
                        d='M171.509 957.105C181.671 957.105 189.909 948.867 189.909 938.705C189.909 928.543 181.671 920.305 171.509 920.305C161.347 920.305 153.109 928.543 153.109 938.705C153.109 948.867 161.347 957.105 171.509 957.105Z'
                        fill='#F6F9FD'
                      />
                      <g id='Group_72'>
                        <path
                          id='Ellipse_21-14_00000057147933110534345210000015946144003770604195_'
                          d='M171.5 957.165C181.662 957.165 189.899 948.927 189.899 938.765C189.899 928.603 181.662 920.365 171.5 920.365C161.337 920.365 153.1 928.603 153.1 938.765C153.1 948.927 161.337 957.165 171.5 957.165Z'
                          fill='white'
                        />
                      </g>
                    </g>
                    <path
                      id='Rectangle_13_00000063633214805135266740000016866338153648584858_'
                      d='M186.509 938.705L178.709 925.405C177.509 923.305 178.209 920.605 180.309 919.405C182.409 918.205 185.109 918.905 186.309 921.005L194.109 934.305C195.309 936.405 194.609 939.105 192.509 940.305C190.409 941.505 187.709 940.805 186.509 938.705Z'
                      fill='#E1E1E1'
                    />
                    <text
                      id='Task_11'
                      transform='translate(186.402 939.481) rotate(-120.399)'
                      fill='#272727'
                      xmlSpace='preserve'
                      style={{whiteSpace: 'pre'}}
                      font-family='Poppins'
                      font-size='7.3487'
                      letter-spacing='0em'
                    >
                      <tspan x='0' y='8.07205'>
                        Task
                      </tspan>
                    </text>
                    <g id='Group_73' opacity={task[3].TasksAndStudents?.isCompleted ? '1' : '0.4'}>
                      <path
                        id='Ellipse_20-13_00000012448688628355078030000003525131970364078489_'
                        d='M171.5 951.464C178.514 951.464 184.2 945.779 184.2 938.765C184.2 931.751 178.514 926.064 171.5 926.064C164.485 926.064 158.8 931.751 158.8 938.765C158.8 945.779 164.485 951.464 171.5 951.464Z'
                        fill='#00AAFF'
                      />
                    </g>
                  </g>
                  <g id='subtask-3_4'>
                    <g id='Group_74'>
                      <path
                        id='Ellipse_18-13_00000078763927814261477330000013440282806156338852_'
                        d='M97.0094 831.404C107.171 831.404 115.409 823.166 115.409 813.004C115.409 802.842 107.171 794.604 97.0094 794.604C86.8474 794.604 78.6094 802.842 78.6094 813.004C78.6094 823.166 86.8474 831.404 97.0094 831.404Z'
                        fill='#F6F9FD'
                      />
                      <g id='Group_75'>
                        <path
                          id='Ellipse_18-14_00000173841534477155416180000001530318774659971516_'
                          d='M96.9995 831.365C107.162 831.365 115.399 823.127 115.399 812.964C115.399 802.802 107.162 794.564 96.9995 794.564C86.8375 794.564 78.5996 802.802 78.5996 812.964C78.5996 823.127 86.8375 831.365 96.9995 831.365Z'
                          fill='white'
                        />
                      </g>
                    </g>
                    <path
                      id='Rectangle_14_00000133488461635084851010000016129165179244064951_'
                      d='M112.809 813.104L105.009 799.804C103.809 797.704 104.509 795.004 106.609 793.804C108.709 792.604 111.409 793.304 112.609 795.404L120.409 808.704C121.609 810.804 120.909 813.504 118.809 814.704C116.709 815.904 114.109 815.204 112.809 813.104Z'
                      fill='#E1E1E1'
                    />
                    <text
                      id='Task_12'
                      transform='translate(112.738 813.914) rotate(-120.399)'
                      fill='#272727'
                      xmlSpace='preserve'
                      style={{whiteSpace: 'pre'}}
                      font-family='Poppins'
                      font-size='7.3487'
                      letter-spacing='0em'
                    >
                      <tspan x='0' y='8.07205'>
                        Task
                      </tspan>
                    </text>
                    <g id='Group_76' opacity={task[3].TasksAndStudents?.isCompleted ? '1' : '0.4'}>
                      <path
                        id='Ellipse_19-13_00000103245885199795394720000004765619096702151577_'
                        d='M96.9995 825.665C104.014 825.665 109.7 819.979 109.7 812.965C109.7 805.951 104.014 800.265 96.9995 800.265C89.9855 800.265 84.2998 805.951 84.2998 812.965C84.2998 819.979 89.9855 825.665 96.9995 825.665Z'
                        fill='#00AAFF'
                      />
                    </g>
                  </g>
                </g>
                <a href='#' onClick={() => handleUserAction(task[3].TasksAndStudents?.id || 0)}>
                  <g id='box-4'>
                    <g id='Group_77'>
                      <path
                        id='Vector_171'
                        opacity='0.0196078'
                        d='M359.31 719.504C356.21 714.204 349.41 712.504 344.21 715.604L312.51 734.204C307.21 737.304 305.51 744.104 308.61 749.304L327.21 781.004C330.31 786.304 337.11 788.004 342.31 784.904L374.01 766.304C379.31 763.204 381.01 756.404 377.91 751.204L359.31 719.504Z'
                        fill='#FBFCFD'
                      />
                      <path
                        id='Vector_172'
                        opacity='0.0392157'
                        d='M359.209 719.704C356.109 714.404 349.309 712.704 344.109 715.804L312.409 734.404C307.109 737.504 305.41 744.304 308.51 749.504L327.109 781.204C330.209 786.504 337.009 788.204 342.209 785.104L373.909 766.504C379.209 763.404 380.909 756.604 377.809 751.404L359.209 719.704Z'
                        fill='#F8F9FB'
                      />
                      <path
                        id='Vector_173'
                        opacity='0.0588235'
                        d='M359.209 719.805C356.109 714.505 349.309 712.805 344.109 715.905L312.409 734.505C307.109 737.605 305.41 744.405 308.51 749.605L327.109 781.305C330.209 786.605 337.009 788.305 342.209 785.205L373.909 766.605C379.209 763.505 380.909 756.705 377.809 751.505L359.209 719.805Z'
                        fill='#F4F6F9'
                      />
                      <path
                        id='Vector_174'
                        opacity='0.0784314'
                        d='M359.209 719.905C356.109 714.605 349.309 712.905 344.109 716.005L312.409 734.605C307.109 737.705 305.41 744.505 308.51 749.705L327.109 781.405C330.209 786.705 337.009 788.405 342.209 785.305L373.909 766.705C379.209 763.605 380.909 756.805 377.809 751.605L359.209 719.905Z'
                        fill='#F1F3F7'
                      />
                      <path
                        id='Vector_175'
                        opacity='0.0980392'
                        d='M359.11 720.105C356.01 714.805 349.21 713.105 344.01 716.205L312.31 734.805C307.01 737.905 305.31 744.705 308.41 749.905L327.01 781.605C330.11 786.905 336.91 788.605 342.11 785.505L373.81 766.905C379.11 763.805 380.81 757.005 377.71 751.805L359.11 720.105Z'
                        fill='#EDF0F4'
                      />
                      <path
                        id='Vector_176'
                        opacity='0.1176'
                        d='M359.11 720.204C356.01 714.904 349.21 713.204 344.01 716.304L312.31 734.904C307.01 738.004 305.31 744.804 308.41 750.004L327.01 781.704C330.11 787.004 336.91 788.704 342.11 785.604L373.81 767.004C379.11 763.904 380.81 757.104 377.71 751.904L359.11 720.204Z'
                        fill='#EAEDF2'
                      />
                      <path
                        id='Vector_177'
                        opacity='0.1373'
                        d='M359.11 720.305C356.01 715.005 349.21 713.305 344.01 716.405L312.31 735.005C307.01 738.105 305.31 744.905 308.41 750.105L327.01 781.805C330.11 787.105 336.91 788.805 342.11 785.705L373.81 767.105C379.11 764.005 380.81 757.205 377.71 752.005L359.11 720.305Z'
                        fill='#E6EAF0'
                      />
                      <path
                        id='Vector_178'
                        opacity='0.1569'
                        d='M359.01 720.405C355.91 715.105 349.11 713.405 343.91 716.505L312.21 735.105C306.91 738.205 305.21 745.005 308.31 750.205L326.91 781.905C330.01 787.205 336.81 788.905 342.01 785.805L373.71 767.205C379.01 764.105 380.71 757.305 377.61 752.105L359.01 720.405Z'
                        fill='#E3E7EE'
                      />
                      <path
                        id='Vector_179'
                        opacity='0.1765'
                        d='M359.01 720.605C355.91 715.305 349.11 713.605 343.91 716.705L312.21 735.305C306.91 738.405 305.21 745.205 308.31 750.405L326.91 782.105C330.01 787.405 336.81 789.105 342.01 786.005L373.71 767.405C379.01 764.305 380.71 757.505 377.61 752.305L359.01 720.605Z'
                        fill='#DFE3EC'
                      />
                      <path
                        id='Vector_180'
                        opacity='0.1961'
                        d='M359.01 720.704C355.91 715.404 349.11 713.704 343.91 716.804L312.21 735.404C306.91 738.504 305.21 745.304 308.31 750.504L326.91 782.204C330.01 787.504 336.81 789.204 342.01 786.104L373.71 767.504C379.01 764.404 380.71 757.604 377.61 752.404L359.01 720.704Z'
                        fill='#DCE0EA'
                      />
                      <path
                        id='Vector_181'
                        opacity='0.2157'
                        d='M358.91 720.805C355.81 715.505 349.01 713.805 343.81 716.905L312.11 735.505C306.81 738.605 305.11 745.405 308.21 750.605L326.81 782.305C329.91 787.605 336.71 789.305 341.91 786.205L373.61 767.605C378.91 764.505 380.61 757.705 377.51 752.505L358.91 720.805Z'
                        fill='#D8DDE8'
                      />
                      <path
                        id='Vector_182'
                        opacity='0.2353'
                        d='M358.91 720.905C355.81 715.605 349.01 713.905 343.81 717.005L312.11 735.605C306.81 738.705 305.11 745.505 308.21 750.705L326.81 782.405C329.91 787.705 336.71 789.405 341.91 786.305L373.61 767.705C378.91 764.605 380.61 757.805 377.51 752.605L358.91 720.905Z'
                        fill='#D4DAE6'
                      />
                      <path
                        id='Vector_183'
                        opacity='0.2549'
                        d='M358.91 721.105C355.81 715.805 349.01 714.105 343.81 717.205L312.11 735.805C306.81 738.905 305.11 745.705 308.21 750.905L326.81 782.605C329.91 787.905 336.71 789.605 341.91 786.505L373.61 767.905C378.91 764.805 380.61 758.005 377.51 752.805L358.91 721.105Z'
                        fill='#D1D7E3'
                      />
                      <path
                        id='Vector_184'
                        opacity='0.2745'
                        d='M358.81 721.204C355.71 715.904 348.91 714.204 343.71 717.304L312.01 735.904C306.71 739.004 305.01 745.804 308.11 751.004L326.71 782.704C329.81 788.004 336.61 789.704 341.81 786.604L373.51 768.004C378.81 764.904 380.51 758.104 377.41 752.904L358.81 721.204Z'
                        fill='#CDD4E1'
                      />
                      <path
                        id='Vector_185'
                        opacity='0.2941'
                        d='M358.81 721.305C355.71 716.005 348.91 714.305 343.71 717.405L312.01 736.005C306.71 739.105 305.01 745.905 308.11 751.105L326.71 782.805C329.81 788.105 336.61 789.805 341.81 786.705L373.51 768.105C378.81 765.005 380.51 758.205 377.41 753.005L358.81 721.305Z'
                        fill='#CAD1DF'
                      />
                      <path
                        id='Vector_186'
                        opacity='0.3137'
                        d='M358.81 721.405C355.71 716.105 348.91 714.405 343.71 717.505L312.01 736.105C306.71 739.205 305.01 746.005 308.11 751.205L326.71 782.905C329.81 788.205 336.61 789.905 341.81 786.805L373.51 768.205C378.81 765.105 380.51 758.305 377.41 753.105L358.81 721.405Z'
                        fill='#C6CEDD'
                      />
                      <path
                        id='Vector_187'
                        opacity='0.3333'
                        d='M358.709 721.605C355.609 716.305 348.809 714.605 343.609 717.705L311.909 736.305C306.609 739.405 304.91 746.205 308.01 751.405L326.609 783.105C329.709 788.405 336.509 790.105 341.709 787.005L373.409 768.405C378.709 765.305 380.409 758.505 377.309 753.305L358.709 721.605Z'
                        fill='#C3CBDB'
                      />
                      <path
                        id='Vector_188'
                        opacity='0.3529'
                        d='M358.709 721.704C355.609 716.404 348.809 714.704 343.609 717.804L311.909 736.404C306.609 739.504 304.91 746.304 308.01 751.504L326.609 783.204C329.709 788.504 336.509 790.204 341.709 787.104L373.409 768.504C378.709 765.404 380.409 758.604 377.309 753.404L358.709 721.704Z'
                        fill='#BFC8D9'
                      />
                      <path
                        id='Vector_189'
                        opacity='0.3725'
                        d='M358.709 721.805C355.609 716.505 348.809 714.805 343.609 717.905L311.909 736.505C306.609 739.605 304.91 746.405 308.01 751.605L326.609 783.305C329.709 788.605 336.509 790.305 341.709 787.205L373.409 768.605C378.709 765.505 380.409 758.705 377.309 753.505L358.709 721.805Z'
                        fill='#BCC5D7'
                      />
                      <path
                        id='Vector_190'
                        opacity='0.3922'
                        d='M358.61 722.004C355.51 716.704 348.71 715.004 343.51 718.104L311.81 736.704C306.51 739.804 304.81 746.604 307.91 751.804L326.51 783.504C329.61 788.804 336.41 790.504 341.61 787.404L373.31 768.804C378.61 765.704 380.31 758.904 377.21 753.704L358.61 722.004Z'
                        fill='#B8C2D5'
                      />
                      <path
                        id='Vector_191'
                        opacity='0.4118'
                        d='M358.61 722.105C355.51 716.805 348.71 715.105 343.51 718.205L311.81 736.805C306.51 739.905 304.81 746.705 307.91 751.905L326.51 783.605C329.61 788.905 336.41 790.605 341.61 787.505L373.31 768.905C378.61 765.805 380.31 759.005 377.21 753.805L358.61 722.105Z'
                        fill='#B4BFD3'
                      />
                      <path
                        id='Vector_192'
                        opacity='0.4314'
                        d='M358.61 722.204C355.51 716.904 348.71 715.204 343.51 718.304L311.81 736.904C306.51 740.004 304.81 746.804 307.91 752.004L326.51 783.704C329.61 789.004 336.41 790.704 341.61 787.604L373.31 769.004C378.61 765.904 380.31 759.104 377.21 753.904L358.61 722.204Z'
                        fill='#B1BCD0'
                      />
                      <path
                        id='Vector_193'
                        opacity='0.451'
                        d='M358.51 722.305C355.41 717.005 348.61 715.305 343.41 718.405L311.71 737.005C306.41 740.105 304.71 746.905 307.81 752.105L326.41 783.805C329.51 789.105 336.31 790.805 341.51 787.705L373.21 769.105C378.51 766.005 380.21 759.205 377.11 754.005L358.51 722.305Z'
                        fill='#ADB9CE'
                      />
                      <path
                        id='Vector_194'
                        opacity='0.4706'
                        d='M358.51 722.504C355.41 717.204 348.61 715.504 343.41 718.604L311.71 737.204C306.41 740.304 304.71 747.104 307.81 752.304L326.41 784.004C329.51 789.304 336.31 791.004 341.51 787.904L373.21 769.304C378.51 766.204 380.21 759.404 377.11 754.204L358.51 722.504Z'
                        fill='#AAB6CC'
                      />
                      <path
                        id='Vector_195'
                        opacity='0.4902'
                        d='M358.51 722.605C355.41 717.305 348.61 715.605 343.41 718.705L311.71 737.305C306.41 740.405 304.71 747.205 307.81 752.405L326.41 784.105C329.51 789.405 336.31 791.105 341.51 788.005L373.21 769.405C378.51 766.305 380.21 759.505 377.11 754.305L358.51 722.605Z'
                        fill='#A6B3CA'
                      />
                      <path
                        id='Vector_196'
                        opacity='0.5098'
                        d='M358.41 722.704C355.31 717.404 348.51 715.704 343.31 718.804L311.61 737.404C306.31 740.504 304.61 747.304 307.71 752.504L326.31 784.204C329.41 789.504 336.21 791.204 341.41 788.104L373.11 769.504C378.41 766.404 380.11 759.604 377.01 754.404L358.41 722.704Z'
                        fill='#A3AFC8'
                      />
                      <path
                        id='Vector_197'
                        opacity='0.5294'
                        d='M358.41 722.805C355.31 717.505 348.51 715.805 343.31 718.905L311.61 737.505C306.31 740.605 304.61 747.405 307.71 752.605L326.31 784.305C329.41 789.605 336.21 791.305 341.41 788.205L373.11 769.605C378.41 766.505 380.11 759.705 377.01 754.505L358.41 722.805Z'
                        fill='#9FACC6'
                      />
                      <path
                        id='Vector_198'
                        opacity='0.549'
                        d='M358.41 723.004C355.31 717.704 348.51 716.004 343.31 719.104L311.61 737.704C306.31 740.804 304.61 747.604 307.71 752.804L326.31 784.504C329.41 789.804 336.21 791.504 341.41 788.404L373.11 769.804C378.41 766.704 380.11 759.904 377.01 754.704L358.41 723.004Z'
                        fill='#9CA9C4'
                      />
                      <path
                        id='Vector_199'
                        opacity='0.5686'
                        d='M358.31 723.105C355.21 717.805 348.41 716.105 343.21 719.205L311.51 737.805C306.21 740.905 304.51 747.705 307.61 752.905L326.21 784.605C329.31 789.905 336.11 791.605 341.31 788.505L373.01 769.905C378.31 766.805 380.01 760.005 376.91 754.805L358.31 723.105Z'
                        fill='#98A6C2'
                      />
                      <path
                        id='Vector_200'
                        opacity='0.5882'
                        d='M358.31 723.204C355.21 717.904 348.41 716.204 343.21 719.304L311.51 737.904C306.21 741.004 304.51 747.804 307.61 753.004L326.21 784.704C329.31 790.004 336.11 791.704 341.31 788.604L373.01 770.004C378.31 766.904 380.01 760.104 376.91 754.904L358.31 723.204Z'
                        fill='#95A3BF'
                      />
                      <path
                        id='Vector_201'
                        opacity='0.6078'
                        d='M358.31 723.305C355.21 718.005 348.41 716.305 343.21 719.405L311.51 738.005C306.21 741.105 304.51 747.905 307.61 753.105L326.21 784.805C329.31 790.105 336.11 791.805 341.31 788.705L373.01 770.105C378.31 767.005 380.01 760.205 376.91 755.005L358.31 723.305Z'
                        fill='#91A0BD'
                      />
                      <path
                        id='Vector_202'
                        opacity='0.6275'
                        d='M358.209 723.504C355.109 718.204 348.309 716.504 343.109 719.604L311.409 738.204C306.109 741.304 304.41 748.104 307.51 753.304L326.109 785.004C329.209 790.304 336.009 792.004 341.209 788.904L372.909 770.304C378.209 767.204 379.909 760.404 376.809 755.204L358.209 723.504Z'
                        fill='#8D9DBB'
                      />
                      <path
                        id='Vector_203'
                        opacity='0.6471'
                        d='M358.209 723.605C355.109 718.305 348.309 716.605 343.109 719.705L311.409 738.305C306.109 741.405 304.41 748.205 307.51 753.405L326.109 785.105C329.209 790.405 336.009 792.105 341.209 789.005L372.909 770.405C378.209 767.305 379.909 760.505 376.809 755.305L358.209 723.605Z'
                        fill='#8A9AB9'
                      />
                      <path
                        id='Vector_204'
                        opacity='0.6667'
                        d='M358.209 723.704C355.109 718.404 348.309 716.704 343.109 719.804L311.409 738.404C306.109 741.504 304.41 748.304 307.51 753.504L326.109 785.204C329.209 790.504 336.009 792.204 341.209 789.104L372.909 770.504C378.209 767.404 379.909 760.604 376.809 755.404L358.209 723.704Z'
                        fill='#8697B7'
                      />
                      <path
                        id='Vector_205'
                        opacity='0.6863'
                        d='M358.11 723.905C355.01 718.605 348.21 716.905 343.01 720.005L311.31 738.605C306.01 741.705 304.31 748.505 307.41 753.705L326.01 785.405C329.11 790.705 335.91 792.405 341.11 789.305L372.81 770.705C378.11 767.605 379.81 760.805 376.71 755.605L358.11 723.905Z'
                        fill='#8394B5'
                      />
                      <path
                        id='Vector_206'
                        opacity='0.7059'
                        d='M358.11 724.004C355.01 718.704 348.21 717.004 343.01 720.104L311.31 738.704C306.01 741.804 304.31 748.604 307.41 753.804L326.01 785.504C329.11 790.804 335.91 792.504 341.11 789.404L372.81 770.804C378.11 767.704 379.81 760.904 376.71 755.704L358.11 724.004Z'
                        fill='#7F91B3'
                      />
                      <path
                        id='Vector_207'
                        opacity='0.7255'
                        d='M358.11 724.105C355.01 718.805 348.21 717.105 343.01 720.205L311.31 738.805C306.01 741.905 304.31 748.705 307.41 753.905L326.01 785.605C329.11 790.905 335.91 792.605 341.11 789.505L372.81 770.905C378.11 767.805 379.81 761.005 376.71 755.805L358.11 724.105Z'
                        fill='#7C8EB1'
                      />
                      <path
                        id='Vector_208'
                        opacity='0.7451'
                        d='M358.01 724.204C354.91 718.904 348.11 717.204 342.91 720.304L311.21 738.904C305.91 742.004 304.21 748.804 307.31 754.004L325.91 785.704C329.01 791.004 335.81 792.704 341.01 789.604L372.71 771.004C378.01 767.904 379.71 761.104 376.61 755.904L358.01 724.204Z'
                        fill='#788BAF'
                      />
                      <path
                        id='Vector_209'
                        opacity='0.7647'
                        d='M358.01 724.405C354.91 719.105 348.11 717.405 342.91 720.505L311.21 739.105C305.91 742.205 304.21 749.005 307.31 754.205L325.91 785.905C329.01 791.205 335.81 792.905 341.01 789.805L372.71 771.205C378.01 768.105 379.71 761.305 376.61 756.105L358.01 724.405Z'
                        fill='#7588AC'
                      />
                      <path
                        id='Vector_210'
                        opacity='0.7843'
                        d='M358.01 724.504C354.91 719.204 348.11 717.504 342.91 720.604L311.21 739.204C305.91 742.304 304.21 749.104 307.31 754.304L325.91 786.004C329.01 791.304 335.81 793.004 341.01 789.904L372.71 771.304C378.01 768.204 379.71 761.404 376.61 756.204L358.01 724.504Z'
                        fill='#7185AA'
                      />
                      <path
                        id='Vector_211'
                        opacity='0.8039'
                        d='M357.91 724.605C354.81 719.305 348.01 717.605 342.81 720.705L311.11 739.305C305.81 742.405 304.11 749.205 307.21 754.405L325.81 786.105C328.91 791.405 335.71 793.105 340.91 790.005L372.61 771.405C377.91 768.305 379.61 761.505 376.51 756.305L357.91 724.605Z'
                        fill='#6D82A8'
                      />
                      <path
                        id='Vector_212'
                        opacity='0.8235'
                        d='M357.91 724.704C354.81 719.404 348.01 717.704 342.81 720.804L311.11 739.404C305.81 742.504 304.11 749.304 307.21 754.504L325.81 786.204C328.91 791.504 335.71 793.204 340.91 790.104L372.61 771.504C377.91 768.404 379.61 761.604 376.51 756.404L357.91 724.704Z'
                        fill='#6A7FA6'
                      />
                      <path
                        id='Vector_213'
                        opacity='0.8431'
                        d='M357.91 724.905C354.81 719.605 348.01 717.905 342.81 721.005L311.11 739.605C305.81 742.705 304.11 749.505 307.21 754.705L325.81 786.405C328.91 791.705 335.71 793.405 340.91 790.305L372.61 771.705C377.91 768.605 379.61 761.805 376.51 756.605L357.91 724.905Z'
                        fill='#667BA4'
                      />
                      <path
                        id='Vector_214'
                        opacity='0.8627'
                        d='M357.81 725.004C354.71 719.704 347.91 718.004 342.71 721.104L311.01 739.704C305.71 742.804 304.01 749.604 307.11 754.804L325.71 786.504C328.81 791.804 335.61 793.504 340.81 790.404L372.51 771.804C377.81 768.704 379.51 761.904 376.41 756.704L357.81 725.004Z'
                        fill='#6378A2'
                      />
                      <path
                        id='Vector_215'
                        opacity='0.8824'
                        d='M357.81 725.105C354.71 719.805 347.91 718.105 342.71 721.205L311.01 739.805C305.71 742.905 304.01 749.705 307.11 754.905L325.71 786.605C328.81 791.905 335.61 793.605 340.81 790.505L372.51 771.905C377.81 768.805 379.51 762.005 376.41 756.805L357.81 725.105Z'
                        fill='#5F75A0'
                      />
                      <path
                        id='Vector_216'
                        opacity='0.902'
                        d='M357.81 725.204C354.71 719.904 347.91 718.204 342.71 721.304L311.01 739.904C305.71 743.004 304.01 749.804 307.11 755.004L325.71 786.704C328.81 792.004 335.61 793.704 340.81 790.604L372.51 772.004C377.81 768.904 379.51 762.104 376.41 756.904L357.81 725.204Z'
                        fill='#5C729E'
                      />
                      <path
                        id='Vector_217'
                        opacity='0.9216'
                        d='M357.709 725.405C354.609 720.105 347.809 718.405 342.609 721.505L310.909 740.105C305.609 743.205 303.91 750.005 307.01 755.205L325.609 786.905C328.709 792.205 335.509 793.905 340.709 790.805L372.409 772.205C377.709 769.105 379.409 762.305 376.309 757.105L357.709 725.405Z'
                        fill='#586F9B'
                      />
                      <path
                        id='Vector_218'
                        opacity='0.9412'
                        d='M357.709 725.504C354.609 720.204 347.809 718.504 342.609 721.604L310.909 740.204C305.609 743.304 303.91 750.104 307.01 755.304L325.609 787.004C328.709 792.304 335.509 794.004 340.709 790.904L372.409 772.304C377.709 769.204 379.409 762.404 376.309 757.204L357.709 725.504Z'
                        fill='#556C99'
                      />
                      <path
                        id='Vector_219'
                        opacity='0.9608'
                        d='M357.709 725.605C354.609 720.305 347.809 718.605 342.609 721.705L310.909 740.305C305.609 743.405 303.91 750.205 307.01 755.405L325.609 787.105C328.709 792.405 335.509 794.105 340.709 791.005L372.409 772.405C377.709 769.305 379.409 762.505 376.309 757.305L357.709 725.605Z'
                        fill='#516997'
                      />
                      <path
                        id='Vector_220'
                        opacity='0.9804'
                        d='M357.61 725.704C354.51 720.404 347.71 718.704 342.51 721.804L310.81 740.404C305.51 743.504 303.81 750.304 306.91 755.504L325.51 787.204C328.61 792.504 335.41 794.204 340.61 791.104L372.31 772.504C377.61 769.404 379.31 762.604 376.21 757.404L357.61 725.704Z'
                        fill='#4E6695'
                      />
                      <path
                        id='Vector_221'
                        d='M357.61 725.905C354.51 720.605 347.71 718.905 342.51 722.005L310.81 740.605C305.51 743.705 303.81 750.505 306.91 755.705L325.51 787.405C328.61 792.705 335.41 794.405 340.61 791.305L372.31 772.705C377.61 769.605 379.31 762.805 376.21 757.605L357.61 725.905Z'
                        fill='#4A6393'
                      />
                    </g>
                    <path
                      id='Vector_222'
                      d='M357.11 727.205C354.11 722.005 347.41 720.305 342.21 723.305L311.11 741.605C305.91 744.605 304.21 751.305 307.21 756.505L325.51 787.605C328.51 792.805 335.21 794.505 340.41 791.505L371.51 773.205C376.71 770.205 378.41 763.505 375.41 758.305L357.11 727.205Z'
                      fill='url(#paint3_linear_473_2)'
                    />
                    <path
                      id='Vector_223'
                      d='M356.71 727.504C353.81 722.604 347.41 720.904 342.51 723.804L311.41 742.104C306.51 745.004 304.81 751.404 307.71 756.304L326.01 787.404C328.91 792.304 335.31 794.004 340.21 791.104L371.31 772.804C376.21 769.904 377.91 763.504 375.01 758.604L356.71 727.504Z'
                      fill={task[3].TasksAndStudents?.isCompleted ? '#1D87CE' : '#fff'}
                    />
                    <text
                      id='Task 4'
                      transform='translate(335.564 785.868) rotate(-120.399)'
                      fill={task[3].TasksAndStudents?.isCompleted ? '#fff' : '#000'}
                      xmlSpace='preserve'
                      style={{whiteSpace: 'pre'}}
                      font-family='Poppins'
                      font-size='13.3613'
                      font-weight='bold'
                      letter-spacing='0em'
                    >
                      <tspan x='0' y='14.6765'>
                        {task[3].name && task[3].name.length > 3
                          ? truncateText(task[3].name, 4)
                          : task[3].name}
                      </tspan>
                    </text>
                    <path
                      id='Vector_224'
                      d='M350.709 736.304L363.709 758.504C365.509 761.604 364.509 765.604 361.409 767.504L359.609 768.604C356.509 770.404 352.509 769.404 350.609 766.304L337.609 744.104C335.809 741.004 336.809 737.004 339.909 735.104L341.709 734.004C344.909 732.104 348.909 733.204 350.709 736.304Z'
                      fill={task[3].TasksAndStudents?.isCompleted ? '#fff' : '#000'}
                    />
                    <text
                      id='3/7_4'
                      transform='translate(349.306 762.948) rotate(-120.399)'
                      fill={task[3].TasksAndStudents?.isCompleted ? '#1D87CE' : '#fff'}
                      xmlSpace='preserve'
                      style={{whiteSpace: 'pre'}}
                      font-family='Poppins'
                      font-size='11.3286'
                      font-weight='bold'
                      letter-spacing='0em'
                    >
                      <tspan x='0' y='12.465'>
                        {/* {task[3].counter + '/3'} */}
                        {(task[3]?.TasksAndStudents?.counter || 0) +
                          '/' +
                          (task[3]?.total_counter || 0)}
                      </tspan>
                    </text>
                  </g>
                </a>
              </g>
            )}
            {task[4] !== undefined && task[4] !== null && (
              <g id='task-5'>
                <g id='rod-5'>
                  <g id='Rectangle_6-5_00000104673162640719437530000000922147912455835059_'>
                    <g id='Group_78'>
                      <path
                        id='Vector_225'
                        d='M538.61 583.705C539.41 580.505 537.51 577.305 534.31 576.505L169.11 484.005C165.91 483.205 162.71 485.105 161.91 488.305C161.11 491.505 163.01 494.705 166.21 495.505L531.41 588.005C534.51 588.705 537.81 586.805 538.61 583.705Z'
                        fill='#F6F9FD'
                      />
                    </g>
                    <g id='Group_79'>
                      <path
                        id='Vector_226'
                        d='M538.61 583.705C539.41 580.505 537.51 577.305 534.31 576.505L169.11 484.005C165.91 483.205 162.71 485.105 161.91 488.305C161.11 491.505 163.01 494.705 166.21 495.505L531.41 588.005C534.51 588.705 537.81 586.805 538.61 583.705Z'
                        fill='#0686EF'
                      />
                    </g>
                  </g>
                  <g id='Group_80'>
                    <path
                      id='Rectangle_6-6_00000041288692955013760420000000745827683174763691_'
                      d='M538.6 583.665C539.4 580.465 537.5 577.265 534.3 576.465L169.1 483.965C165.9 483.165 162.699 485.065 161.899 488.265C161.099 491.465 163 494.665 166.2 495.465L531.399 587.965C534.599 588.765 537.8 586.865 538.6 583.665Z'
                      stroke='#00AAFF'
                      stroke-width='4'
                      stroke-miterlimit='10'
                    />
                  </g>
                </g>
                <g id='subtasks-5'>
                  <g id='rods_5'>
                    <g id='Group_81'>
                      <path
                        id='Rectangle_8-13_00000078743458575341728630000010509868058475885724_'
                        d='M144.61 472.505C147.41 470.805 148.31 467.205 146.61 464.405L112.21 406.705C110.51 403.905 106.91 403.005 104.11 404.705C101.31 406.405 100.41 410.005 102.11 412.805L136.51 470.505C138.21 473.305 141.81 474.205 144.61 472.505Z'
                        fill='#F6F9FD'
                      />
                      <g id='Group_82'>
                        <path
                          id='Rectangle_8-14_00000074422980368211543350000018370660762112448905_'
                          d='M144.6 472.564C147.4 470.864 148.3 467.264 146.6 464.464L112.2 406.764C110.5 403.964 106.9 403.064 104.1 404.764C101.3 406.464 100.4 410.064 102.1 412.864L136.5 470.564C138.2 473.264 141.8 474.164 144.6 472.564Z'
                          fill='white'
                        />
                      </g>
                    </g>
                    <g id='Group_83'>
                      <path
                        id='Rectangle_9-13_00000064349136993962757480000015137341502654242194_'
                        d='M139.51 490.604C137.91 487.804 134.31 486.804 131.51 488.404L73.2097 521.704C70.4097 523.304 69.4097 526.904 71.0097 529.704C72.6097 532.504 76.2097 533.504 79.0097 531.904L137.31 498.604C140.21 497.104 141.21 493.504 139.51 490.604Z'
                        fill='#F6F9FD'
                      />
                      <g id='Group_84'>
                        <path
                          id='Rectangle_9-14_00000022551000111839333860000004227592869642480803_'
                          d='M139.5 490.664C137.9 487.864 134.3 486.864 131.5 488.464L73.2001 521.764C70.4001 523.364 69.3999 526.964 70.9999 529.764C72.5999 532.564 76.1999 533.564 78.9999 531.964L137.3 498.664C140.2 497.064 141.2 493.464 139.5 490.664Z'
                          fill='white'
                        />
                      </g>
                    </g>
                  </g>
                  <g id='subtask-1_5'>
                    <g id='Group_85'>
                      <path
                        id='Ellipse_16-13_00000129925447719154837310000013309399055344083371_'
                        d='M150.61 502.704C160.772 502.704 169.01 494.466 169.01 484.304C169.01 474.142 160.772 465.904 150.61 465.904C140.448 465.904 132.21 474.142 132.21 484.304C132.21 494.466 140.448 502.704 150.61 502.704Z'
                        fill='#F6F9FD'
                      />
                      <g id='Group_86'>
                        <path
                          id='Ellipse_16-14_00000183933519387589188520000017053833045262368942_'
                          d='M150.6 502.764C160.762 502.764 169 494.526 169 484.364C169 474.202 160.762 465.964 150.6 465.964C140.438 465.964 132.2 474.202 132.2 484.364C132.2 494.526 140.438 502.764 150.6 502.764Z'
                          fill='white'
                        />
                      </g>
                    </g>
                    <path
                      id='Rectangle_15_00000075139113975980958400000011049684511860420031_'
                      d='M167.01 481.204L162.91 496.104C162.31 498.404 163.61 500.804 166.01 501.504C168.31 502.104 170.71 500.804 171.41 498.404L175.51 483.504C176.11 481.204 174.81 478.804 172.41 478.104C170.01 477.404 167.61 478.804 167.01 481.204Z'
                      fill='#E1E1E1'
                    />
                    <text
                      id='Task_13'
                      transform='translate(163.232 496.068) rotate(-74.0137)'
                      fill='#272727'
                      xmlSpace='preserve'
                      style={{whiteSpace: 'pre'}}
                      font-family='Poppins'
                      font-size='6.2224'
                      letter-spacing='0em'
                    >
                      <tspan x='0' y='6.67784'>
                        Task
                      </tspan>
                    </text>
                    <g id='Group_87' opacity={task[4].TasksAndStudents?.isCompleted ? '1' : '0.4'}>
                      <path
                        id='Ellipse_17-13_00000064341833590427701540000007194664223276344495_'
                        d='M150.601 497.064C157.615 497.064 163.301 491.378 163.301 484.364C163.301 477.35 157.615 471.664 150.601 471.664C143.587 471.664 137.9 477.35 137.9 484.364C137.9 491.378 143.587 497.064 150.601 497.064Z'
                        fill='#00AAFF'
                      />
                    </g>
                  </g>
                  <g id='subtask-2_5'>
                    <g id='Group_88'>
                      <path
                        id='Ellipse_21-13_00000165192515463198174330000010200298031556882070_'
                        d='M99.5094 413.404C109.671 413.404 117.909 405.167 117.909 395.005C117.909 384.842 109.671 376.604 99.5094 376.604C89.3474 376.604 81.1094 384.842 81.1094 395.005C81.1094 405.167 89.3474 413.404 99.5094 413.404Z'
                        fill='#F6F9FD'
                      />
                      <g id='Group_89'>
                        <path
                          id='Ellipse_21-14_00000181800843709314768080000018402953470403753637_'
                          d='M99.4995 413.464C109.662 413.464 117.899 405.226 117.899 395.064C117.899 384.902 109.662 376.664 99.4995 376.664C89.3375 376.664 81.0996 384.902 81.0996 395.064C81.0996 405.226 89.3375 413.464 99.4995 413.464Z'
                          fill='white'
                        />
                      </g>
                    </g>
                    <path
                      id='Rectangle_13_00000145777055836949742440000009283179648710329477_'
                      d='M113.909 391.104L109.809 406.004C109.209 408.304 110.509 410.704 112.909 411.404C115.209 412.004 117.609 410.704 118.309 408.304L122.409 393.404C123.009 391.104 121.709 388.704 119.309 388.004C117.009 387.404 114.609 388.804 113.909 391.104Z'
                      fill='#E1E1E1'
                    />
                    <text
                      id='Task_14'
                      transform='translate(109.525 406.055) rotate(-74.0137)'
                      fill='#272727'
                      xmlSpace='preserve'
                      style={{whiteSpace: 'pre'}}
                      font-family='Poppins'
                      font-size='7.3488'
                      letter-spacing='0em'
                    >
                      <tspan x='0' y='8.07208'>
                        Task
                      </tspan>
                    </text>
                    <g id='Group_90' opacity={task[4].TasksAndStudents?.isCompleted ? '1' : '0.4'}>
                      <path
                        id='Ellipse_20-13_00000026882157164283560630000016336545899713119872_'
                        d='M99.5005 407.764C106.515 407.764 112.201 402.078 112.201 395.064C112.201 388.05 106.515 382.364 99.5005 382.364C92.4865 382.364 86.8008 388.05 86.8008 395.064C86.8008 402.078 92.4865 407.764 99.5005 407.764Z'
                        fill='#00AAFF'
                      />
                    </g>
                  </g>
                  <g id='subtask-3_5'>
                    <g id='Group_91'>
                      <path
                        id='Ellipse_18-13_00000007407447165769542190000010304133661799158186_'
                        d='M60.4097 554.305C70.5717 554.305 78.8097 546.067 78.8097 535.905C78.8097 525.743 70.5717 517.505 60.4097 517.505C50.2476 517.505 42.0098 525.743 42.0098 535.905C42.0098 546.067 50.2476 554.305 60.4097 554.305Z'
                        fill='#F6F9FD'
                      />
                      <g id='Group_92'>
                        <path
                          id='Ellipse_18-14_00000049940544075955501370000014476949485658652290_'
                          d='M60.3999 554.265C70.5619 554.265 78.8003 546.027 78.8003 535.865C78.8003 525.703 70.5619 517.465 60.3999 517.465C50.2379 517.465 42 525.703 42 535.865C42 546.027 50.2379 554.265 60.3999 554.265Z'
                          fill='white'
                        />
                      </g>
                    </g>
                    <path
                      id='Rectangle_14_00000132774674879864154530000008441907857131871394_'
                      d='M75.7102 531.604L71.6103 546.504C71.0103 548.804 72.3102 551.204 74.7102 551.904C77.0102 552.504 79.4103 551.204 80.1103 548.804L84.2102 533.904C84.8102 531.604 83.5103 529.204 81.1103 528.504C78.8103 527.904 76.4102 529.304 75.7102 531.604Z'
                      fill='#E1E1E1'
                    />
                    <text
                      id='Task_15'
                      transform='translate(71.1035 546.485) rotate(-74.0137)'
                      fill='#272727'
                      xmlSpace='preserve'
                      style={{whiteSpace: 'pre'}}
                      font-family='Poppins'
                      font-size='7.3488'
                      letter-spacing='0em'
                    >
                      <tspan x='0' y='8.07208'>
                        Task
                      </tspan>
                    </text>
                    <g id='Group_93' opacity={task[4].TasksAndStudents?.isCompleted ? '1' : '0.4'}>
                      <path
                        id='Ellipse_19-13_00000173155372364711026540000006383148204438564498_'
                        d='M60.3999 548.564C67.4139 548.564 73.1001 542.878 73.1001 535.864C73.1001 528.85 67.4139 523.164 60.3999 523.164C53.3859 523.164 47.7002 528.85 47.7002 535.864C47.7002 542.878 53.3859 548.564 60.3999 548.564Z'
                        fill='#00AAFF'
                      />
                    </g>
                  </g>
                </g>
                <a href='#' onClick={() => handleUserAction(task[4].TasksAndStudents?.id || 0)}>
                  <g id='box-5'>
                    <g id='Group_94'>
                      <path
                        id='Vector_227'
                        opacity='0.0196078'
                        d='M291.519 493.457C285.422 493.111 280.215 497.686 279.889 503.661L277.921 539.605C277.595 545.579 282.285 550.702 288.382 551.047L325.065 553.13C331.162 553.475 336.369 548.9 336.695 542.926L338.664 506.982C338.99 501.007 334.3 495.885 328.202 495.539L291.519 493.457Z'
                        fill='#FBFCFD'
                      />
                      <path
                        id='Vector_228'
                        opacity='0.0392157'
                        d='M291.618 493.467C285.521 493.122 280.314 497.697 279.988 503.671L278.02 539.616C277.694 545.59 282.384 550.713 288.481 551.058L325.164 553.14C331.262 553.486 336.468 548.911 336.795 542.936L338.763 506.992C339.089 501.018 334.399 495.895 328.301 495.55L291.618 493.467Z'
                        fill='#F8F9FB'
                      />
                      <path
                        id='Vector_229'
                        opacity='0.0588235'
                        d='M291.708 493.576C285.61 493.23 280.404 497.806 280.077 503.78L278.11 539.724C277.784 545.698 282.473 550.821 288.571 551.166L325.254 553.249C331.351 553.594 336.558 549.019 336.884 543.045L338.853 507.101C339.179 501.126 334.489 496.004 328.391 495.658L291.708 493.576Z'
                        fill='#F4F6F9'
                      />
                      <path
                        id='Vector_230'
                        opacity='0.0784314'
                        d='M291.798 493.683C285.7 493.338 280.494 497.913 280.167 503.887L278.199 539.831C277.873 545.806 282.563 550.929 288.661 551.274L325.344 553.356C331.441 553.702 336.648 549.127 336.974 543.152L338.942 507.208C339.268 501.234 334.578 496.111 328.481 495.766L291.798 493.683Z'
                        fill='#F1F3F7'
                      />
                      <path
                        id='Vector_231'
                        opacity='0.0980392'
                        d='M291.886 493.792C285.789 493.446 280.582 498.021 280.256 503.996L278.288 539.94C277.962 545.914 282.652 551.037 288.749 551.382L325.433 553.465C331.53 553.81 336.737 549.235 337.063 543.261L339.031 507.317C339.357 501.342 334.667 496.22 328.569 495.874L291.886 493.792Z'
                        fill='#EDF0F4'
                      />
                      <path
                        id='Vector_232'
                        opacity='0.1176'
                        d='M291.975 493.899C285.878 493.554 280.671 498.129 280.345 504.103L278.377 540.047C278.051 546.022 282.741 551.144 288.838 551.49L325.521 553.572C331.619 553.918 336.825 549.343 337.152 543.368L339.12 507.424C339.446 501.45 334.756 496.327 328.658 495.982L291.975 493.899Z'
                        fill='#EAEDF2'
                      />
                      <path
                        id='Vector_233'
                        opacity='0.1373'
                        d='M292.064 494.006C285.967 493.661 280.76 498.236 280.434 504.211L278.466 540.155C278.14 546.129 282.83 551.252 288.928 551.597L325.611 553.68C331.708 554.025 336.915 549.45 337.241 543.476L339.209 507.531C339.535 501.557 334.845 496.434 328.748 496.089L292.064 494.006Z'
                        fill='#E6EAF0'
                      />
                      <path
                        id='Vector_234'
                        opacity='0.1569'
                        d='M292.154 494.115C286.057 493.77 280.85 498.345 280.524 504.319L278.556 540.263C278.23 546.238 282.92 551.36 289.017 551.705L325.7 553.788C331.798 554.133 337.004 549.558 337.331 543.584L339.299 507.64C339.625 501.665 334.935 496.543 328.838 496.197L292.154 494.115Z'
                        fill='#E3E7EE'
                      />
                      <path
                        id='Vector_235'
                        opacity='0.1765'
                        d='M292.244 494.222C286.147 493.877 280.94 498.452 280.614 504.426L278.645 540.37C278.319 546.345 283.009 551.468 289.107 551.813L325.79 553.895C331.888 554.241 337.094 549.666 337.421 543.691L339.388 507.747C339.715 501.773 335.025 496.65 328.927 496.305L292.244 494.222Z'
                        fill='#DFE3EC'
                      />
                      <path
                        id='Vector_236'
                        opacity='0.1961'
                        d='M292.343 494.233C286.245 493.888 281.039 498.463 280.712 504.437L278.744 540.381C278.418 546.356 283.108 551.478 289.205 551.824L325.889 553.906C331.986 554.252 337.193 549.677 337.519 543.702L339.487 507.758C339.813 501.784 335.123 496.661 329.026 496.316L292.343 494.233Z'
                        fill='#DCE0EA'
                      />
                      <path
                        id='Vector_237'
                        opacity='0.2157'
                        d='M292.431 494.342C286.334 493.996 281.127 498.571 280.801 504.546L278.833 540.49C278.507 546.464 283.197 551.587 289.294 551.932L325.977 554.015C332.075 554.36 337.281 549.785 337.608 543.811L339.576 507.866C339.902 501.892 335.212 496.769 329.114 496.424L292.431 494.342Z'
                        fill='#D8DDE8'
                      />
                      <path
                        id='Vector_238'
                        opacity='0.2353'
                        d='M292.52 494.449C286.423 494.104 281.216 498.679 280.89 504.653L278.922 540.597C278.596 546.572 283.286 551.694 289.384 552.04L326.067 554.122C332.164 554.467 337.371 549.892 337.697 543.918L339.665 507.974C339.991 501.999 335.301 496.877 329.204 496.531L292.52 494.449Z'
                        fill='#D4DAE6'
                      />
                      <path
                        id='Vector_239'
                        opacity='0.2549'
                        d='M292.61 494.557C286.513 494.212 281.306 498.787 280.98 504.761L279.012 540.705C278.686 546.68 283.376 551.803 289.473 552.148L326.156 554.23C332.254 554.576 337.461 550.001 337.787 544.026L339.755 508.082C340.081 502.108 335.391 496.985 329.294 496.64L292.61 494.557Z'
                        fill='#D1D7E3'
                      />
                      <path
                        id='Vector_240'
                        opacity='0.2745'
                        d='M292.7 494.665C286.603 494.319 281.396 498.894 281.07 504.869L279.101 540.813C278.775 546.787 283.466 551.91 289.563 552.255L326.246 554.338C332.344 554.683 337.55 550.108 337.877 544.134L339.844 508.19C340.171 502.215 335.481 497.093 329.383 496.747L292.7 494.665Z'
                        fill='#CDD4E1'
                      />
                      <path
                        id='Vector_241'
                        opacity='0.2941'
                        d='M292.789 494.773C286.692 494.428 281.485 499.003 281.159 504.977L279.19 540.921C278.864 546.896 283.554 552.018 289.652 552.364L326.335 554.446C332.432 554.792 337.639 550.217 337.966 544.242L339.933 508.298C340.259 502.324 335.569 497.201 329.472 496.856L292.789 494.773Z'
                        fill='#CAD1DF'
                      />
                      <path
                        id='Vector_242'
                        opacity='0.3137'
                        d='M292.878 494.881C286.78 494.535 281.574 499.11 281.247 505.085L279.279 541.029C278.953 547.003 283.643 552.126 289.741 552.471L326.424 554.554C332.521 554.899 337.728 550.324 338.054 544.35L340.022 508.405C340.348 502.431 335.658 497.308 329.561 496.963L292.878 494.881Z'
                        fill='#C6CEDD'
                      />
                      <path
                        id='Vector_243'
                        opacity='0.3333'
                        d='M292.967 494.988C286.869 494.643 281.663 499.218 281.336 505.192L279.369 541.136C279.042 547.111 283.733 552.233 289.83 552.579L326.513 554.661C332.61 555.006 337.817 550.432 338.143 544.457L340.111 508.513C340.438 502.539 335.747 497.416 329.65 497.071L292.967 494.988Z'
                        fill='#C3CBDB'
                      />
                      <path
                        id='Vector_244'
                        opacity='0.3529'
                        d='M293.067 494.999C286.97 494.653 281.763 499.228 281.437 505.203L279.469 541.147C279.143 547.121 283.833 552.244 289.93 552.589L326.613 554.672C332.711 555.017 337.918 550.442 338.244 544.468L340.212 508.524C340.538 502.549 335.848 497.427 329.751 497.081L293.067 494.999Z'
                        fill='#BFC8D9'
                      />
                      <path
                        id='Vector_245'
                        opacity='0.3725'
                        d='M293.157 495.107C287.06 494.762 281.853 499.337 281.527 505.311L279.559 541.255C279.232 547.23 283.923 552.352 290.02 552.698L326.703 554.78C332.801 555.125 338.007 550.551 338.334 544.576L340.301 508.632C340.628 502.658 335.938 497.535 329.84 497.19L293.157 495.107Z'
                        fill='#BCC5D7'
                      />
                      <path
                        id='Vector_246'
                        opacity='0.3922'
                        d='M293.246 495.214C287.149 494.869 281.942 499.444 281.616 505.419L279.647 541.363C279.321 547.337 284.011 552.46 290.109 552.805L326.792 554.888C332.89 555.233 338.096 550.658 338.423 544.684L340.39 508.739C340.716 502.765 336.026 497.642 329.929 497.297L293.246 495.214Z'
                        fill='#B8C2D5'
                      />
                      <path
                        id='Vector_247'
                        opacity='0.4118'
                        d='M293.334 495.322C287.236 494.977 282.03 499.552 281.703 505.526L279.735 541.47C279.409 547.445 284.099 552.567 290.197 552.913L326.88 554.995C332.977 555.34 338.184 550.765 338.51 544.791L340.478 508.847C340.804 502.872 336.114 497.75 330.017 497.404L293.334 495.322Z'
                        fill='#B4BFD3'
                      />
                      <path
                        id='Vector_248'
                        opacity='0.4314'
                        d='M293.324 495.42C287.227 495.074 282.02 499.649 281.694 505.624L279.726 541.568C279.399 547.542 284.09 552.665 290.187 553.01L326.87 555.093C332.967 555.438 338.174 550.863 338.5 544.889L340.468 508.945C340.795 502.97 336.104 497.847 330.007 497.502L293.324 495.42Z'
                        fill='#B1BCD0'
                      />
                      <path
                        id='Vector_249'
                        opacity='0.451'
                        d='M293.413 495.528C287.316 495.183 282.109 499.758 281.783 505.732L279.815 541.676C279.489 547.651 284.179 552.773 290.276 553.119L326.959 555.201C333.056 555.547 338.263 550.972 338.59 544.997L340.558 509.053C340.884 503.079 336.194 497.956 330.096 497.611L293.413 495.528Z'
                        fill='#ADB9CE'
                      />
                      <path
                        id='Vector_250'
                        opacity='0.4706'
                        d='M293.503 495.635C287.405 495.29 282.199 499.865 281.872 505.839L279.905 541.784C279.578 547.758 284.268 552.881 290.366 553.226L327.049 555.308C333.146 555.654 338.353 551.079 338.679 545.104L340.648 509.16C340.974 503.186 336.283 498.063 330.186 497.718L293.503 495.635Z'
                        fill='#AAB6CC'
                      />
                      <path
                        id='Vector_251'
                        opacity='0.4902'
                        d='M293.593 495.744C287.495 495.398 282.289 499.973 281.962 505.948L279.994 541.892C279.668 547.866 284.358 552.989 290.455 553.334L327.139 555.417C333.236 555.762 338.443 551.187 338.769 545.213L340.737 509.269C341.063 503.294 336.373 498.172 330.276 497.826L293.593 495.744Z'
                        fill='#A6B3CA'
                      />
                      <path
                        id='Vector_252'
                        opacity='0.5098'
                        d='M293.691 495.754C287.594 495.408 282.387 499.983 282.061 505.958L280.093 541.902C279.766 547.876 284.457 552.999 290.554 553.344L327.238 555.427C333.335 555.772 338.542 551.197 338.868 545.223L340.836 509.279C341.162 503.304 336.471 498.181 330.374 497.836L293.691 495.754Z'
                        fill='#A3AFC8'
                      />
                      <path
                        id='Vector_253'
                        opacity='0.5294'
                        d='M293.78 495.862C287.683 495.517 282.476 500.092 282.15 506.066L280.182 542.01C279.855 547.985 284.546 553.107 290.643 553.453L327.326 555.535C333.423 555.88 338.63 551.305 338.956 545.331L340.924 509.387C341.251 503.412 336.56 498.29 330.463 497.944L293.78 495.862Z'
                        fill='#9FACC6'
                      />
                      <path
                        id='Vector_254'
                        opacity='0.549'
                        d='M293.869 495.969C287.772 495.624 282.565 500.199 282.239 506.174L280.271 542.118C279.945 548.092 284.635 553.215 290.732 553.56L327.415 555.643C333.513 555.988 338.719 551.413 339.046 545.439L341.014 509.494C341.34 503.52 336.65 498.397 330.552 498.052L293.869 495.969Z'
                        fill='#9CA9C4'
                      />
                      <path
                        id='Vector_255'
                        opacity='0.5686'
                        d='M293.959 496.078C287.861 495.733 282.655 500.307 282.328 506.282L280.361 542.226C280.034 548.201 284.724 553.323 290.822 553.668L327.505 555.751C333.602 556.096 338.809 551.521 339.135 545.547L341.104 509.603C341.43 503.628 336.74 498.506 330.642 498.16L293.959 496.078Z'
                        fill='#98A6C2'
                      />
                      <path
                        id='Vector_256'
                        opacity='0.5882'
                        d='M294.049 496.185C287.951 495.84 282.745 500.415 282.418 506.389L280.45 542.333C280.124 548.308 284.814 553.43 290.911 553.776L327.595 555.858C333.692 556.204 338.899 551.629 339.225 545.654L341.193 509.71C341.519 503.736 336.829 498.613 330.732 498.268L294.049 496.185Z'
                        fill='#95A3BF'
                      />
                      <path
                        id='Vector_257'
                        opacity='0.6078'
                        d='M294.137 496.294C288.04 495.948 282.833 500.523 282.507 506.498L280.539 542.442C280.213 548.416 284.903 553.539 291 553.884L327.684 555.967C333.781 556.312 338.988 551.737 339.314 545.763L341.282 509.819C341.608 503.844 336.918 498.721 330.82 498.376L294.137 496.294Z'
                        fill='#91A0BD'
                      />
                      <path
                        id='Vector_258'
                        opacity='0.6275'
                        d='M294.226 496.401C288.129 496.056 282.922 500.631 282.596 506.605L280.628 542.549C280.302 548.524 284.992 553.646 291.089 553.992L327.772 556.074C333.869 556.419 339.076 551.844 339.403 545.87L341.371 509.926C341.697 503.952 337.007 498.829 330.909 498.484L294.226 496.401Z'
                        fill='#8D9DBB'
                      />
                      <path
                        id='Vector_259'
                        opacity='0.6471'
                        d='M294.315 496.509C288.218 496.163 283.011 500.738 282.685 506.713L280.717 542.657C280.391 548.631 285.081 553.754 291.179 554.099L327.862 556.182C333.959 556.527 339.166 551.952 339.492 545.978L341.46 510.033C341.786 504.059 337.096 498.936 330.999 498.591L294.315 496.509Z'
                        fill='#8A9AB9'
                      />
                      <path
                        id='Vector_260'
                        opacity='0.6667'
                        d='M294.405 496.617C288.308 496.272 283.101 500.847 282.775 506.821L280.807 542.765C280.481 548.74 285.171 553.862 291.268 554.208L327.951 556.29C334.049 556.635 339.255 552.06 339.582 546.086L341.55 510.142C341.876 504.167 337.186 499.045 331.089 498.699L294.405 496.617Z'
                        fill='#8697B7'
                      />
                      <path
                        id='Vector_261'
                        opacity='0.6863'
                        d='M294.505 496.628C288.407 496.282 283.201 500.857 282.874 506.832L280.906 542.776C280.58 548.75 285.27 553.873 291.368 554.218L328.051 556.301C334.148 556.646 339.355 552.071 339.681 546.097L341.649 510.153C341.975 504.178 337.285 499.055 331.188 498.71L294.505 496.628Z'
                        fill='#8394B5'
                      />
                      <path
                        id='Vector_262'
                        opacity='0.7059'
                        d='M294.594 496.735C288.496 496.39 283.29 500.965 282.963 506.939L280.995 542.883C280.669 548.858 285.359 553.98 291.456 554.326L328.14 556.408C334.237 556.753 339.444 552.178 339.77 546.204L341.738 510.26C342.064 504.286 337.374 499.163 331.277 498.818L294.594 496.735Z'
                        fill='#7F91B3'
                      />
                      <path
                        id='Vector_263'
                        opacity='0.7255'
                        d='M294.682 496.842C288.585 496.497 283.378 501.072 283.052 507.047L281.084 542.991C280.758 548.965 285.448 554.088 291.545 554.433L328.228 556.515C334.326 556.861 339.532 552.286 339.859 546.311L341.827 510.367C342.153 504.393 337.463 499.27 331.365 498.925L294.682 496.842Z'
                        fill='#7C8EB1'
                      />
                      <path
                        id='Vector_264'
                        opacity='0.7451'
                        d='M294.771 496.951C288.674 496.606 283.467 501.181 283.141 507.155L281.173 543.099C280.847 549.073 285.537 554.196 291.635 554.541L328.318 556.624C334.415 556.969 339.622 552.394 339.948 546.42L341.916 510.476C342.242 504.501 337.552 499.379 331.455 499.033L294.771 496.951Z'
                        fill='#788BAF'
                      />
                      <path
                        id='Vector_265'
                        opacity='0.7647'
                        d='M294.861 497.058C288.764 496.713 283.557 501.288 283.231 507.262L281.263 543.206C280.937 549.181 285.627 554.304 291.724 554.649L328.407 556.731C334.505 557.077 339.711 552.502 340.038 546.527L342.006 510.583C342.332 504.609 337.642 499.486 331.545 499.141L294.861 497.058Z'
                        fill='#7588AC'
                      />
                      <path
                        id='Vector_266'
                        opacity='0.7843'
                        d='M294.951 497.167C288.854 496.821 283.647 501.396 283.321 507.371L281.352 543.315C281.026 549.289 285.717 554.412 291.814 554.757L328.497 556.84C334.595 557.185 339.801 552.61 340.128 546.636L342.095 510.692C342.422 504.717 337.732 499.595 331.634 499.249L294.951 497.167Z'
                        fill='#7185AA'
                      />
                      <path
                        id='Vector_267'
                        opacity='0.8039'
                        d='M295.04 497.274C288.943 496.929 283.736 501.504 283.41 507.478L281.441 543.422C281.115 549.397 285.805 554.519 291.903 554.865L328.586 556.947C334.683 557.292 339.89 552.718 340.216 546.743L342.184 510.799C342.51 504.825 337.82 499.702 331.723 499.357L295.04 497.274Z'
                        fill='#6D82A8'
                      />
                      <path
                        id='Vector_268'
                        opacity='0.8235'
                        d='M295.129 497.382C289.031 497.037 283.825 501.612 283.498 507.587L281.53 543.531C281.204 549.505 285.894 554.628 291.992 554.973L328.675 557.055C334.772 557.401 339.979 552.826 340.305 546.851L342.273 510.907C342.599 504.933 337.909 499.81 331.812 499.465L295.129 497.382Z'
                        fill='#6A7FA6'
                      />
                      <path
                        id='Vector_269'
                        opacity='0.8431'
                        d='M295.228 497.392C289.131 497.047 283.924 501.622 283.598 507.596L281.63 543.54C281.304 549.515 285.994 554.638 292.092 554.983L328.775 557.065C334.872 557.411 340.079 552.836 340.405 546.861L342.373 510.917C342.699 504.943 338.009 499.82 331.912 499.475L295.228 497.392Z'
                        fill='#667BA4'
                      />
                      <path
                        id='Vector_270'
                        opacity='0.8627'
                        d='M295.317 497.501C289.22 497.155 284.013 501.73 283.687 507.705L281.719 543.649C281.393 549.623 286.083 554.746 292.18 555.091L328.863 557.174C334.961 557.519 340.168 552.944 340.494 546.97L342.462 511.026C342.788 505.051 338.098 499.929 332.001 499.583L295.317 497.501Z'
                        fill='#6378A2'
                      />
                      <path
                        id='Vector_271'
                        opacity='0.8824'
                        d='M295.407 497.608C289.31 497.263 284.103 501.838 283.777 507.812L281.809 543.756C281.482 549.731 286.173 554.853 292.27 555.199L328.953 557.281C335.051 557.627 340.257 553.052 340.584 547.077L342.551 511.133C342.878 505.159 338.188 500.036 332.09 499.691L295.407 497.608Z'
                        fill='#5F75A0'
                      />
                      <path
                        id='Vector_272'
                        opacity='0.902'
                        d='M295.496 497.717C289.399 497.371 284.192 501.946 283.866 507.921L281.897 543.865C281.571 549.839 286.261 554.962 292.359 555.307L329.042 557.39C335.14 557.735 340.346 553.16 340.673 547.186L342.64 511.241C342.966 505.267 338.276 500.144 332.179 499.799L295.496 497.717Z'
                        fill='#5C729E'
                      />
                      <path
                        id='Vector_273'
                        opacity='0.9216'
                        d='M295.585 497.825C289.487 497.479 284.281 502.054 283.954 508.029L281.986 543.973C281.66 549.947 286.35 555.07 292.448 555.415L329.131 557.498C335.228 557.843 340.435 553.268 340.761 547.294L342.729 511.35C343.055 505.375 338.365 500.253 332.268 499.907L295.585 497.825Z'
                        fill='#586F9B'
                      />
                      <path
                        id='Vector_274'
                        opacity='0.9412'
                        d='M295.674 497.933C289.576 497.588 284.37 502.163 284.043 508.137L282.076 544.081C281.749 550.056 286.44 555.179 292.537 555.524L329.22 557.606C335.317 557.952 340.524 553.377 340.85 547.402L342.818 511.458C343.145 505.484 338.454 500.361 332.357 500.016L295.674 497.933Z'
                        fill='#556C99'
                      />
                      <path
                        id='Vector_275'
                        opacity='0.9608'
                        d='M295.763 498.04C289.666 497.694 284.459 502.269 284.133 508.244L282.165 544.188C281.839 550.162 286.529 555.285 292.626 555.63L329.31 557.713C335.407 558.058 340.614 553.483 340.94 547.509L342.908 511.565C343.235 505.59 338.544 500.468 332.447 500.122L295.763 498.04Z'
                        fill='#516997'
                      />
                      <path
                        id='Vector_276'
                        opacity='0.9804'
                        d='M295.853 498.148C289.756 497.803 284.549 502.378 284.223 508.352L282.255 544.296C281.929 550.271 286.619 555.393 292.716 555.739L329.4 557.821C335.497 558.167 340.704 553.592 341.03 547.617L342.998 511.673C343.324 505.699 338.634 500.576 332.537 500.231L295.853 498.148Z'
                        fill='#4E6695'
                      />
                      <path
                        id='Vector_277'
                        d='M295.953 498.158C289.856 497.813 284.649 502.388 284.323 508.362L282.354 544.306C282.028 550.28 286.718 555.403 292.816 555.748L329.499 557.831C335.597 558.176 340.803 553.601 341.13 547.627L343.097 511.683C343.424 505.708 338.733 500.586 332.636 500.24L295.953 498.158Z'
                        fill='#4A6393'
                      />
                    </g>
                    <path
                      id='Vector_278'
                      d='M296.735 499.325C290.737 498.99 285.64 503.478 285.324 509.355L283.427 544.617C283.111 550.494 287.712 555.509 293.71 555.844L329.697 557.853C335.695 558.188 340.792 553.7 341.108 547.823L343.004 512.561C343.32 506.684 338.719 501.669 332.722 501.334L296.735 499.325Z'
                      fill='url(#paint4_linear_473_2)'
                    />
                    <path
                      id='Vector_279'
                      d='M296.684 499.812C290.984 499.508 286.007 503.812 285.722 509.396L283.825 544.659C283.54 550.243 287.952 555.14 293.652 555.443L329.639 557.453C335.338 557.756 340.316 553.453 340.601 547.868L342.497 512.606C342.783 507.021 338.37 502.124 332.671 501.821L296.684 499.812Z'
                      fill={task[4].TasksAndStudents?.isCompleted ? '#1D87CE' : '#fff'}
                    />
                    <text
                      id='Task 5'
                      transform='matrix(0.997711 0.0668203 -0.0652362 0.997816 294.01 506.052)'
                      fill={task[4].TasksAndStudents?.isCompleted ? '#fff' : '#000'}
                      xmlSpace='preserve'
                      style={{whiteSpace: 'pre'}}
                      font-family='Poppins'
                      font-size='13.3613'
                      font-weight='bold'
                      letter-spacing='0em'
                    >
                      <tspan x='0' y='14.6765'>
                        {task[4].name && task[4].name.length > 3
                          ? truncateText(task[4].name, 4)
                          : task[4].name}
                      </tspan>
                    </text>
                    <path
                      id='Vector_280'
                      d='M300.066 542.116L325.728 543.828C329.318 544.107 332.421 541.379 332.69 537.861L332.805 535.805C333.074 532.287 330.278 529.235 326.687 528.956L301.026 527.244C297.436 526.965 294.333 529.693 294.064 533.211L293.948 535.267C293.779 538.795 296.476 541.837 300.066 542.116Z'
                      fill={task[4].TasksAndStudents?.isCompleted ? '#fff' : '#000'}
                    />
                    <text
                      id='3/7_5'
                      transform='matrix(0.998957 0.0458091 -0.0433445 0.999067 304.931 528.159)'
                      fill={task[4].TasksAndStudents?.isCompleted ? '#1D87CE' : '#fff'}
                      xmlSpace='preserve'
                      style={{whiteSpace: 'pre'}}
                      font-family='Poppins'
                      font-size='11.3286'
                      font-weight='bold'
                      letter-spacing='0em'
                    >
                      <tspan x='0' y='12.465'>
                        {/* {task[4].counter + '/3'} */}
                        {(task[4]?.TasksAndStudents?.counter || 0) +
                          '/' +
                          (task[4]?.total_counter || 0)}
                      </tspan>
                    </text>
                  </g>
                </a>
              </g>
            )}
            {task[5] !== undefined && task[5] !== null && (
              <g id='task-6'>
                <g id='rod-6'>
                  <g id='Rectangle_6-5_00000052084746898722430240000005825000595250120081_'>
                    <g id='Group_95'>
                      <path
                        id='Vector_281'
                        d='M574.11 531.704C576.81 529.804 577.41 526.204 575.61 523.504L360.91 213.804C359.01 211.104 355.41 210.504 352.71 212.304C350.01 214.204 349.41 217.804 351.21 220.504L565.91 530.204C567.81 532.904 571.51 533.604 574.11 531.704Z'
                        fill='#F6F9FD'
                      />
                    </g>
                    <g id='Group_96'>
                      <path
                        id='Vector_282'
                        d='M574.11 531.704C576.81 529.804 577.41 526.204 575.61 523.504L360.91 213.804C359.01 211.104 355.41 210.504 352.71 212.304C350.01 214.204 349.41 217.804 351.21 220.504L565.91 530.204C567.81 532.904 571.51 533.604 574.11 531.704Z'
                        fill='#0686EF'
                      />
                    </g>
                  </g>
                  <g id='Group_97'>
                    <path
                      id='Rectangle_6-6_00000101065872585464538820000015061001974467885957_'
                      d='M574.2 531.664C576.9 529.764 577.5 526.164 575.7 523.464L361 213.864C359.1 211.164 355.5 210.564 352.8 212.364C350.1 214.264 349.5 217.864 351.3 220.564L566 530.264C567.8 532.864 571.5 533.564 574.2 531.664Z'
                      stroke='#00AAFF'
                      stroke-width='4'
                      stroke-miterlimit='10'
                    />
                  </g>
                </g>
                <g id='subtasks-6'>
                  <g id='rods_6'>
                    <g id='Group_98'>
                      <path
                        id='Rectangle_8-13_00000005267883419966938640000018123005754748249524_'
                        d='M350.11 189.204C353.31 189.804 356.41 187.704 357.01 184.404L369.01 118.304C369.61 115.104 367.51 112.004 364.21 111.404C361.01 110.804 357.91 112.904 357.31 116.204L345.31 182.304C344.71 185.504 346.91 188.604 350.11 189.204Z'
                        fill='#F6F9FD'
                      />
                      <g id='Group_99'>
                        <path
                          id='Rectangle_8-14_00000011710766598923545630000001477471122220290705_'
                          d='M350.1 189.164C353.3 189.764 356.4 187.664 357 184.364L369 118.264C369.6 115.064 367.5 111.964 364.2 111.364C361 110.764 357.9 112.864 357.3 116.164L345.3 182.264C344.7 185.564 346.9 188.564 350.1 189.164Z'
                          fill='white'
                        />
                      </g>
                    </g>
                    <g id='Group_100'>
                      <path
                        id='Rectangle_9-13_00000173852268660874579390000003758839633590017157_'
                        d='M334.309 199.504C334.909 196.304 332.909 193.204 329.709 192.604L263.909 179.504C260.709 178.904 257.609 180.904 257.009 184.104C256.409 187.304 258.409 190.404 261.609 191.004L327.409 204.104C330.609 204.804 333.709 202.704 334.309 199.504Z'
                        fill='#F6F9FD'
                      />
                      <g id='Group_101'>
                        <path
                          id='Rectangle_9-14_00000127730207118974195200000010615727910889917613_'
                          d='M334.3 199.463C334.9 196.263 332.9 193.164 329.7 192.564L263.9 179.464C260.7 178.864 257.6 180.864 257 184.064C256.4 187.264 258.4 190.364 261.6 190.964L327.4 204.064C330.6 204.764 333.7 202.663 334.3 199.463Z'
                          fill='white'
                        />
                      </g>
                    </g>
                  </g>
                  <g id='subtask-1_6'>
                    <g id='Group_102'>
                      <path
                        id='Ellipse_16-13_00000119079278213091628630000018020887311091252127_'
                        d='M346.809 220.404C356.971 220.404 365.209 212.166 365.209 202.004C365.209 191.842 356.971 183.604 346.809 183.604C336.647 183.604 328.409 191.842 328.409 202.004C328.409 212.166 336.647 220.404 346.809 220.404Z'
                        fill='#F6F9FD'
                      />
                      <g id='Group_103'>
                        <path
                          id='Ellipse_16-14_00000121986208867386365340000010646802020546592903_'
                          d='M346.8 220.363C356.962 220.363 365.2 212.125 365.2 201.963C365.2 191.801 356.962 183.563 346.8 183.563C336.638 183.563 328.399 191.801 328.399 201.963C328.399 212.125 336.638 220.363 346.8 220.363Z'
                          fill='white'
                        />
                      </g>
                    </g>
                    <path
                      id='Rectangle_15_00000130648219016155612520000008421094829911771295_'
                      d='M361.209 210.404L348.409 219.004C346.409 220.304 345.809 223.104 347.209 225.104C348.509 227.104 351.309 227.704 353.309 226.304L366.109 217.704C368.109 216.404 368.709 213.604 367.309 211.604C366.009 209.504 363.209 209.004 361.209 210.404Z'
                      fill='#E1E1E1'
                    />
                    <text
                      id='Task_16'
                      transform='translate(348.674 219.158) rotate(-32.9638)'
                      fill='#272727'
                      xmlSpace='preserve'
                      style={{whiteSpace: 'pre'}}
                      font-family='Poppins'
                      font-size='6.2224'
                      letter-spacing='0em'
                    >
                      <tspan x='0' y='6.67784'>
                        Task
                      </tspan>
                    </text>
                    <g id='Group_104' opacity={task[5].TasksAndStudents?.isCompleted ? '1' : '0.4'}>
                      <path
                        id='Ellipse_17-13_00000051375536865796516750000005692117524131972250_'
                        d='M346.8 214.664C353.814 214.664 359.5 208.978 359.5 201.964C359.5 194.95 353.814 189.264 346.8 189.264C339.786 189.264 334.1 194.95 334.1 201.964C334.1 208.978 339.786 214.664 346.8 214.664Z'
                        fill='#00AAFF'
                      />
                    </g>
                  </g>
                  <g id='subatsk-2'>
                    <g id='Group_105'>
                      <path
                        id='Ellipse_21-13_00000045586783940134530020000018359351549417270407_'
                        d='M366.91 119.504C377.072 119.504 385.31 111.266 385.31 101.104C385.31 90.9421 377.072 82.7041 366.91 82.7041C356.748 82.7041 348.51 90.9421 348.51 101.104C348.51 111.266 356.748 119.504 366.91 119.504Z'
                        fill='#F6F9FD'
                      />
                      <g id='Group_106'>
                        <path
                          id='Ellipse_21-14_00000150083319426405971030000005155406051922364300_'
                          d='M366.9 119.464C377.062 119.464 385.3 111.226 385.3 101.064C385.3 90.902 377.062 82.6641 366.9 82.6641C356.738 82.6641 348.5 90.902 348.5 101.064C348.5 111.226 356.738 119.464 366.9 119.464Z'
                          fill='white'
                        />
                      </g>
                    </g>
                    <path
                      id='Rectangle_13_00000074436509537956321360000008406571152298985609_'
                      d='M380.409 107.704L367.61 116.304C365.61 117.604 365.009 120.404 366.409 122.404C367.709 124.404 370.51 125.004 372.51 123.604L385.309 115.004C387.309 113.704 387.91 110.904 386.51 108.904C385.11 106.904 382.409 106.304 380.409 107.704Z'
                      fill='#E1E1E1'
                    />
                    <text
                      id='Task_17'
                      transform='translate(367.287 116.008) rotate(-32.9638)'
                      fill='#272727'
                      xmlSpace='preserve'
                      style={{whiteSpace: 'pre'}}
                      font-family='Poppins'
                      font-size='7.3488'
                      letter-spacing='0em'
                    >
                      <tspan x='0' y='8.07208'>
                        Task
                      </tspan>
                    </text>
                    <g id='Group_107' opacity={task[5].TasksAndStudents?.isCompleted ? '1' : '0.4'}>
                      <path
                        id='Ellipse_20-13_00000036942897312302511060000016908587114779686552_'
                        d='M366.9 113.764C373.914 113.764 379.6 108.078 379.6 101.064C379.6 94.0503 373.914 88.3643 366.9 88.3643C359.886 88.3643 354.2 94.0503 354.2 101.064C354.2 108.078 359.886 113.764 366.9 113.764Z'
                        fill='#00AAFF'
                      />
                    </g>
                  </g>
                  <g id='subatsk-3'>
                    <g id='Group_108'>
                      <path
                        id='Ellipse_18-13_00000088126433917249606060000002584273816686289553_'
                        d='M244.91 200.104C255.072 200.104 263.31 191.866 263.31 181.704C263.31 171.542 255.072 163.304 244.91 163.304C234.748 163.304 226.51 171.542 226.51 181.704C226.51 191.866 234.748 200.104 244.91 200.104Z'
                        fill='#F6F9FD'
                      />
                      <g id='Group_109'>
                        <path
                          id='Ellipse_18-14_00000013887440730254815560000010503395636196285343_'
                          d='M245 200.064C255.162 200.064 263.399 191.826 263.399 181.664C263.399 171.502 255.162 163.264 245 163.264C234.837 163.264 226.6 171.502 226.6 181.664C226.6 191.826 234.837 200.064 245 200.064Z'
                          fill='white'
                        />
                      </g>
                    </g>
                    <path
                      id='Rectangle_14_00000155848371315187620330000015824966722202391452_'
                      d='M259.31 188.504L246.51 197.104C244.51 198.404 243.91 201.204 245.31 203.204C246.61 205.204 249.41 205.804 251.41 204.404L264.21 195.804C266.21 194.504 266.81 191.704 265.41 189.704C264.01 187.704 261.31 187.204 259.31 188.504Z'
                      fill='#E1E1E1'
                    />
                    <text
                      id='Task_18'
                      transform='translate(246.085 196.674) rotate(-32.9638)'
                      fill='#272727'
                      xmlSpace='preserve'
                      style={{whiteSpace: 'pre'}}
                      font-family='Poppins'
                      font-size='7.3488'
                      letter-spacing='0em'
                    >
                      <tspan x='0' y='8.07208'>
                        Task
                      </tspan>
                    </text>
                    <g id='Group_110' opacity={task[5].TasksAndStudents?.isCompleted ? '1' : '0.4'}>
                      <path
                        id='Ellipse_19-13_00000098915057977135373250000012567359673242273721_'
                        d='M245 194.364C252.014 194.364 257.7 188.678 257.7 181.664C257.7 174.65 252.014 168.964 245 168.964C237.985 168.964 232.3 174.65 232.3 181.664C232.3 188.678 237.985 194.364 245 194.364Z'
                        fill='#00AAFF'
                      />
                    </g>
                  </g>
                </g>
                <a href='#' onClick={() => handleUserAction(task[5].TasksAndStudents?.id || 0)}>
                  <g id='box-task-6'>
                    <g id='Group_111'>
                      <path
                        id='Vector_283'
                        opacity='0.0196078'
                        d='M417.835 305.427C411.728 305.401 406.768 310.242 406.755 316.226L406.67 352.224C406.657 358.207 411.609 363.077 417.716 363.103L454.458 363.263C460.565 363.288 465.525 358.447 465.538 352.464L465.622 316.466C465.636 310.483 460.684 305.613 454.577 305.587L417.835 305.427Z'
                        fill='#FBFCFD'
                      />
                      <path
                        id='Vector_284'
                        opacity='0.0392157'
                        d='M417.934 305.433C411.827 305.407 406.866 310.248 406.853 316.232L406.769 352.229C406.756 358.213 411.708 363.083 417.815 363.109L454.557 363.268C460.664 363.294 465.624 358.453 465.637 352.47L465.722 316.472C465.735 310.489 460.783 305.618 454.676 305.593L417.934 305.433Z'
                        fill='#F8F9FB'
                      />
                      <path
                        id='Vector_285'
                        opacity='0.0588235'
                        d='M418.029 305.535C411.922 305.51 406.962 310.351 406.949 316.334L406.865 352.332C406.852 358.315 411.803 363.185 417.91 363.211L454.653 363.371C460.76 363.397 465.72 358.556 465.733 352.572L465.817 316.574C465.83 310.591 460.878 305.721 454.771 305.695L418.029 305.535Z'
                        fill='#F4F6F9'
                      />
                      <path
                        id='Vector_286'
                        opacity='0.0784314'
                        d='M418.125 305.639C412.018 305.613 407.058 310.454 407.045 316.438L406.96 352.435C406.947 358.419 411.899 363.289 418.006 363.315L454.748 363.475C460.855 363.5 465.816 358.659 465.829 352.676L465.913 316.678C465.926 310.695 460.974 305.824 454.867 305.799L418.125 305.639Z'
                        fill='#F1F3F7'
                      />
                      <path
                        id='Vector_287'
                        opacity='0.0980392'
                        d='M418.219 305.742C412.112 305.717 407.152 310.558 407.138 316.541L407.054 352.539C407.041 358.522 411.993 363.392 418.1 363.418L454.842 363.578C460.949 363.604 465.909 358.763 465.922 352.779L466.006 316.781C466.019 310.798 461.067 305.928 454.96 305.902L418.219 305.742Z'
                        fill='#EDF0F4'
                      />
                      <path
                        id='Vector_288'
                        opacity='0.1176'
                        d='M418.313 305.845C412.206 305.819 407.246 310.66 407.233 316.644L407.149 352.642C407.136 358.625 412.088 363.495 418.195 363.521L454.936 363.681C461.043 363.706 466.004 358.865 466.017 352.882L466.101 316.884C466.114 310.901 461.162 306.031 455.055 306.005L418.313 305.845Z'
                        fill='#EAEDF2'
                      />
                      <path
                        id='Vector_289'
                        opacity='0.1373'
                        d='M418.408 305.947C412.301 305.922 407.341 310.763 407.328 316.746L407.244 352.744C407.231 358.727 412.183 363.598 418.29 363.623L455.032 363.783C461.139 363.809 466.099 358.968 466.112 352.984L466.196 316.987C466.209 311.003 461.257 306.133 455.15 306.107L418.408 305.947Z'
                        fill='#E6EAF0'
                      />
                      <path
                        id='Vector_290'
                        opacity='0.1569'
                        d='M418.503 306.051C412.396 306.025 407.436 310.866 407.423 316.85L407.339 352.848C407.326 358.831 412.277 363.701 418.384 363.727L455.126 363.887C461.233 363.912 466.193 359.071 466.207 353.088L466.291 317.09C466.304 311.107 461.352 306.237 455.245 306.211L418.503 306.051Z'
                        fill='#E3E7EE'
                      />
                      <path
                        id='Vector_291'
                        opacity='0.1765'
                        d='M418.599 306.155C412.492 306.129 407.531 310.97 407.518 316.953L407.434 352.951C407.421 358.934 412.373 363.805 418.48 363.83L455.222 363.99C461.329 364.016 466.289 359.175 466.302 353.191L466.386 317.194C466.399 311.21 461.448 306.34 455.341 306.314L418.599 306.155Z'
                        fill='#DFE3EC'
                      />
                      <path
                        id='Vector_292'
                        opacity='0.1961'
                        d='M418.698 306.159C412.591 306.134 407.631 310.975 407.618 316.958L407.534 352.956C407.52 358.939 412.472 363.809 418.579 363.835L455.322 363.995C461.429 364.021 466.389 359.18 466.402 353.196L466.486 317.198C466.499 311.215 461.547 306.345 455.44 306.319L418.698 306.159Z'
                        fill='#DCE0EA'
                      />
                      <path
                        id='Vector_293'
                        opacity='0.2157'
                        d='M418.792 306.262C412.685 306.236 407.725 311.077 407.712 317.061L407.627 353.059C407.614 359.042 412.566 363.912 418.673 363.938L455.415 364.098C461.522 364.123 466.482 359.282 466.495 353.299L466.58 317.301C466.593 311.318 461.641 306.448 455.534 306.422L418.792 306.262Z'
                        fill='#D8DDE8'
                      />
                      <path
                        id='Vector_294'
                        opacity='0.2353'
                        d='M418.887 306.366C412.78 306.34 407.819 311.181 407.806 317.164L407.723 353.162C407.709 359.146 412.661 364.016 418.768 364.041L455.51 364.201C461.617 364.227 466.577 359.386 466.59 353.402L466.675 317.405C466.688 311.421 461.736 306.551 455.629 306.525L418.887 306.366Z'
                        fill='#D4DAE6'
                      />
                      <path
                        id='Vector_295'
                        opacity='0.2549'
                        d='M418.981 306.469C412.874 306.443 407.914 311.284 407.901 317.268L407.817 353.266C407.804 359.249 412.756 364.119 418.863 364.145L455.605 364.305C461.712 364.33 466.672 359.489 466.685 353.506L466.769 317.508C466.783 311.525 461.831 306.655 455.724 306.629L418.981 306.469Z'
                        fill='#D1D7E3'
                      />
                      <path
                        id='Vector_296'
                        opacity='0.2745'
                        d='M419.077 306.571C412.97 306.546 408.01 311.387 407.997 317.37L407.912 353.368C407.899 359.351 412.851 364.222 418.958 364.247L455.701 364.407C461.808 364.433 466.768 359.592 466.781 353.608L466.865 317.611C466.878 311.627 461.926 306.757 455.819 306.731L419.077 306.571Z'
                        fill='#CDD4E1'
                      />
                      <path
                        id='Vector_297'
                        opacity='0.2941'
                        d='M419.172 306.675C413.065 306.649 408.105 311.491 408.091 317.474L408.007 353.472C407.994 359.455 412.946 364.325 419.053 364.351L455.795 364.511C461.902 364.537 466.862 359.695 466.876 353.712L466.959 317.714C466.973 311.731 462.021 306.861 455.914 306.835L419.172 306.675Z'
                        fill='#CAD1DF'
                      />
                      <path
                        id='Vector_298'
                        opacity='0.3137'
                        d='M419.266 306.778C413.159 306.752 408.198 311.593 408.185 317.576L408.101 353.574C408.088 359.557 413.04 364.428 419.147 364.453L455.888 364.613C461.996 364.639 466.956 359.798 466.969 353.815L467.053 317.817C467.066 311.833 462.114 306.963 456.007 306.937L419.266 306.778Z'
                        fill='#C6CEDD'
                      />
                      <path
                        id='Vector_299'
                        opacity='0.3333'
                        d='M419.36 306.881C413.253 306.855 408.293 311.697 408.28 317.68L408.196 353.678C408.183 359.661 413.135 364.531 419.242 364.557L455.984 364.717C462.091 364.743 467.051 359.901 467.064 353.918L467.148 317.92C467.162 311.937 462.21 307.067 456.102 307.041L419.36 306.881Z'
                        fill='#C3CBDB'
                      />
                      <path
                        id='Vector_300'
                        opacity='0.3529'
                        d='M419.461 306.886C413.354 306.86 408.394 311.701 408.381 317.685L408.297 353.683C408.284 359.666 413.235 364.536 419.342 364.562L456.084 364.722C462.191 364.747 467.151 359.906 467.165 353.923L467.249 317.925C467.262 311.942 462.31 307.072 456.203 307.046L419.461 306.886Z'
                        fill='#BFC8D9'
                      />
                      <path
                        id='Vector_301'
                        opacity='0.3725'
                        d='M419.556 306.989C413.449 306.964 408.488 311.805 408.475 317.788L408.391 353.786C408.378 359.769 413.33 364.64 419.437 364.665L456.179 364.825C462.286 364.851 467.246 360.01 467.259 354.026L467.343 318.029C467.356 312.045 462.405 307.175 456.298 307.149L419.556 306.989Z'
                        fill='#BCC5D7'
                      />
                      <path
                        id='Vector_302'
                        opacity='0.3922'
                        d='M419.65 307.092C413.543 307.066 408.583 311.908 408.57 317.891L408.486 353.889C408.473 359.872 413.425 364.742 419.532 364.768L456.274 364.928C462.381 364.953 467.341 360.112 467.354 354.129L467.438 318.131C467.451 312.148 462.499 307.278 456.392 307.252L419.65 307.092Z'
                        fill='#B8C2D5'
                      />
                      <path
                        id='Vector_303'
                        opacity='0.4118'
                        d='M419.745 307.196C413.638 307.17 408.678 312.011 408.665 317.994L408.58 353.992C408.567 359.975 413.519 364.846 419.626 364.871L456.368 365.031C462.475 365.057 467.435 360.216 467.448 354.232L467.533 318.235C467.546 312.251 462.594 307.381 456.487 307.355L419.745 307.196Z'
                        fill='#B4BFD3'
                      />
                      <path
                        id='Vector_304'
                        opacity='0.4314'
                        d='M419.739 307.293C413.632 307.268 408.672 312.109 408.659 318.092L408.575 354.09C408.561 360.073 413.513 364.943 419.62 364.969L456.362 365.129C462.469 365.155 467.429 360.313 467.442 354.33L467.527 318.332C467.54 312.349 462.588 307.479 456.481 307.453L419.739 307.293Z'
                        fill='#B1BCD0'
                      />
                      <path
                        id='Vector_305'
                        opacity='0.451'
                        d='M419.834 307.396C413.727 307.37 408.767 312.211 408.754 318.195L408.67 354.192C408.657 360.176 413.609 365.046 419.716 365.072L456.457 365.231C462.564 365.257 467.525 360.416 467.538 354.433L467.622 318.435C467.635 312.451 462.683 307.581 456.576 307.556L419.834 307.396Z'
                        fill='#ADB9CE'
                      />
                      <path
                        id='Vector_306'
                        opacity='0.4706'
                        d='M419.93 307.499C413.823 307.473 408.862 312.315 408.849 318.298L408.765 354.296C408.752 360.279 413.704 365.149 419.811 365.175L456.553 365.335C462.66 365.361 467.62 360.519 467.633 354.536L467.718 318.538C467.731 312.555 462.779 307.685 456.672 307.659L419.93 307.499Z'
                        fill='#AAB6CC'
                      />
                      <path
                        id='Vector_307'
                        opacity='0.4902'
                        d='M420.024 307.602C413.917 307.576 408.957 312.417 408.944 318.401L408.86 354.398C408.847 360.382 413.799 365.252 419.906 365.278L456.648 365.437C462.755 365.463 467.715 360.622 467.728 354.639L467.812 318.641C467.825 312.658 462.874 307.787 456.767 307.762L420.024 307.602Z'
                        fill='#A6B3CA'
                      />
                      <path
                        id='Vector_308'
                        opacity='0.5098'
                        d='M420.124 307.608C414.017 307.582 409.057 312.423 409.044 318.406L408.959 354.404C408.946 360.388 413.898 365.258 420.005 365.283L456.747 365.443C462.854 365.469 467.815 360.628 467.828 354.645L467.912 318.647C467.925 312.663 462.973 307.793 456.866 307.767L420.124 307.608Z'
                        fill='#A3AFC8'
                      />
                      <path
                        id='Vector_309'
                        opacity='0.5294'
                        d='M420.219 307.71C414.112 307.685 409.152 312.526 409.138 318.509L409.054 354.507C409.041 360.49 413.993 365.36 420.1 365.386L456.842 365.546C462.949 365.572 467.909 360.73 467.922 354.747L468.006 318.749C468.019 312.766 463.067 307.896 456.96 307.87L420.219 307.71Z'
                        fill='#9FACC6'
                      />
                      <path
                        id='Vector_310'
                        opacity='0.549'
                        d='M420.312 307.814C414.205 307.788 409.245 312.629 409.232 318.613L409.148 354.61C409.135 360.594 414.087 365.464 420.194 365.49L456.936 365.649C463.043 365.675 468.003 360.834 468.016 354.851L468.101 318.853C468.114 312.869 463.162 307.999 457.055 307.974L420.312 307.814Z'
                        fill='#9CA9C4'
                      />
                      <path
                        id='Vector_311'
                        opacity='0.5686'
                        d='M420.408 307.916C414.301 307.891 409.341 312.732 409.328 318.715L409.244 354.713C409.231 360.696 414.182 365.566 420.289 365.592L457.032 365.752C463.139 365.778 468.099 360.937 468.112 354.953L468.196 318.955C468.209 312.972 463.257 308.102 457.15 308.076L420.408 307.916Z'
                        fill='#98A6C2'
                      />
                      <path
                        id='Vector_312'
                        opacity='0.5882'
                        d='M420.503 308.02C414.396 307.994 409.436 312.835 409.423 318.818L409.338 354.816C409.325 360.8 414.277 365.67 420.384 365.696L457.126 365.855C463.233 365.881 468.193 361.04 468.207 355.057L468.29 319.059C468.304 313.075 463.352 308.205 457.245 308.18L420.503 308.02Z'
                        fill='#95A3BF'
                      />
                      <path
                        id='Vector_313'
                        opacity='0.6078'
                        d='M420.598 308.122C414.491 308.097 409.53 312.938 409.517 318.921L409.433 354.919C409.42 360.902 414.372 365.772 420.479 365.798L457.221 365.958C463.328 365.984 468.288 361.142 468.301 355.159L468.385 319.161C468.398 313.178 463.446 308.308 457.339 308.282L420.598 308.122Z'
                        fill='#91A0BD'
                      />
                      <path
                        id='Vector_314'
                        opacity='0.6275'
                        d='M420.692 308.226C414.585 308.2 409.625 313.041 409.612 319.025L409.528 355.022C409.515 361.006 414.467 365.876 420.574 365.902L457.315 366.061C463.422 366.087 468.382 361.246 468.396 355.263L468.48 319.265C468.493 313.282 463.541 308.411 457.434 308.386L420.692 308.226Z'
                        fill='#8D9DBB'
                      />
                      <path
                        id='Vector_315'
                        opacity='0.6471'
                        d='M420.786 308.328C414.679 308.303 409.719 313.144 409.706 319.127L409.622 355.125C409.609 361.108 414.561 365.978 420.668 366.004L457.409 366.164C463.517 366.19 468.477 361.349 468.49 355.365L468.574 319.367C468.587 313.384 463.635 308.514 457.528 308.488L420.786 308.328Z'
                        fill='#8A9AB9'
                      />
                      <path
                        id='Vector_316'
                        opacity='0.6667'
                        d='M420.882 308.432C414.775 308.406 409.815 313.247 409.801 319.231L409.718 355.229C409.704 361.212 414.656 366.082 420.763 366.108L457.505 366.268C463.612 366.293 468.572 361.452 468.586 355.469L468.67 319.471C468.683 313.488 463.731 308.617 457.624 308.592L420.882 308.432Z'
                        fill='#8697B7'
                      />
                      <path
                        id='Vector_317'
                        opacity='0.6863'
                        d='M420.982 308.437C414.875 308.411 409.915 313.252 409.902 319.236L409.818 355.233C409.805 361.217 414.757 366.087 420.864 366.113L457.606 366.272C463.713 366.298 468.673 361.457 468.686 355.474L468.77 319.476C468.783 313.492 463.832 308.622 457.725 308.597L420.982 308.437Z'
                        fill='#8394B5'
                      />
                      <path
                        id='Vector_318'
                        opacity='0.7059'
                        d='M421.076 308.54C414.969 308.514 410.009 313.356 409.996 319.339L409.911 355.337C409.898 361.32 414.85 366.19 420.957 366.216L457.7 366.376C463.807 366.402 468.767 361.56 468.78 355.577L468.864 319.579C468.877 313.596 463.925 308.726 457.818 308.7L421.076 308.54Z'
                        fill='#7F91B3'
                      />
                      <path
                        id='Vector_319'
                        opacity='0.7255'
                        d='M421.171 308.643C415.064 308.617 410.104 313.458 410.091 319.442L410.006 355.439C409.993 361.423 414.945 366.293 421.052 366.319L457.794 366.478C463.901 366.504 468.861 361.663 468.874 355.68L468.958 319.682C468.972 313.699 464.02 308.828 457.913 308.803L421.171 308.643Z'
                        fill='#7C8EB1'
                      />
                      <path
                        id='Vector_320'
                        opacity='0.7451'
                        d='M421.266 308.746C415.159 308.721 410.198 313.562 410.185 319.545L410.101 355.543C410.088 361.526 415.04 366.396 421.147 366.422L457.889 366.582C463.996 366.608 468.956 361.767 468.969 355.783L469.054 319.785C469.067 313.802 464.115 308.932 458.008 308.906L421.266 308.746Z'
                        fill='#788BAF'
                      />
                      <path
                        id='Vector_321'
                        opacity='0.7647'
                        d='M421.36 308.849C415.253 308.823 410.293 313.664 410.28 319.648L410.196 355.645C410.183 361.629 415.135 366.499 421.242 366.525L457.984 366.685C464.091 366.71 469.051 361.869 469.064 355.886L469.148 319.888C469.162 313.905 464.21 309.034 458.102 309.009L421.36 308.849Z'
                        fill='#7588AC'
                      />
                      <path
                        id='Vector_322'
                        opacity='0.7843'
                        d='M421.456 308.952C415.349 308.927 410.389 313.768 410.376 319.751L410.291 355.749C410.278 361.732 415.23 366.603 421.337 366.628L458.079 366.788C464.186 366.814 469.147 361.973 469.16 355.989L469.244 319.991C469.257 314.008 464.305 309.138 458.198 309.112L421.456 308.952Z'
                        fill='#7185AA'
                      />
                      <path
                        id='Vector_323'
                        opacity='0.8039'
                        d='M421.55 309.055C415.443 309.029 410.483 313.87 410.469 319.854L410.385 355.851C410.372 361.835 415.324 366.705 421.431 366.731L458.173 366.891C464.28 366.916 469.24 362.075 469.254 356.092L469.337 320.094C469.35 314.111 464.398 309.24 458.291 309.215L421.55 309.055Z'
                        fill='#6D82A8'
                      />
                      <path
                        id='Vector_324'
                        opacity='0.8235'
                        d='M421.644 309.158C415.537 309.133 410.577 313.974 410.564 319.957L410.48 355.955C410.467 361.938 415.419 366.809 421.526 366.834L458.267 366.994C464.374 367.02 469.335 362.179 469.348 356.195L469.432 320.197C469.445 314.214 464.493 309.344 458.386 309.318L421.644 309.158Z'
                        fill='#6A7FA6'
                      />
                      <path
                        id='Vector_325'
                        opacity='0.8431'
                        d='M421.744 309.163C415.637 309.138 410.677 313.979 410.664 319.962L410.58 355.96C410.567 361.943 415.519 366.813 421.626 366.839L458.368 366.999C464.475 367.025 469.435 362.184 469.448 356.2L469.532 320.202C469.545 314.219 464.593 309.349 458.486 309.323L421.744 309.163Z'
                        fill='#667BA4'
                      />
                      <path
                        id='Vector_326'
                        opacity='0.8627'
                        d='M421.839 309.267C415.732 309.241 410.772 314.082 410.758 320.066L410.675 356.063C410.662 362.047 415.613 366.917 421.72 366.943L458.462 367.103C464.569 367.128 469.529 362.287 469.543 356.304L469.627 320.306C469.64 314.323 464.688 309.452 458.581 309.427L421.839 309.267Z'
                        fill='#6378A2'
                      />
                      <path
                        id='Vector_327'
                        opacity='0.8824'
                        d='M421.935 309.369C415.828 309.344 410.867 314.185 410.854 320.168L410.77 356.166C410.757 362.149 415.709 367.019 421.816 367.045L458.558 367.205C464.665 367.231 469.625 362.39 469.638 356.406L469.722 320.408C469.735 314.425 464.784 309.555 458.677 309.529L421.935 309.369Z'
                        fill='#5F75A0'
                      />
                      <path
                        id='Vector_328'
                        opacity='0.902'
                        d='M422.029 309.473C415.922 309.447 410.962 314.288 410.949 320.272L410.865 356.27C410.851 362.253 415.803 367.123 421.91 367.149L458.653 367.309C464.76 367.334 469.72 362.493 469.733 356.51L469.817 320.512C469.83 314.529 464.878 309.658 458.771 309.633L422.029 309.473Z'
                        fill='#5C729E'
                      />
                      <path
                        id='Vector_329'
                        opacity='0.9216'
                        d='M422.123 309.576C416.016 309.551 411.056 314.392 411.043 320.375L410.958 356.373C410.945 362.356 415.897 367.226 422.004 367.252L458.746 367.412C464.853 367.438 469.813 362.597 469.826 356.613L469.911 320.615C469.924 314.632 464.972 309.762 458.865 309.736L422.123 309.576Z'
                        fill='#586F9B'
                      />
                      <path
                        id='Vector_330'
                        opacity='0.9412'
                        d='M422.218 309.68C416.111 309.654 411.151 314.495 411.137 320.479L411.054 356.477C411.04 362.46 415.992 367.33 422.099 367.356L458.841 367.516C464.948 367.541 469.908 362.7 469.921 356.717L470.006 320.719C470.019 314.736 465.067 309.866 458.96 309.84L422.218 309.68Z'
                        fill='#556C99'
                      />
                      <path
                        id='Vector_331'
                        opacity='0.9608'
                        d='M422.313 309.782C416.206 309.756 411.246 314.597 411.233 320.58L411.149 356.578C411.136 362.561 416.088 367.432 422.195 367.457L458.937 367.617C465.044 367.643 470.004 362.802 470.017 356.818L470.101 320.821C470.115 314.837 465.163 309.967 459.056 309.941L422.313 309.782Z'
                        fill='#516997'
                      />
                      <path
                        id='Vector_332'
                        opacity='0.9804'
                        d='M422.408 309.885C416.301 309.859 411.341 314.7 411.328 320.684L411.244 356.682C411.23 362.665 416.182 367.535 422.289 367.561L459.032 367.721C465.139 367.746 470.099 362.905 470.112 356.922L470.196 320.924C470.209 314.941 465.257 310.071 459.15 310.045L422.408 309.885Z'
                        fill='#4E6695'
                      />
                      <path
                        id='Vector_333'
                        d='M422.508 309.89C416.401 309.864 411.441 314.705 411.427 320.689L411.343 356.686C411.33 362.67 416.282 367.54 422.389 367.566L459.131 367.726C465.238 367.751 470.198 362.91 470.212 356.927L470.295 320.929C470.308 314.946 465.356 310.075 459.249 310.05L422.508 309.89Z'
                        fill='#4A6393'
                      />
                    </g>
                    <path
                      id='Vector_334'
                      d='M423.35 311.014C417.343 310.993 412.488 315.742 412.48 321.627L412.432 356.94C412.423 362.826 417.281 367.593 423.288 367.613L459.331 367.736C465.338 367.757 470.193 363.008 470.201 357.123L470.249 321.81C470.257 315.924 465.4 311.157 459.393 311.137L423.35 311.014Z'
                      fill='url(#paint5_linear_473_2)'
                    />
                    <path
                      id='Vector_335'
                      d='M423.325 311.503C417.617 311.498 412.873 316.056 412.88 321.648L412.831 356.961C412.839 362.553 417.501 367.212 423.209 367.217L459.252 367.34C464.959 367.345 469.705 362.787 469.697 357.194L469.745 321.881C469.738 316.289 465.076 311.631 459.368 311.626L423.325 311.503Z'
                      fill={task[5].TasksAndStudents?.isCompleted ? '#1D87CE' : '#fff'}
                    />
                    <text
                      id='Task 6'
                      transform='matrix(0.999841 0.0145126 -0.0129252 0.999863 420.98 317.874)'
                      fill={task[5].TasksAndStudents?.isCompleted ? '#fff' : '#000'}
                      xmlSpace='preserve'
                      style={{whiteSpace: 'pre'}}
                      font-family='Poppins'
                      font-size='13.3613'
                      font-weight='bold'
                      letter-spacing='0em'
                    >
                      <tspan x='0' y='14.6765'>
                        {task[5].name && task[5].name.length > 3
                          ? truncateText(task[5].name, 4)
                          : task[5].name}
                      </tspan>
                    </text>
                    <path
                      id='Vector_336'
                      d='M428.916 353.573L454.632 353.939C458.232 354.03 461.188 351.144 461.272 347.617L461.28 345.557C461.365 342.03 458.412 339.128 454.812 339.038L429.096 338.671C425.496 338.58 422.541 341.467 422.456 344.994L422.448 347.053C422.464 350.586 425.316 353.482 428.916 353.573Z'
                      fill={task[5].TasksAndStudents?.isCompleted ? '#fff' : '#000'}
                    />
                    <text
                      id='3/7_6'
                      transform='matrix(0.999985 -0.006535 0.00900199 0.999966 433.043 339.38)'
                      fill={task[5].TasksAndStudents?.isCompleted ? '#1D87CE' : '#fff'}
                      xmlSpace='preserve'
                      style={{whiteSpace: 'pre'}}
                      font-family='Poppins'
                      font-size='11.3286'
                      font-weight='bold'
                      letter-spacing='0em'
                    >
                      <tspan x='0' y='12.465'>
                        {/* {task[5].counter + '/3'} */}
                        {(task[5]?.TasksAndStudents?.counter || 0) +
                          '/' +
                          (task[5]?.total_counter || 0)}
                      </tspan>
                    </text>
                  </g>
                </a>
              </g>
            )}
            {task[6] !== undefined && task[6] !== null && (
              <g id='task-7'>
                <g id='rod-7'>
                  <g id='Rectangle_6-5_00000176762172907447070870000010670396537225979785_'>
                    <g id='Group_112'>
                      <path
                        id='Vector_337'
                        d='M634.51 515.804C631.31 515.404 628.91 512.504 629.31 509.304L672.31 135.004C672.71 131.804 675.61 129.404 678.81 129.804C682.01 130.204 684.41 133.104 684.01 136.304L641.01 510.604C640.61 513.804 637.71 516.104 634.51 515.804Z'
                        fill='#F6F9FD'
                      />
                    </g>
                    <g id='Group_113'>
                      <path
                        id='Vector_338'
                        d='M634.51 515.804C631.31 515.404 628.91 512.504 629.31 509.304L672.31 135.004C672.71 131.804 675.61 129.404 678.81 129.804C682.01 130.204 684.41 133.104 684.01 136.304L641.01 510.604C640.61 513.804 637.71 516.104 634.51 515.804Z'
                        fill='#0686EF'
                      />
                    </g>
                  </g>
                  <g id='Group_114'>
                    <path
                      id='Rectangle_6-6_00000160185391420790177960000000562168172842457746_'
                      d='M634.501 515.764C631.301 515.364 628.901 512.464 629.301 509.264L672.301 134.964C672.701 131.764 675.601 129.364 678.801 129.764C682.001 130.164 684.401 133.064 684.001 136.264L641.001 510.564C640.601 513.764 637.701 516.164 634.501 515.764Z'
                      stroke='#00AAFF'
                      stroke-width='4'
                      stroke-miterlimit='10'
                    />
                  </g>
                </g>
                <g id='subtasks-7'>
                  <g id='rods_7'>
                    <g id='Group_115'>
                      <path
                        id='Rectangle_8-13_00000178914187701189498210000017128452632075608968_'
                        d='M670.211 108.005C667.611 110.005 663.91 109.605 661.91 107.005L620.111 54.4047C618.111 51.8047 618.511 48.1047 621.111 46.1047C623.711 44.1047 627.41 44.5047 629.41 47.1047L671.211 99.7047C673.211 102.305 672.811 106.005 670.211 108.005Z'
                        fill='#F6F9FD'
                      />
                      <g id='Group_116'>
                        <path
                          id='Rectangle_8-14_00000128483812041272004870000017106286299964592525_'
                          d='M670.201 107.965C667.601 109.965 663.901 109.565 661.901 106.965L620.101 54.3647C618.101 51.7647 618.501 48.0647 621.101 46.0647C623.701 44.0647 627.401 44.4647 629.401 47.0647L671.201 99.6646C673.201 102.265 672.801 105.965 670.201 107.965Z'
                          fill='white'
                        />
                      </g>
                    </g>
                    <g id='Group_117'>
                      <path
                        id='Rectangle_9-13_00000018227016991894757890000004937048259876460206_'
                        d='M689.011 109.705C686.911 107.205 687.31 103.505 689.81 101.405L741.61 58.7048C744.11 56.6048 747.81 57.0048 749.91 59.5048C752.01 62.0048 751.61 65.7048 749.11 67.8048L697.31 110.505C694.71 112.505 691.011 112.205 689.011 109.705Z'
                        fill='#F6F9FD'
                      />
                      <g id='Group_118'>
                        <path
                          id='Rectangle_9-14_00000062907420466627505820000003523119485355819192_'
                          d='M689.001 109.665C686.901 107.165 687.301 103.465 689.801 101.365L741.601 58.6648C744.101 56.5648 747.801 56.9648 749.901 59.4648C752.001 61.9648 751.601 65.6648 749.101 67.7648L697.301 110.465C694.801 112.565 691.001 112.165 689.001 109.665Z'
                          fill='white'
                        />
                      </g>
                    </g>
                  </g>
                  <g id='subtask-1_7'>
                    <g id='Group_119'>
                      <path
                        id='Ellipse_16-13_00000080168993269345708400000009586465173291597970_'
                        d='M679.111 136.105C689.273 136.105 697.511 127.867 697.511 117.705C697.511 107.543 689.273 99.3047 679.111 99.3047C668.949 99.3047 660.711 107.543 660.711 117.705C660.711 127.867 668.949 136.105 679.111 136.105Z'
                        fill='#F6F9FD'
                      />
                      <g id='Group_120'>
                        <path
                          id='Ellipse_16-14_00000082360366000852594580000006493266826684869562_'
                          d='M679.101 136.164C689.263 136.164 697.501 127.926 697.501 117.764C697.501 107.602 689.263 99.3643 679.101 99.3643C668.939 99.3643 660.701 107.602 660.701 117.764C660.701 127.926 668.939 136.164 679.101 136.164Z'
                          fill='white'
                        />
                      </g>
                    </g>
                    <path
                      id='Rectangle_15_00000114775297233198489160000015734940066970631809_'
                      d='M670.411 131.904L685.811 133.404C688.211 133.604 690.011 135.804 689.711 138.204C689.511 140.604 687.311 142.404 684.911 142.104L669.511 140.604C667.111 140.404 665.311 138.204 665.611 135.804C665.811 133.504 668.011 131.704 670.411 131.904Z'
                      fill='#E1E1E1'
                    />
                    <text
                      id='Task_19'
                      transform='translate(670.986 132.527) rotate(5.54811)'
                      fill='#272727'
                      xmlSpace='preserve'
                      style={{whiteSpace: 'pre'}}
                      font-family='Poppins'
                      font-size='6.2225'
                      letter-spacing='0em'
                    >
                      <tspan x='0' y='6.67788'>
                        Task
                      </tspan>
                    </text>
                    <g id='Group_121' opacity={task[6].TasksAndStudents?.isCompleted ? '1' : '0.4'}>
                      <path
                        id='Ellipse_17-13_00000158011498207286712640000002305823647625711525_'
                        d='M679.2 130.464C686.214 130.464 691.9 124.778 691.9 117.764C691.9 110.75 686.214 105.064 679.2 105.064C672.186 105.064 666.5 110.75 666.5 117.764C666.5 124.778 672.186 130.464 679.2 130.464Z'
                        fill='#00AAFF'
                      />
                    </g>
                  </g>
                  <g id='subtask-2_6'>
                    <g id='Group_122'>
                      <path
                        id='Ellipse_21-13_00000149343678047743182560000009713841517248651654_'
                        d='M613.81 56.7043C623.972 56.7043 632.21 48.4663 632.21 38.3043C632.21 28.1423 623.972 19.9043 613.81 19.9043C603.648 19.9043 595.41 28.1423 595.41 38.3043C595.41 48.4663 603.648 56.7043 613.81 56.7043Z'
                        fill='#F6F9FD'
                      />
                      <g id='Group_123'>
                        <path
                          id='Ellipse_21-14_00000011005084749585306500000008996096253566484399_'
                          d='M613.801 56.6642C623.963 56.6642 632.201 48.4263 632.201 38.2643C632.201 28.1022 623.963 19.8643 613.801 19.8643C603.639 19.8643 595.4 28.1022 595.4 38.2643C595.4 48.4263 603.639 56.6642 613.801 56.6642Z'
                          fill='white'
                        />
                      </g>
                    </g>
                    <path
                      id='Rectangle_13_00000119806622159054176710000012749298771057451704_'
                      d='M605.01 50.5044L620.41 52.0044C622.81 52.2044 624.61 54.4044 624.31 56.8044C624.11 59.2044 621.91 61.0044 619.51 60.7044L604.11 59.2044C601.71 59.0044 599.91 56.8044 600.21 54.4044C600.51 52.0044 602.61 50.2044 605.01 50.5044Z'
                      fill='#E1E1E1'
                    />
                    <text
                      id='Task_20'
                      transform='translate(604.456 49.9521) rotate(5.54811)'
                      fill='#272727'
                      xmlSpace='preserve'
                      style={{whiteSpace: 'pre'}}
                      font-family='Poppins'
                      font-size='7.3488'
                      letter-spacing='0em'
                    >
                      <tspan x='0' y='8.07208'>
                        Task
                      </tspan>
                    </text>
                    <g id='Group_124' opacity={task[6].TasksAndStudents?.isCompleted ? '1' : '0.4'}>
                      <path
                        id='Ellipse_20-13_00000029736764661502747940000013516007862314737280_'
                        d='M613.801 50.9644C620.815 50.9644 626.5 45.2785 626.5 38.2644C626.5 31.2504 620.815 25.5645 613.801 25.5645C606.787 25.5645 601.101 31.2504 601.101 38.2644C601.101 45.2785 606.787 50.9644 613.801 50.9644Z'
                        fill='#00AAFF'
                      />
                    </g>
                  </g>
                  <g id='subtask-3_6'>
                    <g id='Group_125'>
                      <path
                        id='Ellipse_18-13_00000050641962211305804470000017056267982862462625_'
                        d='M759.31 70.2043C769.472 70.2043 777.71 61.9663 777.71 51.8043C777.71 41.6423 769.472 33.4043 759.31 33.4043C749.148 33.4043 740.91 41.6423 740.91 51.8043C740.91 61.9663 749.148 70.2043 759.31 70.2043Z'
                        fill='#F6F9FD'
                      />
                      <g id='Group_126'>
                        <path
                          id='Ellipse_18-14_00000146496274410579662060000007064230325827884963_'
                          d='M759.4 70.1642C769.562 70.1642 777.8 61.9263 777.8 51.7643C777.8 41.6022 769.562 33.3643 759.4 33.3643C749.238 33.3643 741 41.6022 741 51.7643C741 61.9263 749.238 70.1642 759.4 70.1642Z'
                          fill='white'
                        />
                      </g>
                    </g>
                    <path
                      id='Rectangle_14_00000002351273164555055520000015950565401803777199_'
                      d='M749.911 64.5045L765.311 66.0045C767.711 66.2045 769.511 68.4044 769.211 70.8044C769.011 73.2044 766.811 75.0044 764.411 74.7044L749.011 73.2044C746.611 73.0044 744.811 70.8044 745.111 68.4044C745.411 66.1044 747.511 64.3045 749.911 64.5045Z'
                      fill='#E1E1E1'
                    />
                    <text
                      id='Task_21'
                      transform='translate(749.357 64.0273) rotate(5.54811)'
                      fill='#272727'
                      xmlSpace='preserve'
                      style={{whiteSpace: 'pre'}}
                      font-family='Poppins'
                      font-size='7.3488'
                      letter-spacing='0em'
                    >
                      <tspan x='0' y='8.07208'>
                        Task
                      </tspan>
                    </text>
                    <g id='Group_127' opacity={task[6].TasksAndStudents?.isCompleted ? '1' : '0.4'}>
                      <path
                        id='Ellipse_19-13_00000171694551642677810470000005567049494316052652_'
                        d='M759.301 64.4644C766.315 64.4644 772 58.7785 772 51.7644C772 44.7504 766.315 39.0645 759.301 39.0645C752.287 39.0645 746.601 44.7504 746.601 51.7644C746.601 58.7785 752.287 64.4644 759.301 64.4644Z'
                        fill='#00AAFF'
                      />
                    </g>
                  </g>
                </g>
                <a href='#' onClick={() => handleUserAction(task[6].TasksAndStudents?.id || 0)}>
                  <g id='box-7'>
                    <g id='Group_128'>
                      <path
                        id='Vector_339'
                        opacity='0.0196078'
                        d='M681.011 319.004C687.111 319.604 692.511 315.104 693.111 309.104L696.711 272.504C697.311 266.404 692.811 261.004 686.811 260.404L650.211 256.804C644.111 256.204 638.711 260.704 638.111 266.704L634.511 303.304C633.911 309.404 638.411 314.804 644.411 315.404L681.011 319.004Z'
                        fill='#FBFCFD'
                      />
                      <path
                        id='Vector_340'
                        opacity='0.0392157'
                        d='M680.91 318.904C687.01 319.504 692.41 315.004 693.01 309.004L696.61 272.404C697.21 266.304 692.71 260.904 686.71 260.304L650.11 256.704C644.01 256.104 638.61 260.604 638.01 266.604L634.41 303.204C633.81 309.304 638.31 314.704 644.31 315.304L680.91 318.904Z'
                        fill='#F8F9FB'
                      />
                      <path
                        id='Vector_341'
                        opacity='0.0588235'
                        d='M680.811 318.804C686.911 319.404 692.311 314.904 692.911 308.904L696.511 272.304C697.111 266.204 692.611 260.805 686.611 260.205L650.011 256.604C643.911 256.004 638.511 260.505 637.911 266.505L634.311 303.104C633.711 309.204 638.211 314.605 644.211 315.205L680.811 318.804Z'
                        fill='#F4F6F9'
                      />
                      <path
                        id='Vector_342'
                        opacity='0.0784314'
                        d='M680.711 318.604C686.811 319.204 692.211 314.704 692.811 308.704L696.411 272.104C697.011 266.004 692.511 260.604 686.511 260.004L649.911 256.404C643.811 255.804 638.411 260.304 637.811 266.304L634.211 302.904C633.611 309.004 638.111 314.404 644.111 315.004L680.711 318.604Z'
                        fill='#F1F3F7'
                      />
                      <path
                        id='Vector_343'
                        opacity='0.0980392'
                        d='M680.711 318.504C686.811 319.104 692.211 314.604 692.811 308.604L696.411 272.004C697.011 265.904 692.511 260.504 686.511 259.904L649.911 256.304C643.811 255.704 638.411 260.204 637.811 266.204L634.211 302.804C633.611 308.904 638.111 314.304 644.111 314.904L680.711 318.504Z'
                        fill='#EDF0F4'
                      />
                      <path
                        id='Vector_344'
                        opacity='0.1176'
                        d='M680.611 318.404C686.711 319.004 692.111 314.504 692.711 308.504L696.311 271.904C696.911 265.804 692.411 260.404 686.411 259.804L649.711 256.304C643.611 255.704 638.211 260.204 637.611 266.204L634.011 302.804C633.411 308.904 637.911 314.304 643.911 314.904L680.611 318.404Z'
                        fill='#EAEDF2'
                      />
                      <path
                        id='Vector_345'
                        opacity='0.1373'
                        d='M680.511 318.304C686.611 318.904 692.011 314.404 692.611 308.404L696.211 271.804C696.811 265.704 692.311 260.305 686.311 259.705L649.711 256.104C643.611 255.504 638.211 260.005 637.611 266.005L634.011 302.604C633.411 308.704 637.911 314.105 643.911 314.705L680.511 318.304Z'
                        fill='#E6EAF0'
                      />
                      <path
                        id='Vector_346'
                        opacity='0.1569'
                        d='M680.41 318.204C686.51 318.804 691.91 314.304 692.51 308.304L696.11 271.704C696.71 265.604 692.21 260.204 686.21 259.604L649.61 256.004C643.51 255.404 638.11 259.904 637.51 265.904L633.91 302.504C633.31 308.604 637.81 314.004 643.81 314.604L680.41 318.204Z'
                        fill='#E3E7EE'
                      />
                      <path
                        id='Vector_347'
                        opacity='0.1765'
                        d='M680.311 318.104C686.411 318.704 691.811 314.204 692.411 308.204L696.011 271.604C696.611 265.504 692.111 260.104 686.111 259.504L649.511 255.904C643.411 255.304 638.011 259.804 637.411 265.804L633.811 302.404C633.211 308.504 637.711 313.904 643.711 314.504L680.311 318.104Z'
                        fill='#DFE3EC'
                      />
                      <path
                        id='Vector_348'
                        opacity='0.1961'
                        d='M680.211 318.004C686.311 318.604 691.711 314.104 692.311 308.104L695.911 271.504C696.511 265.404 692.011 260.004 686.011 259.404L649.411 255.804C643.311 255.204 637.911 259.704 637.311 265.704L633.711 302.304C633.111 308.404 637.611 313.804 643.611 314.404L680.211 318.004Z'
                        fill='#DCE0EA'
                      />
                      <path
                        id='Vector_349'
                        opacity='0.2157'
                        d='M680.211 317.904C686.311 318.504 691.711 314.004 692.311 308.004L695.911 271.404C696.511 265.304 692.011 259.904 686.011 259.304L649.411 255.704C643.311 255.104 637.911 259.604 637.311 265.604L633.711 302.304C633.111 308.404 637.611 313.804 643.611 314.404L680.211 317.904Z'
                        fill='#D8DDE8'
                      />
                      <path
                        id='Vector_350'
                        opacity='0.2353'
                        d='M680.11 317.804C686.21 318.404 691.61 313.904 692.21 307.904L695.81 271.304C696.41 265.204 691.91 259.805 685.91 259.205L649.31 255.604C643.21 255.004 637.81 259.505 637.21 265.505L633.61 302.104C633.01 308.204 637.51 313.605 643.51 314.205L680.11 317.804Z'
                        fill='#D4DAE6'
                      />
                      <path
                        id='Vector_351'
                        opacity='0.2549'
                        d='M680.011 317.704C686.111 318.304 691.511 313.804 692.111 307.804L695.711 271.204C696.311 265.104 691.811 259.704 685.811 259.104L649.211 255.504C643.111 254.904 637.711 259.404 637.111 265.404L633.511 302.004C632.911 308.104 637.411 313.504 643.411 314.104L680.011 317.704Z'
                        fill='#D1D7E3'
                      />
                      <path
                        id='Vector_352'
                        opacity='0.2745'
                        d='M679.91 317.604C686.01 318.204 691.41 313.704 692.01 307.704L695.61 271.104C696.21 265.004 691.71 259.604 685.71 259.004L649.11 255.404C643.01 254.804 637.61 259.304 637.01 265.304L633.41 301.904C632.81 308.004 637.31 313.404 643.31 314.004L679.91 317.604Z'
                        fill='#CDD4E1'
                      />
                      <path
                        id='Vector_353'
                        opacity='0.2941'
                        d='M679.811 317.504C685.911 318.104 691.311 313.604 691.911 307.604L695.511 271.004C696.111 264.904 691.611 259.504 685.611 258.904L649.011 255.304C642.911 254.704 637.511 259.204 636.911 265.204L633.311 301.804C632.711 307.904 637.211 313.304 643.211 313.904L679.811 317.504Z'
                        fill='#CAD1DF'
                      />
                      <path
                        id='Vector_354'
                        opacity='0.3137'
                        d='M679.711 317.404C685.811 318.004 691.211 313.504 691.811 307.504L695.411 270.904C696.011 264.804 691.511 259.404 685.511 258.804L648.911 255.204C642.811 254.604 637.411 259.104 636.811 265.104L633.211 301.704C632.611 307.804 637.111 313.204 643.111 313.804L679.711 317.404Z'
                        fill='#C6CEDD'
                      />
                      <path
                        id='Vector_355'
                        opacity='0.3333'
                        d='M679.711 317.304C685.811 317.904 691.211 313.404 691.811 307.404L695.411 270.804C696.011 264.704 691.511 259.305 685.511 258.705L648.911 255.104C642.811 254.504 637.411 259.005 636.811 265.005L633.211 301.604C632.611 307.704 637.111 313.105 643.111 313.705L679.711 317.304Z'
                        fill='#C3CBDB'
                      />
                      <path
                        id='Vector_356'
                        opacity='0.3529'
                        d='M679.61 317.204C685.71 317.804 691.11 313.304 691.71 307.304L695.31 270.704C695.91 264.604 691.41 259.204 685.41 258.604L648.81 255.004C642.71 254.404 637.31 258.904 636.71 264.904L633.11 301.504C632.51 307.604 637.01 313.004 643.01 313.604L679.61 317.204Z'
                        fill='#BFC8D9'
                      />
                      <path
                        id='Vector_357'
                        opacity='0.3725'
                        d='M679.511 317.104C685.611 317.704 691.011 313.204 691.611 307.204L695.211 270.604C695.811 264.504 691.311 259.104 685.311 258.504L648.711 254.904C642.611 254.304 637.211 258.804 636.611 264.804L633.011 301.404C632.411 307.504 636.911 312.904 642.911 313.504L679.511 317.104Z'
                        fill='#BCC5D7'
                      />
                      <path
                        id='Vector_358'
                        opacity='0.3922'
                        d='M679.41 317.004C685.51 317.604 690.91 313.104 691.51 307.104L695.11 270.504C695.71 264.404 691.21 259.004 685.21 258.404L648.61 254.804C642.51 254.204 637.11 258.704 636.51 264.704L632.91 301.304C632.31 307.404 636.81 312.804 642.81 313.404L679.41 317.004Z'
                        fill='#B8C2D5'
                      />
                      <path
                        id='Vector_359'
                        opacity='0.4118'
                        d='M679.311 316.904C685.411 317.504 690.811 313.004 691.411 307.004L695.011 270.404C695.611 264.304 691.111 258.904 685.111 258.304L648.511 254.704C642.411 254.104 637.011 258.604 636.411 264.604L632.811 301.204C632.211 307.304 636.711 312.704 642.711 313.304L679.311 316.904Z'
                        fill='#B4BFD3'
                      />
                      <path
                        id='Vector_360'
                        opacity='0.4314'
                        d='M679.211 316.804C685.311 317.404 690.711 312.904 691.311 306.904L694.911 270.304C695.511 264.204 691.011 258.805 685.011 258.205L648.411 254.604C642.311 254.004 636.911 258.505 636.311 264.505L632.711 301.104C632.111 307.204 636.611 312.605 642.611 313.205L679.211 316.804Z'
                        fill='#B1BCD0'
                      />
                      <path
                        id='Vector_361'
                        opacity='0.451'
                        d='M679.211 316.704C685.311 317.304 690.711 312.804 691.311 306.804L694.911 270.204C695.511 264.104 691.011 258.704 685.011 258.104L648.411 254.504C642.311 253.904 636.911 258.404 636.311 264.404L632.711 301.004C632.111 307.104 636.611 312.504 642.611 313.104L679.211 316.704Z'
                        fill='#ADB9CE'
                      />
                      <path
                        id='Vector_362'
                        opacity='0.4706'
                        d='M679.11 316.604C685.21 317.204 690.61 312.704 691.21 306.704L694.81 270.104C695.41 264.004 690.91 258.604 684.91 258.004L648.31 254.404C642.21 253.804 636.81 258.304 636.21 264.304L632.61 300.904C632.01 307.004 636.51 312.404 642.51 313.004L679.11 316.604Z'
                        fill='#AAB6CC'
                      />
                      <path
                        id='Vector_363'
                        opacity='0.4902'
                        d='M679.011 316.504C685.111 317.104 690.511 312.604 691.111 306.604L694.711 270.004C695.311 263.904 690.811 258.504 684.811 257.904L648.211 254.304C642.111 253.704 636.711 258.204 636.111 264.204L632.511 300.804C631.911 306.904 636.411 312.304 642.411 312.904L679.011 316.504Z'
                        fill='#A6B3CA'
                      />
                      <path
                        id='Vector_364'
                        opacity='0.5098'
                        d='M678.91 316.404C685.01 317.004 690.41 312.504 691.01 306.504L694.61 269.904C695.21 263.804 690.71 258.404 684.71 257.804L648.11 254.204C642.01 253.604 636.61 258.104 636.01 264.104L632.41 300.704C631.81 306.804 636.31 312.204 642.31 312.804L678.91 316.404Z'
                        fill='#A3AFC8'
                      />
                      <path
                        id='Vector_365'
                        opacity='0.5294'
                        d='M678.811 316.304C684.911 316.904 690.311 312.404 690.911 306.404L694.511 269.804C695.111 263.704 690.611 258.305 684.611 257.705L648.011 254.104C641.911 253.504 636.511 258.005 635.911 264.005L632.311 300.604C631.711 306.704 636.211 312.105 642.211 312.705L678.811 316.304Z'
                        fill='#9FACC6'
                      />
                      <path
                        id='Vector_366'
                        opacity='0.549'
                        d='M678.711 316.204C684.811 316.804 690.211 312.304 690.811 306.304L694.411 269.704C695.011 263.604 690.511 258.204 684.511 257.604L647.911 254.004C641.811 253.404 636.411 257.904 635.811 263.904L632.211 300.504C631.611 306.604 636.111 312.004 642.111 312.604L678.711 316.204Z'
                        fill='#9CA9C4'
                      />
                      <path
                        id='Vector_367'
                        opacity='0.5686'
                        d='M678.711 316.104C684.811 316.704 690.211 312.204 690.811 306.204L694.411 269.604C695.011 263.504 690.511 258.104 684.511 257.504L647.911 253.904C641.811 253.304 636.411 257.804 635.811 263.804L632.211 300.404C631.611 306.504 636.111 311.904 642.111 312.504L678.711 316.104Z'
                        fill='#98A6C2'
                      />
                      <path
                        id='Vector_368'
                        opacity='0.5882'
                        d='M678.61 316.004C684.71 316.604 690.11 312.104 690.71 306.104L694.31 269.504C694.91 263.404 690.41 258.004 684.41 257.404L647.81 253.804C641.71 253.204 636.31 257.704 635.71 263.704L632.11 300.304C631.51 306.404 636.01 311.804 642.01 312.404L678.61 316.004Z'
                        fill='#95A3BF'
                      />
                      <path
                        id='Vector_369'
                        opacity='0.6078'
                        d='M678.511 315.904C684.611 316.504 690.011 312.004 690.611 306.004L694.211 269.404C694.811 263.304 690.311 257.904 684.311 257.304L647.711 253.704C641.611 253.104 636.211 257.604 635.611 263.604L632.011 300.204C631.411 306.304 635.911 311.704 641.911 312.304L678.511 315.904Z'
                        fill='#91A0BD'
                      />
                      <path
                        id='Vector_370'
                        opacity='0.6275'
                        d='M678.41 315.804C684.51 316.404 689.91 311.904 690.51 305.904L694.11 269.304C694.71 263.204 690.21 257.805 684.21 257.205L647.61 253.604C641.51 253.004 636.11 257.505 635.51 263.505L631.91 300.104C631.31 306.204 635.81 311.605 641.81 312.205L678.41 315.804Z'
                        fill='#8D9DBB'
                      />
                      <path
                        id='Vector_371'
                        opacity='0.6471'
                        d='M678.311 315.704C684.411 316.304 689.811 311.804 690.411 305.804L694.011 269.204C694.611 263.104 690.111 257.704 684.111 257.104L647.511 253.504C641.411 252.904 636.011 257.404 635.411 263.404L631.811 300.004C631.211 306.104 635.711 311.504 641.711 312.104L678.311 315.704Z'
                        fill='#8A9AB9'
                      />
                      <path
                        id='Vector_372'
                        opacity='0.6667'
                        d='M678.211 315.604C684.311 316.204 689.711 311.704 690.311 305.704L693.911 269.104C694.511 263.004 690.011 257.604 684.011 257.004L647.411 253.404C641.311 252.804 635.911 257.304 635.311 263.304L631.711 299.904C631.111 306.004 635.611 311.404 641.611 312.004L678.211 315.604Z'
                        fill='#8697B7'
                      />
                      <path
                        id='Vector_373'
                        opacity='0.6863'
                        d='M678.211 315.504C684.311 316.104 689.711 311.604 690.311 305.604L693.911 269.004C694.511 262.904 690.011 257.504 684.011 256.904L647.411 253.304C641.311 252.704 635.911 257.204 635.311 263.204L631.711 299.804C631.111 305.904 635.611 311.304 641.611 311.904L678.211 315.504Z'
                        fill='#8394B5'
                      />
                      <path
                        id='Vector_374'
                        opacity='0.7059'
                        d='M678.11 315.404C684.21 316.004 689.61 311.504 690.21 305.504L693.81 268.904C694.41 262.804 689.91 257.404 683.91 256.804L647.31 253.204C641.21 252.604 635.81 257.104 635.21 263.104L631.61 299.704C631.01 305.804 635.51 311.204 641.51 311.804L678.11 315.404Z'
                        fill='#7F91B3'
                      />
                      <path
                        id='Vector_375'
                        opacity='0.7255'
                        d='M678.011 315.304C684.111 315.904 689.511 311.404 690.111 305.404L693.711 268.804C694.311 262.704 689.811 257.305 683.811 256.705L647.211 253.104C641.111 252.504 635.711 257.005 635.111 263.005L631.511 299.604C630.911 305.704 635.411 311.105 641.411 311.705L678.011 315.304Z'
                        fill='#7C8EB1'
                      />
                      <path
                        id='Vector_376'
                        opacity='0.7451'
                        d='M677.91 315.204C684.01 315.804 689.41 311.304 690.01 305.304L693.61 268.704C694.21 262.604 689.71 257.204 683.71 256.604L647.11 253.004C641.01 252.404 635.61 256.904 635.01 262.904L631.41 299.504C630.81 305.604 635.31 311.004 641.31 311.604L677.91 315.204Z'
                        fill='#788BAF'
                      />
                      <path
                        id='Vector_377'
                        opacity='0.7647'
                        d='M677.811 315.104C683.911 315.704 689.311 311.204 689.911 305.204L693.511 268.604C694.111 262.504 689.611 257.104 683.611 256.504L647.011 252.904C640.911 252.304 635.511 256.804 634.911 262.804L631.311 299.404C630.711 305.504 635.211 310.904 641.211 311.504L677.811 315.104Z'
                        fill='#7588AC'
                      />
                      <path
                        id='Vector_378'
                        opacity='0.7843'
                        d='M677.811 315.004C683.911 315.604 689.311 311.104 689.911 305.104L693.511 268.504C694.111 262.404 689.611 257.004 683.611 256.404L647.011 252.804C640.911 252.204 635.511 256.704 634.911 262.704L631.311 299.304C630.711 305.404 635.211 310.804 641.211 311.404L677.811 315.004Z'
                        fill='#7185AA'
                      />
                      <path
                        id='Vector_379'
                        opacity='0.8039'
                        d='M677.711 314.904C683.811 315.504 689.211 311.004 689.811 305.004L693.411 268.404C694.011 262.304 689.511 256.904 683.511 256.304L646.911 252.704C640.811 252.104 635.411 256.604 634.811 262.604L631.211 299.204C630.611 305.304 635.111 310.704 641.111 311.304L677.711 314.904Z'
                        fill='#6D82A8'
                      />
                      <path
                        id='Vector_380'
                        opacity='0.8235'
                        d='M677.61 314.804C683.71 315.404 689.11 310.904 689.71 304.904L693.31 268.304C693.91 262.204 689.41 256.805 683.41 256.205L646.81 252.604C640.71 252.004 635.31 256.505 634.71 262.505L631.11 299.104C630.51 305.204 635.01 310.605 641.01 311.205L677.61 314.804Z'
                        fill='#6A7FA6'
                      />
                      <path
                        id='Vector_381'
                        opacity='0.8431'
                        d='M677.511 314.704C683.611 315.304 689.011 310.804 689.611 304.804L693.211 268.204C693.811 262.104 689.311 256.704 683.311 256.104L646.711 252.504C640.611 251.904 635.211 256.404 634.611 262.404L631.011 299.004C630.411 305.104 634.911 310.504 640.911 311.104L677.511 314.704Z'
                        fill='#667BA4'
                      />
                      <path
                        id='Vector_382'
                        opacity='0.8627'
                        d='M677.41 314.604C683.51 315.204 688.91 310.704 689.51 304.704L693.11 268.104C693.71 262.004 689.21 256.604 683.21 256.004L646.61 252.404C640.51 251.804 635.11 256.304 634.51 262.304L630.91 298.904C630.31 305.004 634.81 310.404 640.81 311.004L677.41 314.604Z'
                        fill='#6378A2'
                      />
                      <path
                        id='Vector_383'
                        opacity='0.8824'
                        d='M677.311 314.504C683.411 315.104 688.811 310.604 689.411 304.604L693.011 268.004C693.611 261.904 689.111 256.504 683.111 255.904L646.511 252.304C640.411 251.704 635.011 256.204 634.411 262.204L630.811 298.804C630.211 304.904 634.711 310.304 640.711 310.904L677.311 314.504Z'
                        fill='#5F75A0'
                      />
                      <path
                        id='Vector_384'
                        opacity='0.902'
                        d='M677.311 314.404C683.411 315.004 688.811 310.504 689.411 304.504L693.011 267.904C693.611 261.804 689.111 256.404 683.111 255.804L646.511 252.204C640.411 251.604 635.011 256.104 634.411 262.104L630.811 298.704C630.211 304.804 634.711 310.204 640.711 310.804L677.311 314.404Z'
                        fill='#5C729E'
                      />
                      <path
                        id='Vector_385'
                        opacity='0.9216'
                        d='M677.211 314.304C683.311 314.904 688.711 310.404 689.311 304.404L692.911 267.804C693.511 261.704 689.011 256.305 683.011 255.705L646.411 252.104C640.311 251.504 634.911 256.005 634.311 262.005L630.711 298.604C630.111 304.704 634.611 310.105 640.611 310.705L677.211 314.304Z'
                        fill='#586F9B'
                      />
                      <path
                        id='Vector_386'
                        opacity='0.9412'
                        d='M677.11 314.204C683.21 314.804 688.61 310.304 689.21 304.304L692.81 267.704C693.41 261.604 688.91 256.204 682.91 255.604L646.31 252.004C640.21 251.404 634.81 255.904 634.21 261.904L630.61 298.504C630.01 304.604 634.51 310.004 640.51 310.604L677.11 314.204Z'
                        fill='#556C99'
                      />
                      <path
                        id='Vector_387'
                        opacity='0.9608'
                        d='M677.011 314.104C683.111 314.704 688.511 310.204 689.111 304.204L692.711 267.604C693.311 261.504 688.811 256.104 682.811 255.504L646.211 251.904C640.111 251.304 634.711 255.804 634.111 261.804L630.511 298.404C629.911 304.504 634.411 309.904 640.411 310.504L677.011 314.104Z'
                        fill='#516997'
                      />
                      <path
                        id='Vector_388'
                        opacity='0.9804'
                        d='M676.91 314.004C683.01 314.604 688.41 310.104 689.01 304.104L692.61 267.504C693.21 261.404 688.71 256.004 682.71 255.404L646.11 251.804C640.01 251.204 634.61 255.704 634.01 261.704L630.41 298.304C629.81 304.404 634.31 309.804 640.31 310.404L676.91 314.004Z'
                        fill='#4E6695'
                      />
                      <path
                        id='Vector_389'
                        d='M676.811 313.904C682.911 314.504 688.311 310.004 688.911 304.004L692.511 267.404C693.111 261.304 688.611 255.904 682.611 255.304L646.011 251.704C639.911 251.104 634.511 255.604 633.911 261.604L630.311 298.204C629.711 304.304 634.211 309.704 640.211 310.304L676.811 313.904Z'
                        fill='#4A6393'
                      />
                    </g>
                    <path
                      id='Vector_390'
                      d='M676.011 312.705C682.011 313.305 687.311 308.905 687.911 302.905L691.411 267.005C692.011 261.005 687.611 255.705 681.611 255.105L645.711 251.605C639.711 251.005 634.411 255.405 633.811 261.405L630.311 297.305C629.711 303.305 634.111 308.605 640.111 309.205L676.011 312.705Z'
                      fill='url(#paint6_linear_473_2)'
                    />
                    <path
                      id='Vector_391'
                      d='M676.111 312.305C681.811 312.905 686.911 308.705 687.511 303.005L691.011 267.105C691.611 261.405 687.411 256.305 681.711 255.705L645.811 252.205C640.111 251.605 635.011 255.805 634.411 261.505L630.911 297.404C630.311 303.104 634.511 308.205 640.211 308.805L676.111 312.305Z'
                      fill={task[6].TasksAndStudents?.isCompleted ? '#1D87CE' : '#fff'}
                    />
                    <text
                      id='Task 7'
                      transform='translate(641.258 260.889) rotate(5.54811)'
                      fill={task[6].TasksAndStudents?.isCompleted ? '#fff' : '#000'}
                      xmlSpace='preserve'
                      style={{whiteSpace: 'pre'}}
                      font-family='Poppins'
                      font-size='13.3615'
                      font-weight='bold'
                      letter-spacing='0em'
                    >
                      <tspan x='0' y='14.6765'>
                        {task[6].name && task[6].name.length > 3
                          ? truncateText(task[6].name, 4)
                          : task[6].name}
                      </tspan>
                    </text>
                    <path
                      id='Vector_392'
                      d='M672.511 302.205L646.911 299.705C643.311 299.405 640.711 296.205 641.011 292.605L641.211 290.505C641.511 286.905 644.711 284.305 648.311 284.605L673.911 287.105C677.511 287.405 680.111 290.605 679.811 294.205L679.611 296.305C679.211 299.905 676.011 302.505 672.511 302.205Z'
                      fill={task[6].TasksAndStudents?.isCompleted ? '#fff' : '#000'}
                    />
                    <text
                      id='3/7_7'
                      transform='translate(651.746 285.468) rotate(5.54811)'
                      fill={task[6].TasksAndStudents?.isCompleted ? '#1D87CE' : '#fff'}
                      xmlSpace='preserve'
                      style={{whiteSpace: 'pre'}}
                      font-family='Poppins'
                      font-size='11.3288'
                      font-weight='bold'
                      letter-spacing='0em'
                    >
                      <tspan x='0' y='12.4651'>
                        {/* {task[6].counter + '/3'} */}
                        {(task[6]?.TasksAndStudents?.counter || 0) +
                          '/' +
                          (task[6]?.total_counter || 0)}
                      </tspan>
                    </text>
                  </g>
                </a>
              </g>
            )}
            <g id='center'>
              <path
                id='Vector_393'
                d='M626.227 506.935L554.297 533.526L522.728 602.222L550.066 672.876L620.365 704.012L692.295 677.42L723.871 608.627L696.526 538.071L626.227 506.935Z'
                fill='url(#paint7_radial_473_2)'
              />
              <path
                id='Vector_394'
                d='M625.977 516.246L560.836 540.367L532.226 602.591L557.029 666.488L620.622 694.602L685.757 670.58L714.367 608.355L689.564 544.458L625.977 516.246Z'
                fill='url(#paint8_radial_473_2)'
              />
            </g>
          </g>
        </g>
      </g>
      <defs>
        <linearGradient
          id='paint0_linear_473_2'
          x1='947.278'
          y1='551.68'
          x2='952.068'
          y2='609.284'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='white' />
          <stop offset='1' stop-color='#27A9D4' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_473_2'
          x1='737.555'
          y1='907.345'
          x2='681.875'
          y2='922.854'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='white' />
          <stop offset='1' stop-color='#27A9D4' />
        </linearGradient>
        <linearGradient
          id='paint2_linear_473_2'
          x1='536.925'
          y1='917.872'
          x2='482.262'
          y2='899.08'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='white' />
          <stop offset='1' stop-color='#27A9D4' />
        </linearGradient>
        <linearGradient
          id='paint3_linear_473_2'
          x1='355.935'
          y1='782.106'
          x2='326.688'
          y2='732.252'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='white' />
          <stop offset='1' stop-color='#27A9D4' />
        </linearGradient>
        <linearGradient
          id='paint4_linear_473_2'
          x1='342.233'
          y1='530.422'
          x2='284.518'
          y2='527.283'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='white' />
          <stop offset='1' stop-color='#27A9D4' />
        </linearGradient>
        <linearGradient
          id='paint5_linear_473_2'
          x1='470.413'
          y1='339.687'
          x2='412.614'
          y2='339.573'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='white' />
          <stop offset='1' stop-color='#27A9D4' />
        </linearGradient>
        <linearGradient
          id='paint6_linear_473_2'
          x1='632.25'
          y1='279.547'
          x2='689.779'
          y2='285.135'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='white' />
          <stop offset='1' stop-color='#27A9D4' />
        </linearGradient>
        <radialGradient
          id='paint7_radial_473_2'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(623.375 605.449) rotate(-20.288) scale(92.6391 91.3981)'
        >
          <stop stop-color='#A0FFFF' />
          <stop offset='1' stop-color='#3070A8' />
        </radialGradient>
        <radialGradient
          id='paint8_radial_473_2'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(623.375 605.449) rotate(-20.288) scale(83.8618 82.7384)'
        >
          <stop stop-color='#A0FFFF' />
          <stop offset='1' stop-color='#3070A8' />
        </radialGradient>
        <clipPath id='clip0_473_2'>
          <rect width='1324' height='1192' fill='white' />
        </clipPath>
        <clipPath id='clip1_473_2'>
          <rect width='1636' height='1948' fill='white' transform='translate(0 -454)' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default MidTask
